import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InventoryIcon from '@mui/icons-material/Inventory';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptIcon from '@mui/icons-material/Receipt';
import mixpanel from 'mixpanel-browser';

import './style.scss';

const SideMenu = ({ user, activeSellOut }) => {
  const history = useHistory();
  const location = useLocation();

  const [anchorElTwo, setAnchorElTwo] = useState(null);
  const openTwo = Boolean(anchorElTwo);

  const [anchorElThree, setAnchorElThree] = useState(null);
  const openThree = Boolean(anchorElThree);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickTwo = (event) => {
    setAnchorElTwo(event.currentTarget);
  };

  const handleCloseTwo = () => {
    setAnchorElTwo(null);
  };

  const handleClickThree = (event) => {
    setAnchorElThree(event.currentTarget);
  };
  const handleCloseThree = () => {
    setAnchorElThree(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="sf-menu">
      <nav className="sf-menu__navigation">
        <NavLink
          className="sf-menu__navigation__link nav-informe-purchase"
          aria-controls={openTwo ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openTwo ? 'true' : undefined}
          activeClassName={
            anchorElTwo ||
              location.pathname === '/informe-de-compras' ||
              location.pathname === '/ordenes-de-compras'
              ? 'active'
              : ''
          }
          to={
            (location.pathname === '/informe-de-compras' ||
              location.pathname === '/ordenes-de-compras') &&
            location.pathname
          }
          onClick={(e) => {
            handleClickTwo(e);
            mixpanel.track('Click_Purchasing_Module');
          }}
        >
          <CardTravelIcon />
          Compra <ArrowDropDownIcon />
        </NavLink>
        <NavLink
          className="sf-menu__navigation__link nav-informe-sales"
          aria-controls={openThree ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openThree ? 'true' : undefined}
          activeClassName={
            anchorElThree ||
              location.pathname === '/informe-de-ventas' ||
              location.pathname === '/sellout'
              ? 'active'
              : ''
          }
          to={
            (location.pathname === '/informe-de-ventas' || location.pathname === '/sellout') &&
            location.pathname
          }
          onClick={(e) => {
            handleClickThree(e);
            mixpanel.track('Click_Sales_Module');
          }}
        >
          <CardTravelIcon />
          Ventas <ArrowDropDownIcon />
        </NavLink>
        {/*  <NavLink
          className="sf-menu__navigation__link nav-informe-sellin"
          activeClassName="active"
          onClick={() => mixpanel.track('Click_Sales_Module')}
          to="/informe-de-ventas"
        >
          <AssessmentIcon />
          Informe de ventas
        </NavLink> */}
        <NavLink
          className="sf-menu__navigation__link  nav-account-bills"
          activeClassName={
            anchorEl ||
              location.pathname === '/facturas' ||
              location.pathname === '/facturas-rectificativas'
              ? 'active'
              : ''
          }
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          to={
            (location.pathname === '/facturas' ||
              location.pathname === '/facturas-rectificativas') &&
            location.pathname
          }
          onClick={(e) => {
            handleClick(e);
            mixpanel.track('Click_Accounting_Module');
          }}
        >
          <AccountBalanceIcon />
          Contabilidad <ArrowDropDownIcon />
        </NavLink>
        <NavLink
          className="sf-menu__navigation__link nav-informe-sellin"
          activeClassName="active"
          onClick={() => mixpanel.track('Click_inventory_Module')}
          to="/inventario"
        >
          <InventoryIcon />
          Inventario
        </NavLink>
        <NavLink
          className="sf-menu__navigation__link  nav-account-bills"
          activeClassName="active"
          to="/catalogo-productos"
          onClick={() => mixpanel.track('Click_Product_Catalog_Module')}
        >
          <ViewComfyAltIcon />
          Productos
        </NavLink>
        {user?.isFulfillment && (
          <NavLink
            className="sf-menu__navigation__link  nav-account-bills"
            activeClassName="active"
            to="/fulfillment"
            onClick={() => mixpanel.track('Click_Fulfillment')}
          >
            <LocalShippingIcon />
            Fulfillment
          </NavLink>
        )}
        {/*    {user?.email === 'contabilidad@somospurpose.com' && (
          <NavLink
            className="sf-menu__navigation__link  nav-account-bills active"
            activeClassName="active"
            to="/ai"
            onClick={() => mixpanel.track('Click_AI')}
          >
            <SmartToyIcon />
            AI en Aliado: Experiencia Potenciada
          </NavLink>
        )} */}
      </nav>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          selected={location.pathname === '/facturas'}
          onClick={() => {
            history.push('/facturas');
            mixpanel.track('Click_Accounting_Module_invoices');
          }}
          className={location.pathname === '/facturas' ? 'active' : ''}
        >
          <ReceiptIcon />
          Facturas
        </MenuItem>
        <MenuItem
          className={location.pathname === '/facturas-rectificativas' ? 'active' : ''}
          selected={location.pathname === '/facturas-rectificativas'}
          onClick={() => {
            history.push('/facturas-rectificativas');
            mixpanel.track('Click_Accounting_Module_Notes_Credits');
          }}
        >
          <ReceiptLongIcon />
          Notas Créditos
        </MenuItem>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorElTwo}
        open={openTwo}
        onClose={handleCloseTwo}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          selected={location.pathname === '/informe-de-compras'}
          className={location.pathname === '/informe-de-compras' ? 'active' : ''}
          onClick={() => {
            history.push('/informe-de-compras');
            mixpanel.track('Click_Purchasing_Module_informe');
          }}
        >
          <AssessmentIcon />
          Informe de compras
        </MenuItem>
        <MenuItem
          className={location.pathname === '/ordenes-de-compras' ? 'active' : ''}
          selected={location.pathname === '/ordenes-de-compras'}
          onClick={() => {
            history.push('/ordenes-de-compras');
            mixpanel.track('Click_Accounting_Module_Corrective_invoices');
          }}
        >
          <ReceiptLongIcon />
          Órdenes
        </MenuItem>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorElThree}
        open={openThree}
        onClose={handleCloseThree}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          selected={location.pathname === '/informe-de-ventas'}
          className={location.pathname === '/informe-de-ventas' ? 'active' : ''}
          onClick={() => {
            history.push('/informe-de-ventas');
            mixpanel.track('Click_Sales_Module_informe');
          }}
        >
          <AssessmentIcon />
          Informe de ventas
        </MenuItem>
        {activeSellOut && (
          <MenuItem
            className={location.pathname === '/sellout' ? 'active' : ''}
            selected={location.pathname === '/sellout'}
            onClick={() => {
              history.push('/sellout');
              mixpanel.track('Click_SellOut_Informe');
            }}
          >
            <ReceiptLongIcon />
            Sell Out 📊
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

SideMenu.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export { SideMenu };
