import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Button, Grid, IconButton, TableBody } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import { TextField } from '@constants/index';
import { Spinner, CustomTable, renderRow } from '@components/index';

import { Creators as Stocks } from '@reducers/Stocks';
import './style.scss';

const titles = [
  'Sku',
  'Código de Barra',
  'Producto',
  'Imagen',
  'Lote',
  'Disponibles',
  'Fecha de Vencimiento',
  '% Vida Útil',
  'Días hasta Vencimiento',
  'Total Disponibles',
  'Total Reservado',
];

const ContainerProductos = ({
  loading,
  products,
  getProducts,
  pagination,
  onRowsPerPageChange,
  onPageChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();
  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const filteredData = products?.filter((row) => {
    const rowValues = Object.values(row).join(' ').toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    return rowValues.includes(searchTermLower);
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value?.trim());
  };

  const DataExcel = products?.flatMap((item) =>
    item?.lots?.map((row) => ({
      ...item,
      ...row,
      name: item?.name?.trim(),
      nameLote: row?.name?.trim(),
      vuPercent: `${(row.vu_percent * 100).toFixed(0)}%`,
    })),
  );

  return (
    <Grid
      container
      item
      justifyContent="flex-center"
      id="sf-fulfillment-inventory"
      className="sf-fulfillment-inventory"
    >
      <Grid item xs={12} className="pb-3 pt-3">
        <h2 className="sf-title">Inventario Fulfillment </h2>
      </Grid>
      <Grid item container sx={{ mt: 0, mb: 2 }}>
        <Grid item xs={3}>
          <IconButton
            title="Volver atras"
            className="button-icon-table action-click-payment"
            aria-label="expand row"
            size="small"
            onClick={() => history.push('/fulfillment')}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        </Grid>
        {filteredData && filteredData.length > 0 && (
          <Grid item className="text-right" xs={9}>
            <ExcelFile
              filename="Productos Fulfillment"
              element={
                <Button
                  variant="contained"
                  className="btn btn-primary"
                  endIcon={<FileDownloadIcon />}
                >
                  Descargar
                </Button>
              }
            >
              <ExcelSheet data={DataExcel ?? []} name="Inventario Fulfillment">
                <ExcelColumn label="Producto" value="name" />
                <ExcelColumn label="SKU" value="product_sku" />
                <ExcelColumn label="Código de Barra" value="product_barcode" />
                <ExcelColumn label="Lote" value="nameLote" />
                <ExcelColumn label="Disponible" value="availableQty" />
                <ExcelColumn label="Fecha de Expiración" value="expirationDate" />
                <ExcelColumn label="vida útil" value="vuPercent" />
                <ExcelColumn label="Días para Consumir" value="lifeTime" />
                <ExcelColumn label="Total Disponibles" value="totalAvailable" />
                <ExcelColumn label="Total Reservado" value="totalReserved" />
              </ExcelSheet>
            </ExcelFile>
          </Grid>
        )}
      </Grid>
      <Grid container className="card">
        <>
          {loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                padding: '20px 10px',
              }}
            >
              <Spinner />
            </Grid>
          ) : (
            <Grid item xs={12} sx={{ mt: 0, mb: 2, zIndex: 3 }} className="filter text-right">
              <Grid
                item
                xs={4}
                container
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 1, mb: 0, zIndex: 3, paddingLeft: 2, paddingRight: 2 }}
                className="text-left"
              >
                <Grid item xs={searchTerm !== '' ? 10 : 12}>
                  <TextField
                    size="medium"
                    fullWidth
                    label="Buscar"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                {searchTerm !== '' && (
                  <Grid item xs={2}>
                    <IconButton
                      title="Detalles de la Factura"
                      className="button-icon-table action-click-payment"
                      aria-label="expand row"
                      size="small"
                      style={{
                        marginLeft: '20px',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSearchTerm('');
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              {filteredData && filteredData?.length > 0 ? (
                <Grid container className="card">
                  <CustomTable
                    titles={titles}
                    data={filteredData}
                    pagination={pagination}
                    active
                    onPageChange={(event, page) => onPageChange(page)}
                    onRowsPerPageChange={(event) => onRowsPerPageChange(event.target.value)}
                  >
                    <TableBody>{renderRow(filteredData, pagination)}</TableBody>
                  </CustomTable>
                </Grid>
              ) : (
                <Grid item xs={12} className="pb-1">
                  <h5 className="text-center">Sin registros </h5>
                </Grid>
              )}
            </Grid>
          )}
        </>
      </Grid>
    </Grid>
  );
};

ContainerProductos.defaultProps = {
  pagination: {},
  products: [],
  loading: false,
};
ContainerProductos.propTypes = {
  getProducts: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pagination: PropTypes.shape({}),
  loading: PropTypes.bool,
  products: PropTypes.shape({}),
};

const mapStateToProps = ({
  stock: {
    fulfillmentProducs: { products, loading },
    pagination,
  },
}) => ({ products, loading, pagination });

const mapDispatchToProps = (dispatch) => ({
  onRowsPerPageChange: (item) => dispatch(Stocks.changeRowsPerPage(item)),
  onPageChange: (item) => dispatch(Stocks.changePage(item)),
  getProducts: () => dispatch(Stocks.getProductsFulfillment()),
});

export const FulfillmentProducts = connect(mapStateToProps, mapDispatchToProps)(ContainerProductos);
