/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { PAGE, ROWS_PER_PAGE } from '@constants/index';
import { Types as SellInSagas } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  loading: false,
  loadingTop: false,
  loadingCustomer: false,
  frequencySale: [],
  totalAmount: 0,
  deliveredSales: [],
  detailsProduct: {
    products: [],
    loading: false,
  },
  sellout: {
    products: [],
    loading: false,
    pagination: {
      page: PAGE,
      rowsPerPage: ROWS_PER_PAGE,
    },
  },
  listProduct: {
    data: [],
    loading: false,
  },
  frequency: {
    list: [],
    loading: false,
  },
  productSales: {
    products: [],
    loading: false,
  },
  listCustomers: {
    list: [],
    loading: false,
  },
  productClients: {
    list: [],
    loading: false,
  },
  productsRankingSale: [],
  productTop: {},
  customers: {},
  customerCurrent: {},
  summarySales: [],
  summaryCustomer: [],
  paginationProduct: {
    page: PAGE,
    rowsPerPage: ROWS_PER_PAGE,
  },
  sellOutCliens: [],
});
/* ------------- Reducers ------------- */
export const filterTopSales = (state = INITIAL_STATE) => ({
  ...state,
  loadingTop: true,
});
export const filterRankingProductSales = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

export const rankingProductSucces = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  productsRankingSale: data?.products?.sort((a, b) => b.total_amount - a.total_amount),
  paginationProduct: {
    page: PAGE,
    rowsPerPage: ROWS_PER_PAGE,
  },
});
export const topProductSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  loadingTop: false,
  productTop: data,
});

export const changeRowsPerPage = (state = INITIAL_STATE, { PerPage }) => {
  return {
    ...state,
    paginationProduct: {
      rowsPerPage: PerPage,
      page: PAGE,
    },
  };
};

export const changePage = (state = INITIAL_STATE, { page }) => {
  return {
    ...state,
    paginationProduct: {
      ...state.paginationProduct,
      page,
    },
  };
};

export const frequencySalesSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  loadingTop: false,
  frequencySale: data,
  totalAmount: data?.totalAmount,
});

export const topCustomerSalesSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  loadingTop: false,
  customers: data,
});
export const summarySuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  summarySales: data,
});
export const deliveredSalesSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  deliveredSales: data,
});
export const filterCustomerActivity = (state = INITIAL_STATE) => ({
  ...state,
  loadingCustomer: true,
});

export const customerActivitySuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  loadingCustomer: false,
  summaryCustomer: data,
  customerCurrent: data?.sort((a, b) => b.monthDate.localeCompare(a.monthDate))[0] ?? {},
});

export const filterProductSales = (state = INITIAL_STATE) => ({
  ...state,
  productSales: {
    loading: true,
  },
});
export const productSalesSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  productSales: {
    products: data,
    loading: false,
  },
});
export const listProductSalesSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  listProduct: {
    data,
    loading: false,
  },
  detailsProduct: {
    products: [],
    loading: false,
  },
});
export const productDetailsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  detailsProduct: {
    products: data,
    loading: false,
  },
});
export const getProductDetails = (state = INITIAL_STATE, { data }) => ({
  ...state,
  detailsProduct: {
    products: [],
    loading: true,
  },
});

export const getCustomersList = (state = INITIAL_STATE) => ({
  ...state,
  listCustomers: {
    list: [],
    loading: true,
  },
});
export const getCustomersListSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  listCustomers: {
    list: data,
    loading: true,
  },
});

export const getFrequencyCustomerReset = (state = INITIAL_STATE) => ({
  ...state,
  frequency: {
    list: [],
    loading: false,
  },
  productClients: {
    list: [],
    loading: false,
  },
});
export const getFrequencyCustomer = (state = INITIAL_STATE, { data }) => ({
  ...state,
  frequency: {
    list: data,
    loading: true,
  },
});
export const getFrequencyCustomerSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  frequency: {
    list: data,
    loading: true,
  },
});
export const getProductsClients = (state = INITIAL_STATE, { data }) => ({
  ...state,
  productClients: {
    list: [],
    loading: false,
  },
});
export const getProductsClientsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  productClients: {
    list: data,
    loading: false,
  },
});

export const getSellOutRequest = (state = INITIAL_STATE) => ({
  ...state,
  sellout: {
    ...state.sellout,
    products: [],
    loading: true,
    pagination: {
      page: PAGE,
      rowsPerPage: ROWS_PER_PAGE,
    },
  },
});

export const getSellOutSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  sellout: {
    ...state.sellout,
    products: [...data?.summary],
    totalAmount: data?.totalAmount,
    totalUnits: data.totalUnits,
    loading: false,
    pagination: {
      page: PAGE,
      rowsPerPage: ROWS_PER_PAGE,
    },
  },
});

export const changeRowsPerPageSellOut = (state = INITIAL_STATE, { PerPage }) => {
  return {
    ...state,
    sellout: {
      ...state.sellout,
      pagination: {
        page: PAGE,
        rowsPerPage: ROWS_PER_PAGE,
      },
    },
  };
};

export const changePageSellOut = (state = INITIAL_STATE, { page }) => {
  return {
    ...state,
    sellout: {
      ...state.sellout,
      pagination: {
        ...state.paginationProduct,
        page,
      },
    },
  };
};

export const getclientsSellOut = (state = INITIAL_STATE, { data }) => ({
  ...state,
  sellout: {
    ...state.sellout,
    sellOutCliens: data,
  },
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [SellInSagas.RANKING_PRODUCT_SALES_SUCCESS]: rankingProductSucces,
  [SellInSagas.CHANGE_ROWS_PER_PAGE]: changeRowsPerPage,
  [SellInSagas.CHANGE_PAGE]: changePage,
  [SellInSagas.FREQUENCY_SALES_SUCCESS]: frequencySalesSuccess,
  [SellInSagas.TOP_PRODUCT_SUCCESS]: topProductSuccess,
  [SellInSagas.TOP_CUSTOMER_SALES_SUCCESS]: topCustomerSalesSuccess,
  [SellInSagas.FILTER_TOP_SALES]: filterTopSales,
  [SellInSagas.FILTER_RANKING_PRODUCT_SALES]: filterRankingProductSales,
  [SellInSagas.SUMMARY_SALES_SUCCESS]: summarySuccess,
  [SellInSagas.DELIVERED_SALES_SUCCESS]: deliveredSalesSuccess,
  [SellInSagas.FILTER_CUSTOMER_ACTIVITY]: filterCustomerActivity,
  [SellInSagas.CUSTOMER_ACTIVITY_SUCCESS]: customerActivitySuccess,
  [SellInSagas.FILTER_PRODUCT_SALES]: filterProductSales,
  [SellInSagas.PRODUCT_SALES_SUCCESS]: productSalesSuccess,
  [SellInSagas.LIST_PRODUCT_SALES_SUCCESS]: listProductSalesSuccess,
  [SellInSagas.GET_PRODUCT_DETAILS]: getProductDetails,
  [SellInSagas.PRODUCT_DETAILS_SUCCESS]: productDetailsSuccess,
  [SellInSagas.GET_CUSTOMERS_LIST]: getCustomersList,
  [SellInSagas.GET_CUSTOMERS_LIST_SUCCESS]: getCustomersListSuccess,
  [SellInSagas.GET_FREQUENCY_CUSTOMER]: getFrequencyCustomer,
  [SellInSagas.GET_FREQUENCY_CUSTOMER_RESET]: getFrequencyCustomerReset,
  [SellInSagas.GET_FREQUENCY_CUSTOMER_SUCCESS]: getFrequencyCustomerSuccess,
  [SellInSagas.GET_PRODUCTS_CLIENTS_SUCCESS]: getProductsClientsSuccess,
  [SellInSagas.GET_PRODUCTS_CLIENTS]: getProductsClients,
  [SellInSagas.GET_SELL_OUT]: getSellOutRequest,
  [SellInSagas.GET_SELL_OUT_SUCCESS]: getSellOutSuccess,
  [SellInSagas.CHANGE_ROWS_PER_PAGE_SELL_OUT]: changeRowsPerPageSellOut,
  [SellInSagas.CHANGE_PAGE_SELL_OUT]: changePageSellOut,
  [SellInSagas.GET_SELL_OUT_CLIENTS_SUCCESS]: getclientsSellOut,
});
