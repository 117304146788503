import { call } from 'redux-saga/effects';
import { ODOO_HOST, TOKEN_KEY } from '@constants/index';
import { odooApi } from './api';

export const fetchData = (url, formData) => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${localStorage.getItem(TOKEN_KEY)}`);
  return fetch(url, {
    method: 'POST',
    body: formData,
    headers,
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);
};

export const ProductsService = {
  *getStocks() {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/stock/summary', {});
  },
  *putUpdateStatusProduct(payload) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/sascha/stock/product_update_purchaseok',
      {},
      { ...payload },
    );
  },
  *getPickingList() {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/stock/picking_list', {});
  },
  *getProductsFulfillment() {
    return yield call(
      { context: odooApi, fn: odooApi.get },
      '/sascha/stock/summary?fulfillment=true',
      {},
    );
  },
  *getContactsFulfillment() {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/contacts/clients_list', {});
  },
  *createContactsFulfillment(payload) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/sascha/contacts/create_client',
      {},
      {
        ...payload,
      },
    );
  },
  *contactAddDelivery(payload) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/sascha/contacts/add_contact_delivery',
      {},
      {
        contact_deliverys: [payload],
      },
    );
  },
  *createOrderDelivery(payload) {
    try {
      const formData = new FormData();
      const { file_data, json_data } = payload;
      formData.append('file_data', file_data);
      formData.append(
        'json_data',
        JSON.stringify({
          orders: [{ ...json_data }],
        }),
      );

      return yield call(fetchData, `${ODOO_HOST}/api/sascha/stock/create_out_picking`, formData);
    } catch (error) {
      console.error('Error en createOrderDelivery:', error);
      throw error;
    }
  },
  *createOrderPicking(payload) {
    try {
      const formData = new FormData();
      const { file_data, json_data } = payload;
      formData.append('file_data', file_data);
      formData.append(
        'json_data',
        JSON.stringify({
          orders: [{ ...json_data }],
        }),
      );

      return yield call(fetchData, `${ODOO_HOST}/api/sascha/stock/create_in_picking`, formData);
    } catch (error) {
      console.error('Error en createOrderDelivery:', error);
      throw error;
    }
  },
  *updateAddressContact(payload) {
    return yield call(
      { context: odooApi, fn: odooApi.put },
      '/sascha/contacts/update_contact_delivery',
      { contact_id: payload.contactId },
      {
        state_id: payload.state_id,
        city_id: payload.city_id,
        name: payload.name,
        street: payload.street,
        phone: payload.phone,
      },
    );
  },
};
