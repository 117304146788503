import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like Sellin_Request -> SELLIN_REQUEST
 */
export const { Types, Creators } = createActions({
  getRankingProductSales: ['months', 'chanel', 'date'],
  rankingProductSalesSuccess: ['data'],
  changeRowsPerPage: ['PerPage'],
  changePage: ['page'],
  filterTopSales: ['item', 'date'],
  filterRankingProductSales: ['item', 'date'],
  getFrequency: ['months'],
  frequencySalesSuccess: ['data'],
  getTopProduct: ['months'],
  topProductSuccess: ['data'],
  getTopCustomerSales: ['months'],
  topCustomerSalesSuccess: ['data'],
  getSummaryALL: ['months', 'chanel'],
  getSummarySales: ['months', 'chanel'],
  summarySalesSuccess: ['data'],
  deliveredSalesSuccess: ['data'],
  filterChanelMonth: ['filters'],
  filterCustomerActivity: ['filters'],
  customerActivitySuccess: ['data'],
  filterProductSales: ['item', 'date'],
  productSalesSuccess: ['data'],
  getListProductSales: null,
  listProductSalesSuccess: ['data'],
  getProductDetails: ['id'],
  productDetailsSuccess: ['data'],
  getCustomersList: null,
  getCustomersListSuccess: ['data'],
  getFrequencyCustomer: ['payload'],
  getFrequencyCustomerReset: null,
  getFrequencyCustomerSuccess: ['data'],

  getProductsClients: ['id'],
  getProductsClientsSuccess: ['data'],

  getSellOut: ['client', 'date'],
  getSellOutSuccess: ['data'],
  changeRowsPerPageSellOut: ['PerPage'],
  changePageSellOut: ['page'],

  getSellOutClients: null,
  getSellOutClientsSuccess: ['data'],
});
