import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import { Creators as User } from '@reducers/User';
import { Header } from '@components/index';

const LayoutBase = ({ children, user, notification, products = [] }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSignOff = () => {
    history.push('/login');
    mixpanel.track('Click_Logout', { ...user });
    dispatch(User.logoutUser());
  };
  useEffect(() => {
    if (user.isLoggedIn) {
      mixpanel.alias(user?.eid);
      mixpanel.identify(user?.eid);
      mixpanel.people.set({
        $name: user.name,
        $email: user.email,
        $user_id: user?.eid,
      });
    }
  }, [user]);

  return (
    <Container style={{ padding: 0, maxWidth: '100%' }}>
      {user?.isLoggedIn && (
        <Header
          user={user}
          handleSignOff={handleSignOff}
          notification={notification}
          activeSellOut
        />
      )}
      <Container sx={{ mt: 8, mb: 7, padding: 0 }} maxWidth="xl">
        <Grid container item justifyContent="center">
          {children}
        </Grid>
      </Container>
    </Container>
  );
};

LayoutBase.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  user: PropTypes.shape({}).isRequired,
  notification: PropTypes.shape({}).isRequired,
  products: PropTypes.shape({}).isRequired,
  /*   getUser: PropTypes.func.isRequired, */
};
const mapStateToProps = ({
  user,
  notification: { notification },
  sales: {
    sellout: { products },
  },
}) => ({
  user,
  notification,
  products,
});

export const Layout = connect(mapStateToProps)(withRouter(LayoutBase));
