/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Grid, TableBody, TableCell, TableRow } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
// import { Dropdown } from '@superfuds/saori';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { es } from 'date-fns/locale';
import { CustomTable } from '@components/index';
import { formatCurrency } from '@utils';

const titles = ['EAN', 'Nombre', 'Marca', 'Cantidad Entregada', 'Total'];

export const TemplateRanking = ({
  monthByMonthDropdown,
  productsRankingSale,
  filterProduct,
  setFilterProduct,
  filterRankingProduct,
}) => {
  const [dateRange, setDateRange] = useState([moment().startOf('month')?._d, new Date()]);

  return (
    <Grid
      container
      direction="row"
      sx={{ mt: 7 }}
      justifyContent="flex-start"
      alignItems="center"
      className="rankingProducts"
    >
      <Grid item xs={8}>
        <h2 className="sf-title-secondary">Top 10 Productos Más Vendidos</h2>
      </Grid>
      {productsRankingSale && productsRankingSale?.length > 0 && (
        <Grid item xs={4} className="text-right">
          <ExcelFile
            filename="Tu_productos_ranking_sales_superfuds"
            element={
              <Button
                variant="contained"
                className="btn btn-primary"
                endIcon={<FileDownloadIcon />}
              >
                Descargar
              </Button>
            }
          >
            <ExcelSheet data={productsRankingSale} name="Productos Ranking vendidos">
              <ExcelColumn label="EAN" value="product_ean" />
              <ExcelColumn label="Nombre Producto" value="product_name" />
              <ExcelColumn label="Marca" value="product_brand" />
              <ExcelColumn label="Cantidad Entregada" value="total_qty_delibered" />
              <ExcelColumn label="Total" value="total_amount" />
            </ExcelSheet>
          </ExcelFile>
        </Grid>
      )}

      <Grid container className="card">
        <Grid item xs={12} sx={{ mt: 0, mb: 2, zIndex: 3 }} className="filter text-right">
          {/*      <Dropdown
            classNamePrefix="dropdown"
            className="container-filter"
            options={monthByMonthDropdown}
            onChange={(item) => {
              const product = {
                ...filterProduct,
                months: item.value,
              };
              setFilterProduct(product);
              filterRankingProduct(product);
            }}
            defaultValue={monthByMonthDropdown[0]}
            value={monthByMonthDropdown.filter((option) => option.value === filterProduct?.months)}
          /> */}
          <Grid container justifyContent="flex-end">
            <Grid
              xs={2}
              justifyContent="end"
              style={{
                marginRight: '20px',
                padding: '10px 15px',
              }}
            >
              <DatePicker
                showIcon={false}
                selectsRange
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                dateFormat="yyyy/MM/dd"
                isClearable={false}
                onChange={(update) => {
                  setDateRange(update);
                  if (update[1] !== null) {
                    filterRankingProduct(null, {
                      dateStart: moment(update[0], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
                        'YYYY/MM/DD',
                      ),
                      dateEnd: moment(update[1], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
                        'YYYY/MM/DD',
                      ),
                    });
                  }
                }}
                withPortal
                locale={es}
                minDate={moment().subtract(1, 'year').startOf('year').toDate()}
                maxDate={moment().toDate()}
                customInput={
                  <input
                    type="text"
                    style={{
                      width: '100%',
                      padding: '10px',
                      fontSize: '16px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      outline: 'none',
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
        {productsRankingSale && productsRankingSale?.length > 0 ? (
          <CustomTable titles={titles} data={productsRankingSale} active={false}>
            <TableBody>
              {productsRankingSale?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{item.product_ean}</TableCell>
                  <TableCell align="left">{item.product_name}</TableCell>
                  <TableCell align="center">{item.product_brand}</TableCell>
                  <TableCell align="center">{item.total_qty_delibered}</TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        color: 'success.main',
                      }}
                    >
                      {formatCurrency(item.total_amount || 0)}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </CustomTable>
        ) : (
          <Grid item xs={12} className="card_height container-message">
            <h3 className="sf-title-secondary">No hay Resultados</h3>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
TemplateRanking.propTypes = {
  monthByMonthDropdown: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productsRankingSale: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterProduct: PropTypes.shape({}).isRequired,
  setFilterProduct: PropTypes.func.isRequired,
  filterRankingProduct: PropTypes.func.isRequired,
};
