import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ProtectedRouteBase = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to="/" />)}
  />
);

ProtectedRouteBase.propTypes = {
  component: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ user: { isLoggedIn } }) => ({
  isLoggedIn,
});

export const ProtectedRoute = connect(mapStateToProps)(ProtectedRouteBase);
