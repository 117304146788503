import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as XLSX from 'exceljs';

import {
  Autocomplete,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { ToastContainer, Slide } from 'react-toastify';

import { Creators as Stocks } from '@reducers/Stocks';
import { formatCurrency } from '@utils';
import { CustomTable, Spinner } from '@components/index';
import { TextField } from '@constants/index';
import { formikConf } from './order.formik';
import { AddOrderImport } from './OrderImport';

const generateExcel = () => {
  const workbook = new XLSX.Workbook();
  const worksheetClientes = workbook.addWorksheet('Nueva Orden');
  worksheetClientes.addRow(['EAN', 'Cantidad', 'Precio Unitario']);
  worksheetClientes.addRow(['', '', '']);
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Nueva Orden.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  });
};

const ContainerNewOrder = ({
  getProducts,
  clients,
  products,
  createOrderDelivery,
  getContactsFulfillment,
  createOrder,
  loading,
  reset,
}) => {
  const history = useHistory();
  const [delivery, setDelivery] = useState([]);
  const [NewProduct, setNewProduct] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [openImport, setOpenImport] = useState(false);
  const [selectedDelivery, setselectedDelivery] = useState(null);
  const formRef = useRef(null);

  useEffect(() => {
    getProducts();
    getContactsFulfillment();
  }, [getProducts, getContactsFulfillment]);

  useEffect(() => {
    if (createOrder?.code === 201) {
      if (formRef.current) {
        const form = formRef.current;
        Array.from(form.elements).forEach((element) => {
          if (element.type !== 'submit') {
            element.value = '';
          }
        });
        form.reset();
        setSelectedValue(null);
        setselectedDelivery(null);
      }
    }
  }, [createOrder, getContactsFulfillment, getProducts]);

  const filteredData = products?.filter((row) => {
    const rowValues = Object.values(row).join(' ').toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    return rowValues.includes(searchTermLower);
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value?.trim());
  };

  const handleFileChange = (event, setFieldValue, values) => {
    const file = event?.target?.files[0];

    if (file) {
      const renamedFile = new File([file], `${values?.document_origin}.pdf`, {
        type: file.type,
        lastModified: file.lastModified,
      });

      setFieldValue('fileData', renamedFile);
    }
  };

  const handleChangeInput = (event, setFieldValue, type, id) => {
    const value = event?.target?.value;
    if (NewProduct.length > 0) {
      const existingIndex = NewProduct.findIndex((item) => item.product_id === id);

      if (existingIndex !== -1) {
        const updatedProduct = {
          ...NewProduct[existingIndex],
          [type]: type === 'qty' ? parseInt(value, 10) : parseInt(value, 10),
        };

        NewProduct[existingIndex] = updatedProduct;
      } else {
        const newProduct = {
          product_id: id,
          qty: type === 'qty' ? parseInt(value, 10) : 0,
          price: type === 'price' ? parseInt(value, 10) : 0,
        };

        setNewProduct([...NewProduct, newProduct]);
      }
    } else {
      const newProduct = {
        product_id: id,
        qty: type === 'qty' ? parseInt(value, 10) : 0,
        price: type === 'price' ? parseInt(value, 10) : 0,
      };

      setNewProduct([newProduct]);
    }
    setFieldValue('products', NewProduct);
  };
  return (
    <Grid
      container
      item
      justifyContent="flex-center"
      id="sf-fulfillment-inventory"
      className="sf-fulfillment-inventory"
    >
      <ToastContainer transition={Slide} draggablePercent={60} />
      <Grid item xs={12} className="pb-3 pt-3">
        <h2 className="sf-title">Crear Orden de Venta</h2>
      </Grid>
      <Grid item container sx={{ mt: 0, mb: 2 }}>
        <Grid item xs={3}>
          <IconButton
            title="Volver atras"
            className="button-icon-table action-click-payment"
            aria-label="expand row"
            size="small"
            onClick={() => {
              history.push('/fulfillment');
              reset();
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        container
        className="card"
        sx={{
          padding: 2,
        }}
      >
        <Grid item xs={12} className="pb-1 pt-1">
          <Formik
            {...formikConf}
            onSubmit={(values) => {
              const totalAmount = values?.products.reduce(
                (accumulator, currentValue) => accumulator + currentValue?.price,
                0,
              );
              createOrderDelivery({
                file_data: values?.fileData,
                json_data: {
                  delivery_contact_id: values?.delivery_contact_id,
                  date_delivered: values?.date_delivered,
                  amount_origin: totalAmount,
                  document_origin: values?.document_origin,
                  products: values?.products ?? [],
                },
              });
            }}
          >
            {({ handleChange, errors, touched, setFieldValue, values }) => {
              const productIds = values?.products ?? [];
              return (
                <Form ref={formRef}>
                  <AddOrderImport
                    open={openImport}
                    products={products}
                    handleClose={() => {
                      setOpenImport(false);
                    }}
                    send={(values) => setFieldValue('products', values)}
                  />
                  <Grid item container justifyContent="center" className="pb-3 pt-6" spacing={2}>
                    <Grid item container justifyContent="center" className="pb-3 pt-6" spacing={4}>
                      <Grid item xs={3}>
                        <Autocomplete
                          id="AutocompleteCustomer"
                          autoHighlight
                          options={clients ?? []}
                          getOptionLabel={(option) => option.name ?? ''}
                          renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          )}
                          onChange={(event, newValue) => {
                            setFieldValue('contact', newValue?.id);
                            setDelivery(newValue?.contactsDelivery);
                            setSelectedValue(newValue);
                          }}
                          value={selectedValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Cliente"
                              name="contact"
                              error={!!(touched.contact && errors.contact)}
                            />
                          )}
                        />
                        {!!(touched.contact && errors.contact) && (
                          <FormHelperText className="Mui-error">{errors.contact}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Autocomplete
                          id="AutocompleteDelivery"
                          autoHighlight
                          options={delivery ?? []}
                          getOptionLabel={(option) => `${option.name} (${option.stret})`}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                                {option.stret}
                              </li>
                            );
                          }}
                          onChange={(event, newValue) => {
                            setFieldValue('delivery_contact_id', newValue?.id);
                            setselectedDelivery(newValue);
                          }}
                          value={selectedDelivery}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Direcciones de Entrega"
                              error={!!(touched.delivery_contact_id && errors.delivery_contact_id)}
                            />
                          )}
                        />
                        {touched.delivery_contact_id && errors.delivery_contact_id && (
                          <FormHelperText className="Mui-error">
                            {errors.delivery_contact_id}
                          </FormHelperText>
                        )}
                      </Grid>

                      <Grid item xs={2}>
                        <TextField
                          label="Fecha Aproximada de Entrega"
                          placeholder="Ingresa Fecha de Entrega"
                          fullWidth
                          name="date_delivered"
                          type="date"
                          inputProps={{
                            min: '2023-06-01',
                            max: '2028-06-30',
                          }}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={!!(touched.date_delivered && errors.date_delivered)}
                          helperText={touched.date_delivered && errors.date_delivered}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          label="Documento Origen"
                          placeholder="Documento Origen"
                          fullWidth
                          name="document_origin"
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={!!(touched.document_origin && errors.document_origin)}
                          helperText={touched.document_origin && errors.document_origin}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="Agregar Documento PDF"
                          placeholder="Agregar Documento PDF"
                          fullWidth
                          name="fileData"
                          disabled={!values.document_origin}
                          type="file"
                          inputProps={{
                            accept: '.pdf',
                          }}
                          onChange={(e) => {
                            handleFileChange(e, setFieldValue, values);
                          }}
                          onBlur={(e) => {
                            handleFileChange(e, setFieldValue, values);
                          }}
                          error={!!(touched.fileData && errors.fileData)}
                          helperText={
                            (touched.fileData && errors.fileData) ||
                            'Asegúrate de que el archivo sea inferior a 1 mega.'
                          }
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Observación de Entrega"
                          fullWidth
                          placeholder="Observación de Entrega"
                          rows={4}
                          multiline
                          name="observations"
                          onChange={handleChange}
                          onBlur={handleChange}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 4, mb: 3 }}>
                      <Grid
                        item
                        xs={12}
                        sx={{ mt: 0, mb: 2, zIndex: 3 }}
                        className="filter text-right"
                      >
                        <Grid
                          item
                          xs={4}
                          container
                          justifyContent="center"
                          alignItems="center"
                          sx={{ mt: 1, mb: 0, zIndex: 3, paddingLeft: 2, paddingRight: 2 }}
                          className="text-left"
                        >
                          <Grid item xs={searchTerm !== '' ? 10 : 12}>
                            <TextField
                              size="medium"
                              fullWidth
                              label="Buscar"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              margin="normal"
                              variant="outlined"
                            />
                          </Grid>
                          {searchTerm !== '' && (
                            <Grid item xs={2}>
                              <IconButton
                                className="button-icon-table action-click-payment"
                                aria-label="expand row"
                                style={{
                                  marginLeft: '20px',
                                }}
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSearchTerm('');
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="mb-4 mt-2 text-right">
                        <Button
                          variant="contained"
                          className="btn btn-primary"
                          endIcon={<AddCircleOutlineIcon />}
                          onClick={() => setOpenImport(true)}
                          sx={{
                            margin: '0px 10px',
                          }}
                        >
                          Subir plantilla
                        </Button>
                        <Button
                          sx={{
                            marginLeft: '15px',
                          }}
                          variant="contained"
                          className="btn btn-primary"
                          endIcon={<FileDownloadIcon />}
                          onClick={() => {
                            generateExcel();
                          }}
                        >
                          Plantilla base
                        </Button>
                      </Grid>
                      {loading ? (
                        <Grid
                          container
                          className="card"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            padding: '20px 10px',
                          }}
                        >
                          <Spinner />
                        </Grid>
                      ) : (
                        <>
                          {filteredData && filteredData?.length > 0 ? (
                            <CustomTable
                              titles={[
                                'Producto',
                                'SKU ',
                                'Código de Barra',
                                'Disponible',
                                'Cantidad',
                                'Precio Unitario',
                                'Total',
                              ]}
                              data={filteredData}
                              pagination={[]}
                              active={false}
                              textAlign="left"
                            >
                              <TableBody>
                                {filteredData
                                  ?.filter((item) => item.total_available !== 0)
                                  ?.map((items) => {
                                    const idActive = productIds?.find(
                                      (row) => row?.product_id === items.id,
                                    )?.product_id;

                                    const productsWithTotal = productIds
                                      .filter((item) => item?.product_id === items.id)
                                      .map((item) => ({
                                        ...item,
                                        total: item?.qty * item?.price,
                                      }))
                                      .find((row) => row?.product_id === items.id)?.total;
                                    return (
                                      <TableRow
                                        key={items.id}
                                        style={{
                                          backgroundColor: idActive === items.id && '#8c8c8c24',
                                        }}
                                      >
                                        <TableCell align="left" width="300">
                                          {items.name}
                                        </TableCell>
                                        <TableCell align="left">{items.product_sku}</TableCell>
                                        <TableCell align="left">{items.product_barcode}</TableCell>

                                        <TableCell align="left">{items.total_available}</TableCell>
                                        <TableCell align="center" width="100">
                                          {items.total_available !== 0 ? (
                                            <Field
                                              name={`products[${items.id}].qty`}
                                              type="number"
                                              fullWidth
                                            >
                                              {({ field }) => (
                                                <TextField
                                                  {...field}
                                                  type="number"
                                                  variant="outlined"
                                                  value={
                                                    values?.products?.find(
                                                      (e) => e.product_id === items.id,
                                                    )?.qty
                                                  }
                                                  name={`products[${items.id}].qty`}
                                                  fullWidth
                                                  onChange={(e) => {
                                                    handleChangeInput(
                                                      e,
                                                      setFieldValue,
                                                      'qty',
                                                      items.id,
                                                    );
                                                  }}
                                                  disabled={items.total_available === 0}
                                                  inputProps={{
                                                    min: 1,
                                                    max: items.total_available,
                                                    inputMode: 'numeric',
                                                    style: {
                                                      textAlign: 'center',
                                                    },
                                                  }}
                                                  error={
                                                    !!(
                                                      (items.total_available !== 0 &&
                                                        touched.products &&
                                                        errors.products) ||
                                                      (touched?.products?.[0]?.qty &&
                                                        errors?.products?.[0]?.qty)
                                                    )
                                                  }
                                                /*    helperText={
                                         (items.total_available !== 0 &&
                                           errors?.products?.[0]?.qty) ||
                                         errors?.products
                                       } */
                                                />
                                              )}
                                            </Field>
                                          ) : (
                                            'Sin inventario'
                                          )}
                                        </TableCell>
                                        <TableCell align="center" width="100">
                                          {items.total_available !== 0 ? (
                                            <Field
                                              type="number"
                                              fullWidth
                                              name={`products[${items.id}].price`}
                                            >
                                              {({ field }) => (
                                                <TextField
                                                  {...field}
                                                  type="number"
                                                  variant="outlined"
                                                  onChange={(e) => {
                                                    handleChangeInput(
                                                      e,
                                                      setFieldValue,
                                                      'price',
                                                      items.id,
                                                    );
                                                  }}
                                                  value={
                                                    values?.products?.find(
                                                      (e) => e?.product_id === items.id,
                                                    )?.price
                                                  }
                                                  fullWidth
                                                  disabled={items.total_available === 0}
                                                  inputProps={{
                                                    min: 1,
                                                    inputMode: 'numeric',
                                                    style: {
                                                      textAlign: 'center',
                                                    },
                                                  }}
                                                  error={
                                                    !!(
                                                      (items.total_available !== 0 &&
                                                        touched.products &&
                                                        errors.products) ||
                                                      (touched?.products?.[0]?.price &&
                                                        errors?.products?.[0]?.price)
                                                    )
                                                  }
                                                /*                                                   error={
                                                                                !!(
                                                                                  items.total_available !== 0 &&
                                                                                  touched.products &&
                                                                                  errors.products
                                                                                )
                                                                              }
                                                                              helperText={
                                                                                items.total_available !== 0 &&
                                                                                touched.products &&
                                                                                errors.products
                                                                              } */
                                                />
                                              )}
                                            </Field>
                                          ) : (
                                            'Sin inventario'
                                          )}
                                        </TableCell>
                                        <TableCell align="left">
                                          {formatCurrency(productsWithTotal || 0) || 0}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </CustomTable>
                          ) : (
                            <Grid item xs={12} className="pb-1">
                              <h5 className="text-center">Sin registros </h5>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container sx={{ mt: 3, mb: 3 }}>
                    <Grid item xs={6} className="text-left">
                      <Button
                        style={{
                          textTransform: 'initial',
                          background: 'transparent',
                          borderColor: '#25c16a',
                          color: '#25c16a',
                        }}
                        color="primary"
                        variant="outlined"
                        onClick={() => history.push('/fulfillment')}
                        endIcon={<CloseIcon />}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                      <Button
                        variant="contained"
                        className="btn btn-primary"
                        type="submit"
                        style={{
                          textTransform: 'initial',
                        }}
                        endIcon={<SendIcon />}
                      >
                        {createOrder?.loading ? 'Guardando...' : 'Guardar'}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
};

ContainerNewOrder.propTypes = {
  getProducts: PropTypes.func.isRequired,
  clients: PropTypes.shape({}).isRequired,
  products: PropTypes.shape({}).isRequired,
  createOrder: PropTypes.shape({}).isRequired,
  createOrderDelivery: PropTypes.func.isRequired,
  getContactsFulfillment: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  stock: {
    fulfillmentProducs: { products, loading },
    fulfillmentClients: { clients },
    createOrder,
  },
}) => ({ products, loading, clients, createOrder });

const mapDispatchToProps = (dispatch) => ({
  getProducts: () => dispatch(Stocks.getProductsFulfillment()),
  getContactsFulfillment: () => dispatch(Stocks.getContactsFulfillment()),
  createOrderDelivery: (payload) => dispatch(Stocks.createOrderDelivery(payload)),
  reset: () => dispatch(Stocks.resetCreateOrderDelivery()),
});

export const FulfillmentNewOrder = connect(mapStateToProps, mapDispatchToProps)(ContainerNewOrder);
