import { useEffect, useState } from 'react';

import { capitalize } from '@utils';

export const clientTypePlaces = {
  B2B: `https://www.gaia.superfuds.com/api/general/cities?channel=b2b`,
};

export function usePlaces() {
  const [places, setPlaces] = useState([]);
  const url = clientTypePlaces.B2B;
  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const response = await fetch(url);
        const { data } = await response.json();

        if (data.length > 0) {
          const groupedByDepartment = data.reduce((acc, place) => {
            const existingDepartment = acc.find((item) => item.id === place.department_id);

            if (!existingDepartment) {
              acc.push({
                label: capitalize(place.department_name),
                id: place.department_id,
                cities: [
                  {
                    ...place,
                    id: place.city_id,
                    label: capitalize(place.city_name),
                  },
                ],
              });
            } else {
              existingDepartment.cities.push({
                ...place,
                id: place.city_id,
                label: capitalize(place.city_name),
              });
            }

            return acc;
          }, []);

          setPlaces(groupedByDepartment);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPlaces();
  }, [url]);

  return {
    places,
  };
}
