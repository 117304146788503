/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TableRow, TableCell, TableBody, Button, Tooltip, Typography } from '@mui/material';
import { Chart } from '@superfuds/sf-charts';
import { connect } from 'react-redux';
import ReactExport from 'react-export-excel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// import { Dropdown } from '@superfuds/saori';
import { es } from 'date-fns/locale';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { CustomTable, PurcharsesTop, SummaryOrders, CardTablePromedio } from '@components/index';
import { Creators as Purchase } from '@reducers/Purchases';
import { capitalize, formatCurrency } from '@utils';

import {
  optionsChart,
  // monthByMonthDropdown
} from '@constants/index';
import './style.scss';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const titlesFrequency = ['Mes', 'Unidades', 'Variación'];
const titlesAverageTicket = ['Mes', 'Ticket promedio', 'Variación'];
const titles = [
  'SKU',
  'Nombre',
  'Cantidad Pedida',
  'Total',
  'Cantidad Recibida',
  'Cantidad No Entregada',
];

const isNegativeNumber = (number) => {
  const color = number < 0 ? '#EF4044' : '#25c16a';
  const content = number < 0 ? `Decrecimiento ${number}%` : `Crecimiento ${number}%`;

  return (
    <Typography style={{ color }} variant="caption" display="block" gutterBottom>
      {content}
    </Typography>
  );
};

const PurchaseReportScreen = ({
  user,
  totalAmount,
  product,
  getaverageTicket,
  getSummary,
  serviceLevelByUnits,
  getfrequency,
  getStocks,
  summary,
  dataFrequency,
  averageTicket,
  delivered,
  pagination,
  filter,
  rankingProduct,
  productsRanking,
  paginationProduct,
  changePage,
  changeRowsPerPage,
  loading,
}) => {
  // const [filterProduct, setFilterProduct] = useState(1);
  const [filterTotalPurchase] = useState(12);
  const [dateRange, setDateRange] = useState([moment().startOf('month')?._d, new Date()]);

  useEffect(() => {
    getaverageTicket();
    filter(3, null);
  }, [getStocks, getaverageTicket, filter]);

  useEffect(() => {
    getfrequency();
  }, [getfrequency]);

  useEffect(() => {
    getSummary(filterTotalPurchase);
  }, [getSummary, filterTotalPurchase]);

  useEffect(() => {
    rankingProduct(3, null);
  }, [rankingProduct]);
  return (
    <Grid
      container
      item
      justifyContent="center"
      id="sf-purchaseReport"
      className="sf-purchaseReport"
    >
      <Grid item xs={12} className="pb-3 pt-3">
        <h2 className="sf-title">
          Compras de Superfüds a <span color="primary">{capitalize(user.name)}</span>
        </h2>
      </Grid>
      <PurcharsesTop
        loading={loading}
        product={product}
        totalAmount={totalAmount}
        serviceLevel={serviceLevelByUnits}
        filter={filter}
        title="Total de compras"
        titleView="compras"
        customFilter
      />

      <Grid
        container
        direction="row"
        sx={{ mt: 7 }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={8}>
          <h2 className="sf-title-secondary">Ranking de productos comprados</h2>
        </Grid>

        {productsRanking && productsRanking.length > 0 && (
          <Grid item xs={4} className="text-right">
            <ExcelFile
              filename="Tu_productos_ranking_compras_superfuds"
              disabled={productsRanking.length === 0}
              element={
                <Button
                  disabled={productsRanking.length === 0}
                  variant="contained"
                  className="btn btn-primary"
                  endIcon={<FileDownloadIcon />}
                >
                  Descargar
                </Button>
              }
            >
              <ExcelSheet data={productsRanking} name="Productos Ranking">
                <ExcelColumn label="ID" value="product_id" />
                <ExcelColumn label="SKU" value="product_code" />
                <ExcelColumn label="Nombre Producto" value="product_name" />
                <ExcelColumn label="Cantidad Pedida" value="total_qty_request" />
                <ExcelColumn label="Cantidad Recibida" value="total_qty_received" />
                <ExcelColumn label="Cantidad no entregada" value="total_qty_lost" />
                <ExcelColumn label="Total" value="total_amount" />
              </ExcelSheet>
            </ExcelFile>
          </Grid>
        )}

        <Grid container className="card">
          <Grid
            item
            container
            justifyContent="flex-end"
            sx={{ mt: 0, mb: 2, zIndex: 3 }}
            className="filter text-right"
          >
            {/*  <Dropdown
              classNamePrefix="dropdown"
              className="container-filter"
              options={monthByMonthDropdown}
              onChange={(item) => {
                setFilterProduct(item.value);
                rankingProduct(item.value);
              }}
              defaultValue={monthByMonthDropdown[0]}
              value={monthByMonthDropdown.filter((option) => option.value === filterProduct)}
            /> */}
            <Grid
              xs={2}
              justifyContent="end"
              style={{
                marginRight: '20px',
                padding: '10px 15px',
              }}
            >
              <DatePicker
                showIcon={false}
                selectsRange
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                dateFormat="yyyy/MM/dd"
                isClearable={false}
                onChange={(update) => {
                  setDateRange(update);
                  if (update[1] !== null) {
                    rankingProduct(null, {
                      dateStart: moment(update[0], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
                        'YYYY/MM/DD',
                      ),
                      dateEnd: moment(update[1], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
                        'YYYY/MM/DD',
                      ),
                    });
                  }
                }}
                withPortal
                locale={es}
                minDate={moment().subtract(1, 'year').startOf('year').toDate()}
                maxDate={moment().toDate()}
                customInput={
                  <input
                    type="text"
                    style={{
                      width: '100%',
                      padding: '10px',
                      fontSize: '16px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      outline: 'none',
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
          {productsRanking && productsRanking?.length > 0 ? (
            <CustomTable
              titles={titles}
              data={productsRanking}
              pagination={paginationProduct}
              active
              onPageChange={(event, page) => changePage(page)}
              onRowsPerPageChange={(event) => changeRowsPerPage(event.target.value)}
            >
              <TableBody>
                {productsRanking?.map((item) => (
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={item.total_qty_lost > 0 ? 'Producto no Entregado' : ''}
                    placement="right-end"
                  >
                    <TableRow key={item.id}>
                      <TableCell align="center">{item.product_code}</TableCell>
                      <TableCell align="center">{item.product_name}</TableCell>
                      <TableCell align="center">{item.total_qty_request}</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: item.total_qty_lost > 0 && '#fff',
                        }}
                      >
                        {formatCurrency(item.total_amount)}
                      </TableCell>
                      <TableCell align="center">{item.total_qty_received}</TableCell>
                      <TableCell align="center">{item.total_qty_lost}</TableCell>
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </CustomTable>
          ) : (
            <Grid item xs={12} className="card_height container-message">
              <h3 className="sf-title-secondary">No hay Resultados</h3>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" sx={{ mt: 7 }} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <h2 className="sf-title-secondary">Total de Órdenes de Compra Mensuales</h2>
        </Grid>
        <Grid
          className="card"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {/*     <Grid item xs={12} sx={{ mt: 0, mb: 2, zIndex: 3 }} className="filter text-right">
            <Dropdown
              classNamePrefix="dropdown"
              className="container-filter"
              options={monthByMonthDropdown}
              onChange={(item) => {
                setFilterTotalPurchase(item.value);
                getSummary(item.value);
              }}
              defaultValue={monthByMonthDropdown[0]}
              value={monthByMonthDropdown.filter((option) => option.value === filterTotalPurchase)}
            />
          </Grid> */}
          {summary && summary.length > 0 ? (
            <SummaryOrders summary={summary} pagination={pagination} delivered={delivered} />
          ) : (
            <Grid item xs={12} className="card_height container-message">
              <h3 className="sf-title-secondary">No hay Resultados</h3>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" sx={{ mt: 7 }} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <h2 className="sf-title-secondary">Tendencia de Compras Mensuales </h2>
        </Grid>
        <Grid
          className="card"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {/*    <Grid item xs={12} sx={{ mt: 2, mb: 2, zIndex: 3 }} className="filter text-right">
           <Dropdown
              classNamePrefix="dropdown"
              className="container-filter"
              options={monthByMonthDropdown}
              onChange={(item) => {
                setFilterTotalPurchase(item.value);
                getSummary(item.value);
              }}
              defaultValue={monthByMonthDropdown[0]}
              value={monthByMonthDropdown.filter((option) => option.value === filterTotalPurchase)}
            />
          </Grid> */}
          {summary && summary.length > 0 ? (
            <Chart data={summary} options={optionsChart(summary)} />
          ) : (
            <Grid item xs={12} className="card_height container-message">
              <h3 className="sf-title-secondary">No hay Resultados</h3>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ mt: 4 }}
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="baseline"
      >
        <Grid item xs={6}>
          {dataFrequency.summaryFqy && dataFrequency.summaryFqy.length > 0 && (
            <CardTablePromedio
              title="Frecuencia de compra"
              titleSecondary="Promedio de Compras Mensuales"
              ticket={dataFrequency.frequency}
              titlesTable={titlesFrequency}
              summary={dataFrequency.summaryFqy}
              activeNumber
              renderRow={() =>
                dataFrequency.summaryFqy.map((item) => (
                  <TableRow key={item.date}>
                    <TableCell align="center">{item.date}</TableCell>
                    <TableCell align="center">{item.totalUnitsRequested}</TableCell>
                    <TableCell align="center">
                      {item.growthInValue ?? 0}
                      {item.growthInValue !== null && (
                        <Typography variant="caption">
                          {' '}
                          {isNegativeNumber(item.growthInPercentage)}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              }
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {averageTicket.summaryAov && averageTicket.summaryAov.length > 0 && (
            <CardTablePromedio
              title="Tu ticket promedio"
              titleSecondary="Promedio Mensual"
              ticket={averageTicket.aov}
              titlesTable={titlesAverageTicket}
              summary={averageTicket.summaryAov}
              active
              renderRow={() =>
                averageTicket.summaryAov.map((item) => (
                  <TableRow key={item.date}>
                    <TableCell align="center">{item.date}</TableCell>
                    <TableCell align="center">{item.aov}</TableCell>
                    <TableCell align="center">
                      {item.growthInPercentage}
                      <Typography variant="caption">
                        {' '}
                        {isNegativeNumber(item.percentage)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              }
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

PurchaseReportScreen.propTypes = {
  user: PropTypes.shape({
    name: PropTypes?.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  totalAmount: PropTypes.number.isRequired,
  serviceLevelByUnits: PropTypes.number.isRequired,
  getaverageTicket: PropTypes.func.isRequired,
  changeRowsPerPage: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  getStocks: PropTypes.func.isRequired,
  getfrequency: PropTypes.func.isRequired,
  getSummary: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  rankingProduct: PropTypes.func.isRequired,
  product: PropTypes.shape({}).isRequired,
  summary: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  delivered: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productsRanking: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataFrequency: PropTypes.shape({
    frequency: PropTypes.number,
    summaryFqy: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  averageTicket: PropTypes.shape({
    aov: PropTypes.number,
    summaryAov: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  pagination: PropTypes.shape({}).isRequired,
  paginationProduct: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({
  user,
  purchases: {
    itemSold: { totalAmount },
    product,
    datalevel: { serviceLevelByUnits },
    averageTicket,
    dataFrequency,
    delivered,
    summary,
    productsRanking,
    paginationProduct,
    loading,
  },
}) => ({
  user,
  totalAmount,
  product,
  serviceLevelByUnits,
  dataFrequency,
  averageTicket,
  delivered,
  paginationProduct,
  summary,
  productsRanking,
  loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getSummary: (months) => dispatch(Purchase.getSummary(months)),
    rankingProduct: (item, date) => dispatch(Purchase.getRankingProduct(item, date)),
    getaverageTicket: () => dispatch(Purchase.getAverageTicket()),
    getfrequency: () => dispatch(Purchase.getFrequencyPurchase()),
    filter: (item, date) => dispatch(Purchase.filterTopPurchases(item, date)),
    changeRowsPerPage: (item) => dispatch(Purchase.changeRowsPerPage(item)),
    changePage: (item) => dispatch(Purchase.changePage(item)),
  };
};
export const PurchaseContainer = connect(mapStateToProps, mapDispatchToProps)(PurchaseReportScreen);
