/* eslint-disable no-underscore-dangle */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterListIcon from '@mui/icons-material/FilterAltOff';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import { Dropdown } from '@superfuds/saori';
import { es } from 'date-fns/locale';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import { CustomTable, ModalLayout, Spinner } from '@components/index';
import { formatCurrency } from '@utils';
import { TextField } from '@constants/index';
import { ModalGraphic } from './modalGraphic';

import './style.scss';

const titles = [
  'EAN',
  'Nombre',
  'Marca',
  'Cliente',
  'Puntos de Ventas',
  'Cantidad Entregada',
  'Total',
];
const isNegativeNumber = (number) => {
  const color = number < 0 ? '#EF4044' : '#25c16a';
  const content = number < 0 ? `Decrecimiento ${number}%` : `Crecimiento ${number}%`;

  return (
    <Typography style={{ color }} variant="caption" display="block" gutterBottom>
      {content}
    </Typography>
  );
};
export const TemplateSalesProductDetails = ({
  monthByMonthDropdown,
  productSales,
  filterProduct,
  setFilterProduct,
  filterProductSales,
  channel,
  paginationProduct,
  changePage,
  changeRowsPerPage,
  listProduct,
  getProductDetails,
  detailsProduct,
  listCustomers,
  getFrequencyCustomer,
  getProductsClients,
  productClients,
  frequency,
  getFrequencyCustomerReset,
  loading,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [open, setOpen] = useState(false);
  const [openGraphic, setOpenGraphic] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [product, setProduct] = useState({});
  const [dateRange, setDateRange] = useState([moment().startOf('month')?._d, new Date()]);

  const { page, rowsPerPage } = paginationProduct;

  useMemo(() => {
    if (listProduct && listProduct?.data[0]) {
      getProductDetails(listProduct?.data[0]?.id);
      setProduct(listProduct?.data[0]);
    }
  }, [getProductDetails, listProduct]);

  const handleResetFilter = () => {
    setSelectedBrand(null);
    setSelectedClient(null);
    setFilterProduct({
      chanel: '',
      months: 1,
    });
    filterProductSales({
      chanel: '',
      months: 1,
    });
  };

  const uniqueClients = [...new Set(productSales?.map((item) => item?.product_client))];
  const clientOptions = [
    {
      label: 'Todos',
      value: '',
    },
    ...uniqueClients?.map((client, index) => ({
      value: index,
      label: client,
    })),
  ];

  const uniqueBrands = [...new Set(productSales?.map((item) => item?.product_brand))];
  const brandOptions = [
    {
      label: 'Todas las Marcas',
      value: '',
    },
    ...uniqueBrands?.map((brand, index) => ({
      value: index,
      label: brand,
    })),
  ];

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value?.trim());
  };
  const filteredData = productSales?.filter((row) => {
    const includesSearchTerm = Object.values(row)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesSelectedClient = !selectedClient || row.product_client === selectedClient.label;
    const matchesSelectedBrand = !selectedBrand || row.product_brand === selectedBrand.label;
    return includesSearchTerm && matchesSelectedClient && matchesSelectedBrand;
  });
  return (
    <>
      <ModalGraphic
        open={openGraphic}
        handleClose={() => setOpenGraphic(false)}
        listCustomers={listCustomers}
        getFrequencyCustomer={getFrequencyCustomer}
        listProduct={productClients?.list}
        getProductsClients={getProductsClients}
        frequency={frequency}
        getFrequencyCustomerReset={getFrequencyCustomerReset}
      />
      <ModalLayout
        open={open}
        handleClose={() => {
          setOpen(!open);
          setProduct(listProduct?.data[0]);
          getProductDetails(listProduct?.data[0]?.id);
        }}
        fullWidth
        maxWidth="lg"
      >
        <Grid item container>
          <Grid item xs={12} className="pt-1 pb-1 text-center">
            <Typography variant="h5">
              <strong> Frecuencia por Producto</strong>
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            sx={{ mt: 7 }}
            justifyContent="center"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={4} className="text-center" alignItems="center">
              <Autocomplete
                id="AutocompleteDepartament"
                autoHighlight
                options={listProduct?.data ?? []}
                value={product}
                openOnFocus
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, state) => {
                  const isSelected = state?.inputValue === option?.name;

                  return (
                    <Box
                      component="li"
                      sx={{
                        '& > img': { mr: 2, flexShrink: 0 },
                        color: isSelected ? '#25c16a' : '',
                      }}
                      {...props}
                    >
                      <img
                        width="40"
                        srcSet={`${option.image}`}
                        src={`${option.image}`}
                        alt={`${product?.name}`}
                      />
                      {option.name}
                    </Box>
                  );
                }}
                onChange={(event, newValue) => {
                  getProductDetails(newValue?.id);
                  setProduct(newValue);
                }}
                getOptionLabel={(option) => option.name ?? ''}
                renderInput={(params) => <TextField {...params} label="Selecciona Producto" />}
              />
              {product && product?.image !== 'none' && (
                <Card
                  sx={{
                    margin: '0 auto',
                    marginTop: '15px',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    maxWidth: 300,
                  }}
                >
                  <Typography
                    style={{
                      padding: '15px',
                    }}
                  >
                    <strong>{product?.name}</strong>
                  </Typography>
                  <CardMedia
                    component="img"
                    style={{
                      width: '150px',
                      margin: '0 auto',
                    }}
                    image={product?.image}
                    alt={`${product?.name}`}
                  />
                  {product?.totalProductQty > 0 &&
                    product?.totalSalesQty > 0 &&
                    product?.totalSalesAmount && (
                      <CardContent className="text-left">
                        <Divider />
                        <Typography paragraph className="text-center">
                          <strong>Resumen de los Últimos Seis Meses</strong>
                        </Typography>
                        <Typography variant="body2">
                          Unidades Disponible{' '}
                          <span style={{ color: '#25c16a' }}>{product?.totalProductQty} Uds</span>
                        </Typography>
                        <Typography variant="body2" color="success">
                          Total Unidades Vendidas{' '}
                          <span style={{ color: '#25c16a' }}>{product?.totalSalesQty}</span>
                        </Typography>
                        <Typography variant="body2">
                          Total Ventas{' '}
                          <span style={{ color: '#25c16a' }}>
                            {formatCurrency(product?.totalSalesAmount || 0)}
                          </span>
                        </Typography>
                      </CardContent>
                    )}
                </Card>
              )}
            </Grid>
            <Grid item xs={8}>
              {detailsProduct && detailsProduct?.loading ? (
                <Grid
                  container
                  className="card"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    marginTop: '30px',
                    padding: '20px 10px',
                  }}
                >
                  <Spinner />
                </Grid>
              ) : (
                <>
                  {detailsProduct && detailsProduct?.products?.length > 0 ? (
                    <>
                      <Grid container xs={12} justifyContent="end">
                        <Grid item xs={4} className="text-right">
                          <ExcelFile
                            filename="Frecuencia_por_Producto"
                            element={
                              <Button
                                variant="contained"
                                className="btn btn-primary"
                                endIcon={<FileDownloadIcon />}
                              >
                                Descargar
                              </Button>
                            }
                          >
                            <ExcelSheet
                              data={detailsProduct?.products.map((item) => {
                                return {
                                  ...item,
                                  name: product?.name,
                                };
                              })}
                              name="Productos Frecuencia"
                            >
                              <ExcelColumn label="Producto" value="name" />
                              <ExcelColumn label="Mes" value="month_name" />
                              <ExcelColumn label="Unidades Vendidas" value="total_units" />
                              <ExcelColumn label="Total Ventas $" value="growth_rate_units" />
                            </ExcelSheet>
                          </ExcelFile>
                        </Grid>
                      </Grid>
                      <Typography paragraph className="text-center">
                        <strong>Resumen de los Últimos Seis Meses</strong>
                      </Typography>
                      <Alert
                        icon={false}
                        severity="warning"
                        variant="outlined"
                        sx={{
                          width: '80%',
                          fontSize: '.8rem',
                          margin: '0 auto',
                        }}
                        className="text-center"
                      >
                        <Typography variant="caption">
                          !Cómo Calcular el Crecimiento o Decrecimiento¡
                          <br />
                          Utiliza esta fórmula para calcular la tasa de Crecimiento/ Decrecimiento
                          <br />
                          <strong>
                            Tasa de Crecimiento/ Decrecimiento = ((Valor Actual - Valor Anterior) /
                            Valor Anterior) * 100
                          </strong>
                        </Typography>
                      </Alert>
                      <br />
                      <CustomTable
                        titles={['Mes', 'Unidades Vendidas', 'Total Ventas $']}
                        data={detailsProduct?.products}
                        active={false}
                      >
                        <TableBody>
                          {detailsProduct?.products?.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">{item.month_name}</TableCell>
                              <TableCell align="center">
                                {item.total_units}
                                <Typography variant="caption">
                                  {isNegativeNumber(item.growth_rate_units)}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Box
                                  sx={{
                                    color: 'success.main',
                                  }}
                                >
                                  {formatCurrency(item.total_amount || 0)}
                                </Box>
                                <Typography variant="caption">
                                  {isNegativeNumber(item.growth_rate_mount)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </CustomTable>
                    </>
                  ) : (
                    <Grid item xs={12} className="card_height container-message">
                      <h3 className="sf-title-secondary">No hay Resultados</h3>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </ModalLayout>
      <Grid
        container
        direction="row"
        sx={{ mt: 7 }}
        justifyContent="flex-start"
        alignItems="center"
        className="rankingProducts"
      >
        <Grid item xs={8}>
          <h2 className="sf-title-secondary">
            Catálogo Detallado de Productos con Información de Ventas
          </h2>
          <Grid container>
            <Grid item xs={5}>
              <Button
                variant="outlined"
                onClick={() => setOpen(true)}
                startIcon={<MenuBookIcon />}
                style={{
                  textTransform: 'initial',
                }}
                className="btn btn-primary btn-option mb-2 mt-2"
              >
                Análisis de Ventas de Producto
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenGraphic(true);
                  getFrequencyCustomerReset();
                }}
                startIcon={<AnalyticsIcon />}
                style={{
                  textTransform: 'initial',
                }}
                className="btn btn-primary btn-option mb-2 mt-2"
              >
                Gráficos de Ventas
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {filteredData && filteredData?.length > 0 && (
          <Grid item xs={4} className="text-right">
            <ExcelFile
              filename="Productos vendidos"
              element={
                <Button
                  variant="contained"
                  className="btn btn-primary"
                  endIcon={<FileDownloadIcon />}
                >
                  Descargar
                </Button>
              }
            >
              <ExcelSheet data={productSales} name="Productos vendidos">
                <ExcelColumn label="EAN" value="product_ean" />
                <ExcelColumn label="Nombre Producto" value="product_name" />
                <ExcelColumn label="Marca" value="product_brand" />
                <ExcelColumn label="Cliente" value="product_client" />
                <ExcelColumn label="Punto de Venta" value="shipping_client" />
                <ExcelColumn label="Cantidad Entregada" value="total_qty_delibered" />
                <ExcelColumn label="Total" value="total_amount" />
              </ExcelSheet>
            </ExcelFile>
          </Grid>
        )}
        <Grid container className="card">
          <>
            <Grid item xs={12} sx={{ mt: 2, mb: 2, zIndex: 3 }} className="filter text-right">
              <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item xs={9}>
                  <Dropdown
                    classNamePrefix="dropdown"
                    className="container-filter  text-center"
                    options={productSales && productSales?.length > 0 ? clientOptions : []}
                    onChange={(item) => setSelectedClient(item?.value === '' ? null : item)}
                    placeholder="Por Cliente"
                    value={
                      productSales && productSales.length > 0
                        ? clientOptions?.filter((option) => option.label === selectedClient?.label)
                        : []
                    }
                  />
                  <Dropdown
                    classNamePrefix="dropdown"
                    className="container-filter"
                    options={productSales && productSales?.length > 0 ? brandOptions : []}
                    onChange={(item) => setSelectedBrand(item?.value === '' ? null : item)}
                    placeholder="Por Marca"
                    value={
                      productSales && productSales.length > 0
                        ? brandOptions?.filter((option) => option.label === selectedBrand?.label)
                        : []
                    }
                  />
                  <Dropdown
                    classNamePrefix="dropdown"
                    className="container-filter"
                    options={channel}
                    onChange={(item) => {
                      const product = {
                        ...filterProduct,
                        chanel: item.value,
                      };
                      setFilterProduct(product);
                      filterProductSales(product);
                    }}
                    placeholder="Canal de Ventas"
                    value={channel.filter((option) => option.value === filterProduct?.chanel)}
                  />
                </Grid>
                <Grid
                  xs={2}
                  justifyContent="end"
                  style={{
                    marginRight: '20px',
                    padding: '10px 15px',
                  }}
                >
                  <DatePicker
                    style={{
                      zIndex: 999999,
                    }}
                    showIcon={false}
                    selectsRange
                    dateFormat="yyyy/MM/dd"
                    isClearable={false}
                    onChange={(update) => {
                      setDateRange(update);
                      if (update[1] !== null) {
                        filterProductSales(null, {
                          dateStart: moment(update[0], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
                            'YYYY/MM/DD',
                          ),
                          dateEnd: moment(update[1], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
                            'YYYY/MM/DD',
                          ),
                        });
                      }
                    }}
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    withPortal
                    locale={es}
                    minDate={moment().subtract(1, 'year').startOf('year').toDate()}
                    maxDate={moment().toDate()}
                    customInput={
                      <input
                        type="text"
                        className="inputDate"
                        style={{
                          width: '100%',
                          padding: '10px',
                          fontSize: '16px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          outline: 'none',
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              {/*   <Dropdown
                classNamePrefix="dropdown"
                className="container-filter"
                options={monthByMonthDropdown}
                onChange={(item) => {
                  const product = {
                    ...filterProduct,
                    months: item.value,
                  };
                  setFilterProduct(product);
                  filterProductSales(product);
                }}
                defaultValue={monthByMonthDropdown[0]}
                value={monthByMonthDropdown.filter(
                  (option) => option.value === filterProduct?.months,
                )}
              /> */}
              {(searchTerm !== '' ||
                selectedBrand !== null ||
                selectedClient !== null ||
                filterProduct?.chanel !== '' ||
                filterProduct?.chanel !== '') && (
                  <Grid
                    item
                    style={{
                      display: 'inline-block',
                      padding: '0 20px',
                    }}
                  >
                    <IconButton onClick={handleResetFilter}>
                      <FilterListIcon
                        style={{
                          color: '#25c16a',
                        }}
                      />
                    </IconButton>
                  </Grid>
                )}

              <Grid
                item
                sx={{ mt: 1, mb: 0, zIndex: 3, paddingLeft: 2, paddingRight: 2 }}
                className="text-left"
              >
                <TextField
                  label="Buscar"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {loading ? (
              <Grid
                container
                className="card"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginTop: '30px',
                  padding: '20px 10px',
                }}
              >
                <Spinner />
              </Grid>
            ) : (
              <>
                {filteredData && filteredData?.length > 0 ? (
                  <CustomTable
                    titles={titles}
                    data={filteredData}
                    pagination={paginationProduct}
                    onPageChange={(event, page) => changePage(page)}
                    onRowsPerPageChange={(event) => changeRowsPerPage(event.target.value)}
                    active
                  >
                    <TableBody>
                      {filteredData
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">{item.product_ean}</TableCell>
                            <TableCell align="center">{item.product_name}</TableCell>
                            <TableCell align="center">{item.product_brand}</TableCell>
                            <TableCell align="center">{item.product_client}</TableCell>
                            <TableCell align="center">{item.shipping_client}</TableCell>
                            <TableCell align="center">{item.total_qty_delibered}</TableCell>
                            <TableCell align="center">
                              <Box
                                sx={{
                                  color: 'success.main',
                                }}
                              >
                                {formatCurrency(item.total_amount || 0)}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </CustomTable>
                ) : (
                  <Grid item xs={12} className="card_height container-message">
                    <h3 className="sf-title-secondary">No hay Resultados</h3>
                  </Grid>
                )}
              </>
            )}
          </>
        </Grid>
      </Grid>
    </>
  );
};
TemplateSalesProductDetails.propTypes = {
  monthByMonthDropdown: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productSales: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterProduct: PropTypes.shape({}).isRequired,
  setFilterProduct: PropTypes.func.isRequired,
  filterProductSales: PropTypes.func.isRequired,
  paginationProduct: PropTypes.shape({}).isRequired,
  listProduct: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeRowsPerPage: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getProductDetails: PropTypes.func.isRequired,
  getFrequencyCustomer: PropTypes.func.isRequired,
  getFrequencyCustomerReset: PropTypes.func.isRequired,
  detailsProduct: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  listCustomers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  frequency: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
