/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TableRow, TableCell, TableBody, Typography } from '@mui/material';
// import { Dropdown } from '@superfuds/saori';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import { es } from 'date-fns/locale';
import { CustomTitle, BestSellingProduct, Spinner, CustomTable } from '@components/index';
// import { monthByMonthDropdown } from '@constants/index';
import { formatCurrency } from '@utils';
import 'react-datepicker/dist/react-datepicker.css';

import './style.scss';

const titles = ['Nombre', 'Localidad', 'Total Ventas'];

export const PurcharsesTop = ({
  product,
  totalAmount,
  serviceLevel,
  filter,
  loading,
  title,
  customers,
  titleView,
  customFilter,
}) => {
  /// const filteItem = monthByMonthDropdown.filter((option) => option.selected);
  // const [itemFilter, setItemfilter] = useState(1);
  const [dateRange, setDateRange] = useState([moment().startOf('month')?._d, new Date()]);

  return (
    <Grid container className="card" direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12} sx={{ mt: 0, mb: 0, zIndex: 3 }} className="filter">
        {/*  {customFilter ? ( */}
        <Grid container justifyContent="flex-end">
          <Grid
            xs={2}
            justifyContent="end"
            style={{
              marginRight: '20px',
              padding: '10px 15px',
            }}
          >
            <DatePicker
              style={{
                zIndex: 999999,
              }}
              showIcon={false}
              selectsRange
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              dateFormat="yyyy/MM/dd"
              isClearable={false}
              onChange={(update) => {
                setDateRange(update);
                if (update[1] !== null) {
                  filter(null, {
                    dateStart: moment(update[0], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
                      'YYYY/MM/DD',
                    ),
                    dateEnd: moment(update[1], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
                      'YYYY/MM/DD',
                    ),
                  });
                }
              }}
              withPortal
              locale={es}
              minDate={moment().subtract(1, 'year').startOf('year').toDate()}
              maxDate={moment().toDate()}
              customInput={
                <input
                  type="text"
                  style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '16px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    outline: 'none',
                  }}
                />
              }
            />
          </Grid>
        </Grid>
        {/*   ) : (
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
               <DemoContainer
                 components={['DatePicker', 'DatePicker']}
                 sx={{
                   justifyContent: 'end',
                   marginRight: '20px',
                 }}
               >
                 <DatePicker
                   label="Fecha Inicio"
                   minDate={lastYear}
                   value={valueStart}
                   disableFuture
                   onChange={handleStartDateChange}
                 />
                 <DatePicker
                   label="Fecha Fin"
                   value={valueEnd}
                   minDate={lastYear}
                   disableFuture
                   onChange={handleEndDateChange}
                 />
               </DemoContainer>
             </LocalizationProvider>
          <Dropdown
            classNamePrefix="dropdown"
            className="container-filter"
            options={monthByMonthDropdown}
            onChange={(item) => {
              filter(item.value);
              setItemfilter(item.value);
            }}
            defaultValue={monthByMonthDropdown[0]}
            value={monthByMonthDropdown.filter((option) => option.value === itemFilter)}
          />
        )} */}
      </Grid>
      {product?.id !== undefined && product?.name !== undefined ? (
        <>
          {loading ? (
            <Grid
              item
              xs={12}
              className="card_height"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </Grid>
          ) : (
            <>
              <Grid item xs={3} className="card_height">
                {totalAmount > 0 && <CustomTitle title={title} item={totalAmount} active />}
              </Grid>
              <Grid item xs={4} className="card_height">
                {product && <BestSellingProduct product={product} title={titleView} />}
              </Grid>
              <Grid item xs={5} className="card_height text-center">
                {serviceLevel !== 0 ? (
                  <CustomTitle
                    title="Nivel de Servicio"
                    item={serviceLevel === 100 ? 0 : serviceLevel * 100 || 0}
                    percent
                  />
                ) : (
                  <>
                    {customers && customers?.topCustomers && (
                      <Grid className="containerCustomer">
                        <Typography variant="h2" className="title" gutterBottom>
                          Top 5 de Clientes
                        </Typography>
                        <CustomTable titles={titles} data={[]} active={false}>
                          <TableBody className="CustomerBody">
                            {customers &&
                              customers?.topCustomers?.slice(0, 5)?.map((item) => (
                                <TableRow key={item.id}>
                                  <TableCell align="left">{item.name}</TableCell>
                                  <TableCell align="center">{item.location}</TableCell>
                                  <TableCell align="center">
                                    <span>{formatCurrency(item.totalAmount || 0)}</span>
                                  </TableCell>
                                </TableRow>
                              ))}

                            <TableRow>
                              <TableCell colSpan={3} align="right">
                                {customers?.topCustomers && (
                                  <>
                                    <strong>Total</strong>{' '}
                                    <span>
                                      {formatCurrency(
                                        customers?.topCustomers
                                          ?.slice(0, 5)
                                          .reduce(
                                            (accumulator, currentValue) =>
                                              accumulator + currentValue.totalAmount,
                                            0,
                                          ),
                                      )}
                                    </span>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </CustomTable>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </>
          )}
        </>
      ) : (
        <Grid item xs={12} className="card_height container-message">
          <h3 className="sf-title-secondary">
            No hay top de productos más
            {titleView === 'compras' ? ' comprando' : ' vendido'} este mes, <br /> revise
            información de otros meses.
          </h3>
        </Grid>
      )}
    </Grid>
  );
};
PurcharsesTop.defaultProps = {
  loading: false,
  customFilter: false,
  title: 'Comprado',
  titleView: '',
  customers: {
    topCustomers: [],
  },
};

PurcharsesTop.propTypes = {
  product: PropTypes.shape({}).isRequired,
  totalAmount: PropTypes.number.isRequired,
  serviceLevel: PropTypes.number.isRequired,
  filter: PropTypes.func.isRequired,
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      topCustomers: PropTypes.shape({}),
    }),
  ),
  loading: PropTypes.bool,
  customFilter: PropTypes.bool,
  title: PropTypes.string,
  titleView: PropTypes.string,
};
