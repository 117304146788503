import { call } from 'redux-saga/effects';
import { odooApi } from './api';

export const fetchData = () => {
  return fetch('https://superfuds-assets.s3.sa-east-1.amazonaws.com/utils/products.json', {
    headers: {
      Accept: 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);
};

export const SellIntService = {
  *getProductRanking(date) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/product_ranking', {
      months: 1,
      ...date,
    });
  },
  *getSummary(months, chanel, date) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/summary', {
      months: months || 12,
      chanel: chanel || '',
      ...date,
    });
  },
  *getTopProduct(months, date) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/top_product_summary', {
      // months: months || 6,
      ...date,
    });
  },
  *getFequency(months, date) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/frequency', {
      // months: months || 6,
      ...date,
    });
  },
  *getDelivered(months, chanel) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/delivered', {
      months: 12,
      chanel: chanel || '',
    });
  },
  *getProductMonth(months) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/product_month', {
      months: months || 6,
    });
  },
  *getTopCustomerSales(months, date) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/top_customer_sales', {
      // months: months || 6,
      ...date,
    });
  },
  *getCustomerActivity(months, chanel) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/customer_activity', {
      months: months || 6,
      chanel: chanel || '',
    });
  },
  *getTopProductSale(months) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/top_product_sales', {
      months: months || 6,
    });
  },
  *getTotalProductSales(months) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/total_product_sales', {
      months: months || 6,
    });
  },
  *getStockProductsList(months) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/stock/products_list', {
      months: months || 6,
    });
  },
  *getStockProductsRelacionated(months) {
    return yield call(
      { context: odooApi, fn: odooApi.get },
      '/sascha/stock/products_relacionated',
      {
        months: months || 6,
      },
    );
  },
  *getProductAll(item, date) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/sales_by_product', {
      months: item?.months || 6,
      chanel: item?.chanel || '',
      ...date,
    });
  },
  *getListProductSales(months) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/stock/products_list', {
      months: months || 6,
    });
  },
  *getProductDetails(id) {
    return yield call(
      { context: odooApi, fn: odooApi.get },
      '/sascha/sales/product_sales_frequency',
      {
        productId: id,
      },
    );
  },
  *getCustomersList() {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/customers_list', {});
  },
  *getFrequencyCustomer(clientId, productId) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/frequency_customer', {
      clientId,
      productId,
    });
  },
  *getStockProductDetails(id) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sales/list_product_sales', {
      clientId: id,
    });
  },
  *getSellout(client, date) {
    // return yield call(fetchData);
    return yield call(
      { context: odooApi, fn: odooApi.post },
      `/sascha/sellout/summary?dateStart=2024/01/01&dateEnd=2024/02/01`,
      {},
      {
        client: ' ALMACENES ÉXITO',
      },
    );
  },
  *getSelloutClient() {
    // return yield call(fetchData);
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/sellout/clients', {});
  },
};
