import { put } from 'redux-saga/effects';

import mixpanel from 'mixpanel-browser';
import { UserService } from '@services/user.service';
import { Creators as UserCreators } from '@reducers/User';
import { Creators as UserStartUp } from '@reducers/StartUp';

export function* getUserData() {
  try {
    const userData = yield UserService.getUserData();
    mixpanel.alias(userData?.eid);
    mixpanel.identify(userData?.eid);
    mixpanel.people.set({
      ...userData,
      $name: userData.name,
      $email: userData.email,
      $user_id: userData?.eid,
    });
    yield put(UserCreators.getUserSuccess(userData));
    yield put(UserStartUp.startup());
  } catch (error) { }
}

export function* logout() {
  yield put(UserCreators.reset());
  localStorage.clear();
  window.location.replace(`${window.location.origin}/${window.location.search}`);
}
