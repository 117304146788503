/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { PAGE, ROWS_PER_PAGE } from '@constants/index';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  loading: false,
  loadingBills: false,
  loadingCorrective: false,
  loadingLines: false,
  bills: [],
  billsLines: [],
  corrective: [],
  message: '',
  pagination: {
    page: PAGE,
    rowsPerPage: ROWS_PER_PAGE,
  },
});
/* ------------- Reducers ------------- */
export const accountBillsrequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingBills: true,
});
export const accountBillsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loadingBills: false,
  bills: data?.bills,
  pagination: {
    page: PAGE,
    rowsPerPage: ROWS_PER_PAGE,
  },
});

export const changeRowsPerPage = (state = INITIAL_STATE, { PerPage }) => ({
  ...state,
  pagination: {
    rowsPerPage: PerPage,
    page: PAGE,
  },
});
export const changePage = (state = INITIAL_STATE, { page }) => ({
  ...state,
  pagination: {
    ...state.pagination,
    page,
  },
});
export const paymentSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  message: data,
});

export const paymentReset = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  message: '',
});
export const accountBillsCorrectiverequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loadingCorrective: true,
  };
};
export const accountBillsCorrectiveSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loadingCorrective: false,
  corrective: data,
  pagination: {
    page: PAGE,
    rowsPerPage: ROWS_PER_PAGE,
  },
});
export const getAccountLinesRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingLines: true,
  billsLines: [],
  loadingCorrective: false,
});
export const getAccountLinesSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loadingLines: false,
  billsLines: data,
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ACCOUNT_BILLS]: accountBillsrequest,
  [Types.ACCOUNT_BILLS_SUCCESS]: accountBillsSuccess,
  [Types.CHANGE_ROWS_PER_PAGE]: changeRowsPerPage,
  [Types.CHANGE_PAGE]: changePage,
  [Types.PREPAYMENT_SUCCESS]: paymentSuccess,
  [Types.PREPAYMENT_RESET]: paymentReset,
  [Types.GET_ACCOUNT_BILLS_CORRECTIVE]: accountBillsCorrectiverequest,
  [Types.ACCOUNT_BILLS_CORRECTIVE_SUCCESS]: accountBillsCorrectiveSuccess,
  [Types.GET_ACCOUNT_LINES_SUCCESS]: getAccountLinesSuccess,
  [Types.GET_ACCOUNT_LINES]: getAccountLinesRequest,
});