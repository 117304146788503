// @ts-nocheck
import { takeLatest, all } from 'redux-saga/effects';
import { Types as StartupTypes } from '@reducers/StartUp';
import { Types as PurchasesSaga } from '@reducers/Purchases';
import { Types as AuthSagas } from '@reducers/Auth';
import { Types as UserSagas } from '@reducers/User';
import { Types as AccountSagas } from '@reducers/Account';
import { Types as StocksSagas } from '@reducers/Stocks';
import { Types as SellInSagas } from '@reducers/SellIn';

/* ------------- Sagas ------------- */

import { startup } from './StartupSagas';
import { authentication } from './auth/AuthSagas';
import { getUserData, logout } from './user/UserSagas';
import {
  getTotalSold,
  getTopProduct,
  getSummary,
  serviceLevel,
  averageTicket,
  getfrequency,
  filterTopPurchases,
  getRankingProductPurchase,
  getOrderList,
  getOrderDownload,
  getOrderLines,
} from './purchase/PurchaseSaga';
import {
  get,
  updateStatusProduct,
  getPickingList,
  getProductsFulfillment,
  getContactsFulfillment,
  createContactFulfillment,
  createDeliveryContact,
  createOrderDelivery,
  createOrderPicking,
  updateContactAddrees,
} from './stocks/StocksSaga';
import {
  getAccountBills,
  paymentBill,
  getAccountBillsCorrective,
  getAccountLines,
} from './accounting/Accounting';

import {
  getRankingProductSales,
  getFequencySales,
  getTopProductSales,
  getTopCustomerSales,
  filterTopSales,
  filterRankingProductSales,
  filterProductSales,
  getSummarySales,
  getSummaryALL,
  filterChanelMonth,
  filterCustomerActivity,
  getListProductSales,
  getProductDetails,
  getCustomersList,
  getFrequencyCustomer,
  getProductsClients,
  getSellOut,
  getSellOutClients,
} from './sellInReport/SellInSaga';
/* ------------- Connect Types To Sagas ------------- */
export function* rootSaga() {
  yield all([
    takeLatest(AuthSagas.LOGIN_REQUEST, authentication),
    takeLatest(UserSagas.GET_USER_REQUEST, getUserData),
    takeLatest(UserSagas.LOGOUT_USER, logout),
    takeLatest(PurchasesSaga.TOTAL_SOLD, getTotalSold),
    takeLatest(PurchasesSaga.GET_SUMMARY, getSummary),
    takeLatest(PurchasesSaga.GET_TOP_PRODUCT, getTopProduct),
    takeLatest(PurchasesSaga.GET_SERVICE_LEVEL, serviceLevel),
    takeLatest(StocksSagas.GET_STOCKS, get),
    takeLatest(StocksSagas.GET_PICKING_LIST, getPickingList),
    takeLatest(StocksSagas.GET_PRODUCTS_FULFILLMENT, getProductsFulfillment),
    takeLatest(StocksSagas.GET_CONTACTS_FULFILLMENT, getContactsFulfillment),
    takeLatest(StocksSagas.CREATE_CONTACT_FULFILLMENT, createContactFulfillment),
    takeLatest(StocksSagas.PUT_UPDATE_STATUS_PRODUCT, updateStatusProduct),
    takeLatest(StocksSagas.CREATE_DELIVERY_CONTACT, createDeliveryContact),
    takeLatest(StocksSagas.CREATE_ORDER_DELIVERY, createOrderDelivery),
    takeLatest(StocksSagas.CREATE_ORDER_PICKING, createOrderPicking),
    takeLatest(StocksSagas.UPDATE_CONTACT_ADDREES, updateContactAddrees),
    takeLatest(PurchasesSaga.GET_AVERAGE_TICKET, averageTicket),
    takeLatest(PurchasesSaga.GET_FREQUENCY_PURCHASE, getfrequency),
    takeLatest(PurchasesSaga.GET_PURCHASE_ORDERS_DOWNLOAD, getOrderDownload),
    takeLatest(PurchasesSaga.FILTER_TOP_PURCHASES, filterTopPurchases),
    takeLatest(PurchasesSaga.GET_PURCHASE_ORDERS, getOrderList),
    takeLatest(PurchasesSaga.GET_ORDER_LINES, getOrderLines),
    takeLatest(PurchasesSaga.GET_RANKING_PRODUCT, getRankingProductPurchase),
    takeLatest(AccountSagas.GET_ACCOUNT_BILLS, getAccountBills),
    takeLatest(AccountSagas.GET_ACCOUNT_LINES, getAccountLines),
    takeLatest(AccountSagas.GET_ACCOUNT_BILLS_CORRECTIVE, getAccountBillsCorrective),
    takeLatest(AccountSagas.PAYMENT_BILL, paymentBill),
    takeLatest(SellInSagas.GET_RANKING_PRODUCT_SALES, getRankingProductSales),
    takeLatest(SellInSagas.GET_FREQUENCY, getFequencySales),
    takeLatest(SellInSagas.GET_TOP_PRODUCT, getTopProductSales),
    takeLatest(SellInSagas.GET_TOP_CUSTOMER_SALES, getTopCustomerSales),
    takeLatest(SellInSagas.FILTER_TOP_SALES, filterTopSales),
    takeLatest(SellInSagas.FILTER_RANKING_PRODUCT_SALES, filterRankingProductSales),
    takeLatest(SellInSagas.FILTER_PRODUCT_SALES, filterProductSales),
    takeLatest(SellInSagas.GET_SUMMARY_SALES, getSummarySales),
    takeLatest(SellInSagas.GET_SUMMARY_ALL, getSummaryALL),
    takeLatest(SellInSagas.GET_SELL_OUT_CLIENTS, getSellOutClients),
    takeLatest(SellInSagas.FILTER_CHANEL_MONTH, filterChanelMonth),
    takeLatest(SellInSagas.FILTER_CUSTOMER_ACTIVITY, filterCustomerActivity),
    takeLatest(SellInSagas.GET_LIST_PRODUCT_SALES, getListProductSales),
    takeLatest(SellInSagas.GET_PRODUCT_DETAILS, getProductDetails),
    takeLatest(SellInSagas.GET_CUSTOMERS_LIST, getCustomersList),
    takeLatest(SellInSagas.GET_FREQUENCY_CUSTOMER, getFrequencyCustomer),
    takeLatest(SellInSagas.GET_PRODUCTS_CLIENTS, getProductsClients),
    takeLatest(SellInSagas.GET_SELL_OUT, getSellOut),
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup),
    // some sagas receive extra parameters in addition to an action
  ]);
}
