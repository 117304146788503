import { BaseApi } from '@superfuds/sf-base-api';
import { NODE_ENVIRONMENT } from '@constants/env-vars.constants';
import { ODOO_HOST, DEMO_USER, ODOO_HOST_DEMO, TOKEN_KEY } from '@constants/index';

class OdooApi extends BaseApi {
  constructor() {
    super({
      basePath: '',
      environment: NODE_ENVIRONMENT,
      prefix: '/api',
      opts: {
        authTokenKey: TOKEN_KEY,
        addPlatform: false,
      },
      customHeaders: {
        'Content-Type': 'text/plain',
      },
    });
  }

  setBasePath(newBasePath) {
    this.basePath = `${newBasePath}/api`;
  }
}

let url = '';

if (localStorage.getItem('DEMO_USER')) {
  url = localStorage.getItem('DEMO_USER') === DEMO_USER ? ODOO_HOST_DEMO : ODOO_HOST;
} else {
  url = ODOO_HOST;
}
const restApi = new OdooApi();
restApi.setBasePath(url);

export const odooApi = restApi;
