// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Button,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { connect } from 'react-redux';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoIcon from '@mui/icons-material/Info';
import ReactExport from 'react-export-excel';
import { Chart } from '@superfuds/sf-charts';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { es } from 'date-fns/locale';

// import moment from 'moment';
import { formatCurrency } from 'utils';
import { Creators as Sales } from '@reducers/SellIn';

import { CustomTable, ModalLayout, Spinner } from '@components/index';
import { optionsChartSellOut, optionsChartSellOutUnds } from '@constants/index';

import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const titles = ['Mes', 'Unidades Vendidas', 'Total', ''];

const isNegativeNumber = (number) => {
  const color = number < 0 ? '#EF4044' : '#25c16a';
  const content = number < 0 ? `Decrecimiento ${number}%` : `Crecimiento ${number}%`;

  return (
    <Typography style={{ color }} variant="caption" display="block" gutterBottom>
      {content}
    </Typography>
  );
};

const SellOutContainer = ({
  products,
  pagination,
  getSellOut,
  loading,
  changeRowsPerPage,
  changePage,
  totalAmount,
  totalUnits,
  getSellOutClients,
  sellOutCliens,
}) => {
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [client, setClient] = useState(sellOutCliens && sellOutCliens[0]);
  const [dateRange, setDateRange] = useState([moment().startOf('year').toDate(), new Date()]);

  const getSellout = (item) => {
    if (item) {
      const clientName = item || client?.client_name;
      getSellOut(clientName, {
        dateStart: moment(dateRange[0], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('YYYY/MM/DD'),
        dateEnd: moment(dateRange[1], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('YYYY/MM/DD'),
      });
    }
  };

  useEffect(() => {
    getSellOutClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sellOutCliens && sellOutCliens.length > 0) {
      setClient(sellOutCliens?.[0]);
      getSellout(sellOutCliens?.[0]?.client_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellOutCliens]);

  const filteredData =
    products?.length > 0 &&
    products?.filter((row) => {
      const rowValues = Object.values(row).join(' ').toLowerCase();
      return rowValues;
    });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dataSet2 = products?.map((item) => item.sell_outs)?.flat();

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const result = products?.map((obj) => capitalizeFirstLetter(obj?.date)).join(', ');
  const mensaje =
    products.length !== 0
      ? `El valor representa las ventas históricas del cliente seleccionado en los meses de:
      ${result}`
      : '';

  return (
    <Grid
      container
      item
      justifyContent="flex-start"
      id="sf-SellOutContainer"
      className="sf-SellOutContainer"
    >
      <ModalLayout open={open} handleClose={handleClose}>
        <Grid item xs={12} className="pb-1 pt-3">
          <h2 className="sf-title">
            Productos agrupados para
            <span className="primary-font-color"> {currentItem?.date?.toUpperCase() || ''}</span>
          </h2>
        </Grid>
        <Grid
          item
          xs={7}
          style={{
            backgroundColor: 'rgb(255, 244, 229)',
            padding: 10,
            marginBottom: '30px',
          }}
        >
          <h6
            className="text-left"
            style={{
              fontSize: '.8rem',
              fontWeight: 300,
              margin: '0px',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <InfoIcon
              style={{
                color: '#25c16a',
              }}
            />
            <strong>Información importante sobre los Productos</strong>
          </h6>
          <ul className="terms">
            <li>
              Para descargar la información, simplemente haz clic en el botón de descarga. Se
              generará un archivo que contiene dos páginas:
              <ul>
                <li>Una página muestra los productos agrupados por mes.</li>
                <li>
                  Otra página proporciona detalles adicionales sobre cada producto, como la tienda,
                  canal de venta y la fecha de compra.
                </li>
              </ul>
            </li>
          </ul>
        </Grid>
        <Grid
          item
          container
          justifyContent="end"
          alignItems="center"
          sx={{ mt: 1, mb: 0, zIndex: 3, paddingLeft: 2, paddingRight: 2 }}
          className="text-left"
        >
          <Grid item xs={2} sx={{ mt: 1, mb: 2 }} className="text-right">
            <ExcelFile
              filename="Detalles de productos"
              element={
                <Button
                  variant="contained"
                  className="btn btn-primary"
                  endIcon={<FileDownloadIcon />}
                >
                  Descargar
                </Button>
              }
            >
              <ExcelSheet data={currentItem?.products ?? []} name="SellOut ">
                <ExcelColumn label="Cliente" value="client" />
                <ExcelColumn label="Tienda" value="store" />
                <ExcelColumn label="Canal" value="chanel" />
                <ExcelColumn label="SKU" value="sku" />
                <ExcelColumn label="Código de Barra" value="barcode" />
                <ExcelColumn label="Producto" value="description" />
                <ExcelColumn label="Marca" value="brand" />
                <ExcelColumn label="Categoria" value="category" />
                <ExcelColumn label="Unidades" value="quantity" />
                <ExcelColumn label="Total" value="amount" />
              </ExcelSheet>
            </ExcelFile>
          </Grid>
        </Grid>
        <CustomTable
          titles={[
            'Cliente',
            'Tienda',
            'Canal',
            'SKU',
            'Código de Barra',
            'Producto',
            'Marca',
            'Categoria',
            'Unidades',
            'Total',
          ]}
          data={currentItem?.products ?? []}
        >
          <TableBody>
            {currentItem?.products?.map((item, _) => (
              <TableRow key={_}>
                <TableCell align="center">{item?.client}</TableCell>
                <TableCell align="center">{item?.store}</TableCell>
                <TableCell align="center">{item?.chanel}</TableCell>
                <TableCell align="center">{item?.sku}</TableCell>
                <TableCell align="center">{item?.barcode}</TableCell>
                <TableCell align="center">{item?.description}</TableCell>
                <TableCell align="center">{item?.brand}</TableCell>
                <TableCell align="center">{item?.category}</TableCell>
                <TableCell align="center">{item?.quantity}</TableCell>
                <TableCell align="center">
                  <span className="primary-font-color">{formatCurrency(item?.amount)}</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </CustomTable>
      </ModalLayout>

      <Grid item xs={12} className="pb-1 pt-3">
        <h2 className="sf-title">Sell Out</h2>
      </Grid>
      <Grid
        item
        xs={7}
        style={{
          backgroundColor: 'rgb(255, 244, 229)',
          padding: 10,
          marginBottom: '30px',
        }}
      >
        <h6
          className="text-left"
          style={{
            fontSize: '.8rem',
            fontWeight: 300,
            margin: '0px',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <InfoIcon
            style={{
              color: '#25c16a',
            }}
          />
          <strong>Información importante sobre el informe</strong>
        </h6>
        <ul className="terms">
          <li> El cliente Farmatodo la información se actualiza todos los lunes de cada semana.</li>
          <li> Los demas clientes se actualizar cada mes.</li>
          <li>
            Los datos presentados comienzan a partir de <strong>Noviembre de 2023</strong>.
          </li>
          <li>Debes Siempre seleccionar el cliente que quieres ver la información</li>
        </ul>
      </Grid>
      <Grid
        container
        className="card"
        justifyContent="space-between"
        alignItems="center"
        style={{
          padding: '20px 20px',
          margin: '2rem 0px',
        }}
      >
        <Grid item xs={5}>
          <h2 className="sf-title-secondary">Resumen </h2>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Seleccionar Cliente</InputLabel>
            <Select
              onChange={(e) => {
                e.preventDefault();
                const selectedClient = sellOutCliens.filter(
                  (item) => item.client_name === e.target.value,
                );

                if (selectedClient.length === 1) {
                  getSellOut(selectedClient[0]?.client_name, {
                    dateStart: moment(dateRange[0], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
                      'YYYY/MM/DD',
                    ),
                    dateEnd: moment(dateRange[1], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
                      'YYYY/MM/DD',
                    ),
                  });
                  setClient(selectedClient[0]);
                }
              }}
              value={client?.client_name ?? ''}
            >
              {sellOutCliens &&
                sellOutCliens.map((row, _) => {
                  return (
                    <MenuItem key={_} value={row.client_name}>
                      {row.client_name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <InputLabel id="demo-simple-select-label">Fecha</InputLabel>
          <DatePicker
            style={{
              zIndex: 999999,
              width: '100%',
            }}
            showIcon={false}
            selectsRange
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            dateFormat="yyyy/MM/dd"
            isClearable={false}
            onChange={(update) => {
              setDateRange(update);
              if (update[1] !== null) {
                getSellOut(client?.client_name, {
                  dateStart: moment(update[0], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
                    'YYYY/MM/DD',
                  ),
                  dateEnd: moment(update[1], 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
                    'YYYY/MM/DD',
                  ),
                });
              }
            }}
            locale={es}
            minDate={moment().subtract(1, 'year').startOf('year').toDate()}
            maxDate={moment().toDate()}
            customInput={
              <input
                type="text"
                style={{
                  width: '50%',
                  padding: '10px',
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  outline: 'none',
                }}
              />
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        className="card"
        justifyContent="center"
        alignItems="center"
        style={{
          padding: '20px 20px',
        }}
      >
        {products && products?.length === 0 && sellOutCliens && sellOutCliens.length > 0 ? (
          <Grid
            container
            className="card"
            justifyContent="center"
            alignItems="center"
            style={{
              padding: '20px 10px',
            }}
          >
            <Spinner />
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <Typography variant="h2" className="title" gutterBottom>
                Total COP
              </Typography>
              <Typography className="text-secondary primary-font-color" variant="body1">
                {formatCurrency(totalAmount || 0)}
              </Typography>
              <Typography variant="caption" display="block" className="caption-info" gutterBottom>
                {mensaje || ''}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h2" className="title" gutterBottom>
                Total Unidades
              </Typography>
              <Typography className="text-secondary primary-font-color" variant="body1">
                {totalUnits || 0} <span className="primary-font-color">Unidades</span>
              </Typography>
              <Typography variant="caption" className="caption-info" display="block" gutterBottom>
                {mensaje || ''}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        container
        direction="row"
        className="containerfequency"
        sx={{ mt: 7 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <h2 className="sf-title-secondary">Tendencia Mensual en COP</h2>
        </Grid>
        <Grid
          className="card"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {loading ? (
            <Grid
              container
              className="card"
              justifyContent="center"
              alignItems="center"
              style={{
                padding: '20px 10px',
              }}
            >
              <Spinner />
            </Grid>
          ) : (
            <>
              {products && products?.length > 0 ? (
                <Chart data={products} options={optionsChartSellOut(products)} />
              ) : (
                <Grid item xs={12} className="card_height container-message">
                  <h3 className="sf-title-secondary">No hay Resultados</h3>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        className="containerfequency"
        sx={{ mt: 7 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <h2 className="sf-title-secondary">Tendencia Mensual en Unidades</h2>
        </Grid>
        <Grid
          className="card"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {loading ? (
            <Grid
              container
              className="card"
              justifyContent="center"
              alignItems="center"
              style={{
                padding: '20px 10px',
              }}
            >
              <Spinner />
            </Grid>
          ) : (
            <>
              {products && products?.length > 0 ? (
                <Chart data={products} options={optionsChartSellOutUnds(products)} />
              ) : (
                <Grid item xs={12} className="card_height container-message">
                  <h3 className="sf-title-secondary">No hay Resultados</h3>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-6">
        <h2 className="sf-title-secondary"> Historico de Ventas Mensuales </h2>
      </Grid>
      <Grid container className="card">
        <Grid
          item
          container
          justifyContent="end"
          alignItems="center"
          sx={{ mt: 1, mb: 0, zIndex: 3, paddingLeft: 2, paddingRight: 2 }}
          className="text-left"
        >
          {filteredData && filteredData?.length > 0 && (
            <Grid item xs={2} sx={{ mt: 1, mb: 2 }} className="text-right">
              <ExcelFile
                filename="SellOut"
                element={
                  <Button
                    variant="contained"
                    className="btn btn-primary"
                    endIcon={<FileDownloadIcon />}
                  >
                    Descargar
                  </Button>
                }
              >
                <ExcelSheet data={products ?? []} name="SellOut">
                  <ExcelColumn label="Mes" value="date" />
                  <ExcelColumn label="Unidades Vendidas" value={(col) => col?.units?.total} />
                  <ExcelColumn label="Total" value={(col) => formatCurrency(col?.amount?.total)} />
                </ExcelSheet>
                <ExcelSheet data={dataSet2 ?? []} name="Detalle de Productos SellOut Farmatodo">
                  <ExcelColumn label="EAN" value="barcode" />
                  <ExcelColumn label="Producto" value="description" />
                  <ExcelColumn label="Canal" value="chanel" />
                  <ExcelColumn label="Categoria" value="category" />
                  <ExcelColumn label="Unidades" value="units" />
                  <ExcelColumn label="Total" value={(col) => formatCurrency(col?.amount)} />
                </ExcelSheet>
              </ExcelFile>
            </Grid>
          )}
        </Grid>
        {loading ? (
          <Grid
            container
            className="card"
            justifyContent="center"
            alignItems="center"
            style={{
              padding: '20px 10px',
            }}
          >
            <Spinner />
          </Grid>
        ) : (
          <>
            {filteredData && filteredData?.length > 0 ? (
              <CustomTable
                titles={titles}
                data={filteredData}
                active={false}
              /*      onPageChange={(event, page) => changePage(page)}
onRowsPerPageChange={(event) => changeRowsPerPage(event.target.value)} */
              >
                <TableBody>
                  {filteredData &&
                    [...filteredData].sort().map((item, _) => (
                      <TableRow key={_}>
                        <TableCell align="center">{item?.date?.toUpperCase()}</TableCell>
                        <TableCell align="center">
                          {item?.units?.total}
                          {isNegativeNumber(parseInt(item?.units?.growth_in_percentage * 100, 10))}
                        </TableCell>
                        <TableCell align="center">
                          {formatCurrency(item?.amount?.total)}
                          {isNegativeNumber(parseInt(item?.amount?.growth_in_percentage * 100, 10))}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            className="button-icon-table"
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              handleOpen();
                              setCurrentItem({
                                products: item?.sell_outs,
                                date: item?.date,
                              });
                            }}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </CustomTable>
            ) : (
              <Grid item xs={12} className="card_height container-message">
                <h3 className="sf-title-secondary">No hay Resultados</h3>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

SellOutContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  getSellOut: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sellOutCliens: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeRowsPerPage: PropTypes.func.isRequired,
  getSellOutClients: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  totalAmount: PropTypes.number.isRequired,
  totalUnits: PropTypes.number.isRequired,
};

const mapStateToProps = ({
  sales: {
    sellout: { loading, products, pagination, totalAmount, totalUnits, sellOutCliens },
  },
}) => ({
  loading,
  products,
  pagination,
  totalAmount,
  totalUnits,
  sellOutCliens,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getSellOut: (item, date) => dispatch(Sales.getSellOut(item, date)),
    changeRowsPerPage: (item) => dispatch(Sales.changeRowsPerPageSellOut(item)),
    changePage: (item) => dispatch(Sales.changePageSellOut(item)),
    getSellOutClients: () => dispatch(Sales.getSellOutClients()),
  };
};
export const SellOut = connect(mapStateToProps, mapDispatchToProps)(SellOutContainer);
