export const {
  REACT_APP_ENVIRONMENT: NODE_ENVIRONMENT,
  REACT_APP_WEB_URL: WEB_URL,
  REACT_APP_AWS_ASSETS: AWS_ASSETS,
  REACT_APP_REDUX_LOGGER_ENABLED: REDUX_LOGGER_ENABLED,
  REACT_APP_EMAIL: EMAIL,
  REACT_APP_PASSWORD: PASSWORD,
} = process.env;

export const isProd = NODE_ENVIRONMENT === 'production';
