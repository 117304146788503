import { call } from 'redux-saga/effects';
import { odooApi } from './api';

export const AccountService = {
  *getAccount() {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/account/bills');
  },
  *getAccountCorrective() {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/account/credit_notes');
  },
  *paymentBill(id) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/sascha/account/prepayment',
      {},
      {
        bill_id: id,
      },
    );
  },
  *getAccountLines({ id }) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/account/invoice_lines', {
      id,
    });
  },
};
