export const transformServiceStock = (response) => {
  const mapLots = (item, json) => ({
    ...item,
    expirationDate: item.expiration_date,
    lifeTime: item.life_time,
    quantity: item.product_qty,
    name: `demo`,
    sf_ecom_description: `demo`,
    availableQty: item?.available_qty,
    reservedQty: item?.reserved_qty,
  });

  const mapItems = (json) => ({
    ...json,
    id: json.id,
    daysInventory: json?.days_inventory,
    name: `demo ${json.id}`,
    totalAvailable: json?.total_available,
    totalReserved: json?.total_reserved,
    product_sku: `demo ${json.id}`,
    product_barcode: `demo ${json.id}`,
    product_brand: `demo ${json.id}`,
    sf_ecom_description: `demo`,
    lots: json.lots.map((rows) => mapLots(rows, json)),
    imagen:
      'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/placeholder-image.jpg',
  });
  return response.map((items) => mapItems(items));
};

export const stateTranslations = {
  draft: 'Borrador',
  waiting: 'Esperando otra operación',
  confirmed: 'En espera',
  assigned: 'Preparado',
  done: 'Terminado',
  cancel: 'Cancelado',
};

export const stateStyle = {
  'Esperando otra operación': 'warning.main',
  'En espera': 'secondary.main',
  Preparado: 'info.main',
  Borrador: 'text.primary',
  Terminado: 'success.main',
  Cancelado: 'error.main',
};
export const deliveryStateTranslations = {
  pending: 'Pendiente',
  enlisted: 'Alistado',
  shipped: 'Despachado',
  delivered: 'Entregado',
  cancelled: 'Cancelado',
};

export const colorDeliveryState = {
  Despachado: 'warning.main',
  Alistado: 'secondary.main',
  Pendiente: 'info.main',
  pending: 'text.primary',
  Entregado: 'success.main',
  Cancelado: 'error.main',
};
