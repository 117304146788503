import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';

import { formatCurrency } from '@utils';

export const CustomTitle = ({ item, title, active, percent, activeNumber }) => {
  return (
    <Grid item>
      <Typography variant="h2" className="title" gutterBottom>
        {title}
      </Typography>
      {activeNumber ? (
        <Typography className="text-secondary" variant="body1">
          <span className="primary-font-color">
            {' '}
            {item}{' '}
            <span
              style={{
                fontSize: '1.3rem',
              }}
            >
              Unidades
            </span>
          </span>
        </Typography>
      ) : (
        <Typography className="text-secondary" variant="body1">
          {active && !percent && <span className="symbol">$</span>}
          {!active
            ? ((percent && item === 100) || item >= 1 ? `${item}%` : `${item * 100}%`) || item
            : formatCurrency(item, false)}
        </Typography>
      )}
    </Grid>
  );
};
CustomTitle.defaultProps = {
  active: false,
  percent: false,
  activeNumber: false,
};

CustomTitle.propTypes = {
  item: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  percent: PropTypes.bool,
  activeNumber: PropTypes.bool,
};
