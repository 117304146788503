import { put, call } from 'redux-saga/effects';

import moment from 'moment';
import { capitalize } from 'utils';
import { HOST } from '@constants/index';
import { transformServiceSaleFequency } from '@transforms/ApiToServiceSalestransform';
import { transformServiceTopProductSale } from '@transforms/ApiToServicesTopProduct.transform';
import {
  transformServiceTopCustomer,
  transformServiceCustomerActivity,
} from '@transforms/ApiToServiceTopCustomerSalestransform';
import { transformServiceSummary } from '@transforms/ApiToServiceSummarytransform';

import { SellIntService } from '@services/sellInReport.service';
import { Creators as SalesCreators } from '@reducers/SellIn';

export function* getDelivered({ months, chanel }) {
  try {
    const data = yield SellIntService.getDelivered(months, chanel);
    const orders = data?.orders?.slice(0, 3).map((item) => {
      return {
        ...item,
        order_name: `Demo ${item.id}`,
        product_name: `Demo ${item.id}`,
      };
    });
    yield put(SalesCreators.deliveredSalesSuccess(orders));
  } catch (error) { }
}

export function* getSummarySales({ months, chanel = '', date }) {
  try {
    yield call(getDelivered, { months, chanel });
    const response = yield SellIntService.getSummary(months, chanel, date);
    const items = transformServiceSummary(response);
    yield put(SalesCreators.frequencySalesSuccess(items));
    yield put(SalesCreators.summarySalesSuccess(items?.summary));
  } catch (error) { }
}

export function* getRankingProductSales({ date }) {
  try {
    const response = yield SellIntService.getProductRanking(date);
    const data = response?.products.map((item) => {
      return {
        ...item,
        product_name: `Demo ${item.product_id}`,
        product_ean: `demo ${item.product_id}`,
        product_brand: `Demo ${item.product_id}`,
      };
    });
    yield put(
      SalesCreators.rankingProductSalesSuccess({
        products: data.slice(0, 3),
      }),
    );
  } catch (error) { }
}

export function* getProductSales({ item, date }) {
  try {
    const { products: response } = yield SellIntService.getProductAll(item, date);
    if (!response) {
      throw new Error('Network response was not ok');
    }
    const data = response.map((item) => {
      return {
        ...item,
        product_name: `Demo ${item.product_id}`,
        product_ean: `demo ${item.product_id}`,
        product_brand: `Demo ${item.product_id}`,
        shipping_client: `Demo ${item.product_id}`,
        product_client: `Demo ${item.product_id}`,
      };
    });
    yield put(SalesCreators.productSalesSuccess(data.slice(0, 3)));
  } catch (error) {
    yield put(SalesCreators.productSalesSuccess([]));
  }
}

export function* getTopProductSales({ months, date }) {
  try {
    const response = yield SellIntService.getTopProduct(months, date);
    const items = transformServiceTopProductSale(response?.product);
    yield put(SalesCreators.topProductSuccess(items));
  } catch (error) { }
}

export function* getSummaryALL({ months = 12, chanel = '' }) {
  try {
    yield call(getDelivered, { months, chanel });
    const response = yield SellIntService.getSummary(months, chanel);
    const items = transformServiceSummary(response);
    yield put(SalesCreators.summarySalesSuccess(items?.summary));
  } catch (error) { }
}

export function* getCustomerActivity({ chanel, months }) {
  try {
    const response = yield SellIntService.getCustomerActivity(months, chanel);
    if (response?.activity) {
      const data = transformServiceCustomerActivity(response?.activity);
      yield put(SalesCreators.customerActivitySuccess(data.slice(0, 3)));
    } else {
      yield put(SalesCreators.customerActivitySuccess([]));
    }
  } catch (error) { }
}

export function* getFequencySales({ months, date }) {
  try {
    const response = yield SellIntService.getFequency(months, date);
    const items = transformServiceSaleFequency(response);
    yield put(SalesCreators.frequencySalesSuccess(items));
  } catch (error) { }
}

export function* getTopCustomerSales({ months, date }) {
  try {
    const response = yield SellIntService.getTopCustomerSales(months, date);
    const items = transformServiceTopCustomer(response[0]);
    yield put(SalesCreators.topCustomerSalesSuccess(items));
  } catch (error) { }
}

export function* filterTopSales({ date }) {
  try {
    yield call(getSummarySales, { months: 1, date });
    yield call(getTopProductSales, { months: 1, date });
    yield call(getTopCustomerSales, { months: 1, date });
  } catch (error) { }
}

export function* filterRankingProductSales({ item, date }) {
  try {
    yield call(getRankingProductSales, { date });
  } catch (error) { }
}

export function* filterProductSales({ item, date }) {
  try {
    yield call(getProductSales, { item, date });
  } catch (error) { }
}
export function* filterChanelMonth({ filters: { chanel, months } }) {
  try {
    yield call(getSummarySales, { months, chanel });
  } catch (error) { }
}

export function* filterCustomerActivity({ filters: { chanel, months } }) {
  try {
    yield call(getCustomerActivity, { months, chanel });
  } catch (error) { }
}

export function* getListProductSales({ months }) {
  try {
    const data = yield SellIntService.getListProductSales(months);
    const response = Object.values(data);
    const newData = response.map((item) => {
      const totalProductQty = item.total_product_qty;
      const totalSalesAmount = item.total_sales_amount;
      const totalSalesQty = item.total_sales_qty;
      return {
        ...item,
        name: `Demo ${item.id}`,
        image: 'https://nayemdevs.com/wp-content/uploads/2020/03/default-product-image.png',
        totalProductQty,
        totalSalesAmount,
        totalSalesQty,
      };
    });
    yield put(SalesCreators.listProductSalesSuccess(newData));
    yield put(SalesCreators.productDetailsSuccess([]));
  } catch (error) { }
}

export function* getProductDetails({ id }) {
  try {
    if (id === undefined) {
      yield put(SalesCreators.productDetailsSuccess([]));
    } else {
      const { frequency: response } = yield SellIntService.getProductDetails(id);
      if (!response) {
        throw new Error('No se pudo establecer conexión con el servidor. Intente de nuevo.');
      }
      const data = response.slice(0, 3)?.map((item) => {
        const formattedDate = moment(item?.month_date, 'YYYY-MM').locale('es').format('MMMM YYYY');
        return {
          ...item,
          month_name: capitalize(formattedDate),
        };
      });
      yield put(SalesCreators.productDetailsSuccess(data));
    }
  } catch (error) {
    yield put(SalesCreators.productDetailsSuccess([]));
  }
}

export function* getCustomersList() {
  try {
    const response = yield SellIntService.getCustomersList();
    if (!response) {
      throw new Error('No se pudo establecer conexión con el servidor. Intente de nuevo.');
    }
    const data = response.slice(0, 3)?.map((item, index) => {
      return {
        id: item?.client_id,
        label: `Demo ${index}`,
      };
    });
    yield put(SalesCreators.getCustomersListSuccess(data));
  } catch (error) {
    yield put(SalesCreators.getCustomersListSuccess([]));
  }
}

export function* getFrequencyCustomer({ payload: { clientId, productId } }) {
  try {
    const response = yield SellIntService.getFrequencyCustomer(clientId, productId);
    if (!response) {
      throw new Error('No se pudo establecer conexión con el servidor. Intente de nuevo.');
    }
    yield put(SalesCreators.getFrequencyCustomerSuccess(response));
  } catch (error) {
    yield put(SalesCreators.getFrequencyCustomerSuccess([]));
  }
}
export function* getProductsClients({ id }) {
  try {
    const response = yield SellIntService.getStockProductDetails(id);
    const data = response[0]?.list_products.map((objeto) => {
      const id = Object.keys(objeto)[0];
      objeto[id].image = `${HOST}${objeto[id].image}`;
      return {
        ...objeto[id],
        total_product_qty: objeto.total_product_qty,
        total_sales_qty: objeto.total_sales_qty,
        total_sales_amount: objeto.total_sales_amount,
      };
    });
    const products = data.slice(0, 3).map((item) => {
      return {
        ...item,
        name: `Demo ${item.id}`,
        brand: `Demo ${item.id}`,
      };
    });
    yield put(SalesCreators.getProductsClientsSuccess(products));
  } catch (error) { }
}

export function* getSellOut({ client, date }) {
  try {
    const { summary } = yield SellIntService.getSellout(client, date);

    const response = summary.slice(0, 3).map((item, _) => {
      return {
        ...item,
        amount: {
          growth_in_percentage: null,
          growth_in_value: null,
          total: 0,
          amount: 0,
        },
        sell_outs: item.sell_outs.slice(0, 3).map((item, _) => ({
          product_id: _,
          description: `Demo ${_}`,
          sku: `Demo ${_}`,
          brand: `Demo ${_}`,
          barcode: `Demo ${_}`,
          category: `Demo ${_}`,
          client: `Demo ${_}`,
          store: `Demo ${_}`,
          chanel: `Demo ${_}`,
          business_date: `Demo ${_}`,
          amount: 0,
          quantity: 7,
        })),
      };
    });

    yield put(
      SalesCreators.getSellOutSuccess({
        summary: response,
        totalAmount: 0,
        totalUnits: 0,
      }),
    );
  } catch (error) {
    // yield put(SalesCreators.getSellOutSuccess({}));
    console.log(error);
  }
}

export function* getSellOutClients() {
  try {
    const data = yield SellIntService.getSelloutClient();
    const response = data.slice(0, 3).map((item, _) => {
      return {
        ...item,
        client_name: `Demo ${_}`,
        amount: 0,
        quantity: 2,
      };
    });
    yield put(SalesCreators.getSellOutClientsSuccess(response));
  } catch (error) {
    console.log(error);
  }
}
