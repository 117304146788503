import React from 'react';
import { ReactComponent as Welcome } from '@assets/SVGs/welcomeIcon.svg';
import './style.scss';

const WelcomePage = () => {
  return (
    <div className="container">
      <h1>
        ¡Te damos la bienvenida a tu aliado, <span>Superfüds</span>!
      </h1>
      <div className="icon">
        <Welcome />
      </div>
      <p>
        Aumenta tus ventas y controla tu negocio con nuestro servicio de gestión empresarial. Accede
        a informes, estadísticas y análisis de ventas de manera sencilla. Toma decisiones
        estratégicas basadas en datos precisos.
        <br />
        <br />
        <p
          style={{
            color: '#000',
          }}
        >
          ¡Comienza ahora y haz crecer tu negocio!
        </p>
      </p>
    </div>
  );
};

export { WelcomePage };
