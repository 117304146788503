/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  loading: false,
  notification: [],
});
/* ------------- Reducers ------------- */
export const pushNotifications = (state = INITIAL_STATE, { data }) => {
  const filteredData = data.filter((newNotification) => {
    return !state.notification.some(
      (existingNotification) => existingNotification.id === newNotification.id,
    );
  });
  const newDataToAdd = filteredData.filter(
    (newItem) => !state.notification.some((existingItem) => existingItem.id === newItem.id),
  );

  const updatedNotification = [...state.notification, ...newDataToAdd];
  return {
    ...state,
    notification: updatedNotification,
  };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PUSH_NOTIFICATIONS]: pushNotifications,
});
