// @ts-nocheck
import React, { Suspense, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {
  SfApp,
  useKeepUiVersionInQueryStringIfPresent,
  useKeepClientIdInQueryStringIfPresent,
} from '@superfuds/sf-app';
import { Loader } from '@superfuds/saori';
import mixpanel from 'mixpanel-browser';

import {
  ToastContainer,
  Slide,
  // toast
} from 'react-toastify';
import { history } from '@redux/store';

import { Creators as StartupActions } from '@reducers/StartUp';

import { reduxPersistConfig } from '@config/reduxPersistConfig';
import { HomeContainer } from '@containers/HomeContainer/home.container';
import { ChatContainer } from '@containers/ChatContainer/chat.container';
import { FulfillmentContainer } from '@containers/FulfillmentContainer/fulfillment.container';
import { CustomerContainer } from '@containers/FulfillmentContainer/CustomersContainer/clientList';
import { FulfillmentProducts } from '@containers/FulfillmentContainer/ProductsContainer/productsFulfillment.container';
import { FulfillmentNewOrder } from '@containers/FulfillmentContainer/FormFulfillment/newOrderContainer';
import { LoginContainer } from '@containers/LoginContainer/login.container';
import { PurchaseContainer } from '@containers/PurchaseContainer/PurchaseContainer';
import { AccountingContainer } from '@containers/AccountingContainer/AccountingContainer';
import { PurchaseOrdersContainer } from '@containers/PurchaseOrdersContainer/PurchaseOrdersContainer';
import { CorrectiveinvoicesContainer } from '@containers/CorrectiveinvoicesContainer/CorrectiveinvoicesContainer';
import { SellInReportContainer } from '@containers/SellInReportContainers/sellInReport.containers';
import { ContainerProductCatalog } from '@containers/ProductCatalogContainer/ProductCatalogContainer';
import { FulfillmentReception } from '@containers/FulfillmentContainer/FormReceptionFulfillment/receptionContainer';
import { Inventory } from '@containers/InventoryContainer/InventoryContainer';
import { SellOut } from '@containers/SellOutContainer/SellOutContainer';
import {
  ProtectedRoute,
  // CustomToastBody
} from '@components/index';

import { NODE_ENVIRONMENT, TOKEN_KEY } from '@constants/index';

import { Layout } from '@containers/Layout/layout';
// import { onMessageListener } from './https:/aliado.superfuds.co/';

import { theme } from './theme';
import 'react-toastify/dist/ReactToastify.css';

import './index.scss';

const AppBase = ({ user }) => {
  useKeepUiVersionInQueryStringIfPresent(history);
  useKeepClientIdInQueryStringIfPresent(history);

  const dispatch = useDispatch();
  useEffect(() => {
    mixpanel.init('bca5a91d358b242a594d3eb5bb78499a');
    // if redux persist is not active fire startup action
    if (!reduxPersistConfig.active) {
      dispatch(StartupActions.startup());
    }
  }, [dispatch]);
  /*   useEffect(() => {
      onMessageListener()
        .then((payload) => {
          const { title, body, image } = payload?.notification || {};
          toast.success(<CustomToastBody title={title} body={body} image={image} />, {
            autoClose: 7000,
            draggable: true,
          });
        })
        .catch((err) => console.log('failed: ', err));
    }, []); */

  return (
    <SfApp
      loadingComponent={<Loader />}
      environment={NODE_ENVIRONMENT}
      user={user}
      history={history}
      enableEvents={false}
      enableDebug={false}
    >
      <Suspense fallback={<Loader />}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <ToastContainer
              transition={Slide}
              draggablePercent={60}
              autoClose={8000}
              theme="colored"
            />
            <Router history={history}>
              <Switch>
                <Route path="/login" exact component={LoginContainer} />
                <Route exact path="/">
                  {!localStorage.getItem(TOKEN_KEY) ? (
                    <Redirect to="/login" />
                  ) : (
                    <Layout>
                      <Route component={HomeContainer} />
                    </Layout>
                  )}
                </Route>
                <Layout>
                  <ProtectedRoute path="/informe-de-compras" exact component={PurchaseContainer} />
                  <ProtectedRoute
                    path="/ordenes-de-compras"
                    exact
                    component={PurchaseOrdersContainer}
                  />
                  <ProtectedRoute
                    path="/informe-de-ventas"
                    exact
                    component={SellInReportContainer}
                  />
                  <ProtectedRoute path="/sellout" exact component={SellOut} />
                  <ProtectedRoute path="/fulfillment" exact component={FulfillmentContainer} />
                  <ProtectedRoute
                    path="/fulfillment/clientes"
                    exact
                    component={CustomerContainer}
                  />
                  <ProtectedRoute
                    path="/fulfillment/inventario"
                    exact
                    component={FulfillmentProducts}
                  />
                  <ProtectedRoute
                    path="/fulfillment/nueva-orden"
                    exact
                    component={FulfillmentNewOrder}
                  />
                  <ProtectedRoute path="/ai" exact component={ChatContainer} />
                  <ProtectedRoute
                    path="/fulfillment/recepcion"
                    exact
                    component={FulfillmentReception}
                  />
                  <ProtectedRoute path="/facturas" exact component={AccountingContainer} />
                  <ProtectedRoute path="/inventario" exact component={Inventory} />
                  <ProtectedRoute
                    path="/facturas-rectificativas"
                    exact
                    component={CorrectiveinvoicesContainer}
                  />
                  <ProtectedRoute
                    path="/catalogo-productos"
                    exact
                    component={ContainerProductCatalog}
                  />
                  {/*  <Route path="/404" component={LoginContainer} />
                  <Redirect from="*" exact to="/" /> */}
                </Layout>
              </Switch>
            </Router>
          </StyledEngineProvider>
        </ThemeProvider>
      </Suspense>
    </SfApp>
  );
};

AppBase.propTypes = {
  user: PropTypes.shape({
    eid: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ user }) => ({ user });

export const App = connect(mapStateToProps)(AppBase);
