import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import { Creators as Stocks } from '@reducers/Stocks';
import { SectionStocks } from '@components/index';

import './style.scss';

const InventoryContainer = ({ products, pagination, getStocks, loading }) => {
  useEffect(() => {
    getStocks();
  }, [getStocks]);

  return (
    <Grid
      container
      item
      justifyContent="center"
      id="sf-InventoryContainer"
      className="sf-InventoryContainer"
    >
      <SectionStocks products={products} pagination={pagination} loading={loading} />
    </Grid>
  );
};

InventoryContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  getStocks: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ stock: { products, pagination, loading } }) => ({
  products,
  pagination,
  loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getStocks: () => dispatch(Stocks.getStocks()),
  };
};
export const Inventory = connect(mapStateToProps, mapDispatchToProps)(InventoryContainer);
