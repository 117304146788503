import { put } from 'redux-saga/effects';
import mixpanel from 'mixpanel-browser';
import { Creators as AuthCreators } from '@reducers/Auth';
import { Creators as User } from '@reducers/User';
import { AuthService } from '@services/auth.service';
import { TOKEN_KEY } from '@constants/index';

export function* authentication({ payload }) {
  try {
    const response = yield AuthService.login(payload);
    if (!response) {
      throw new Error('No se pudo establecer conexión con el servidor. Intente de nuevo.');
    }
    const {
      data: { access_token: AccessToken },
      status_code: status,
    } = response;

    localStorage.setItem(TOKEN_KEY, AccessToken);
    yield put(User.getUserRequest());
    mixpanel.track('Successful login', payload);
    yield put(
      AuthCreators.loginSuccess({
        status,
        AccessToken,
      }),
    );
  } catch (error) {
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      yield put(
        AuthCreators.loginFailure({
          error: 'Error de ingreso',
          status: 500,
        }),
      );
    }
    const responseError = {
      error: error?.parsedBody?.data || error?.parsedBody?.data?.message,
      status: error?.getStatus(),
    };

    mixpanel.track('Failed login', {
      ...payload,
      ...responseError,
    });
    yield put(AuthCreators.loginFailure(responseError));
  }
}

// export function* recoveryPassword() {}
