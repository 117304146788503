import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';

import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { TextField, Types } from '@constants/index';
import { ModalLayout } from '@components/index';
import { formikConf } from './customer.formik';

import './style.scss';

export const AddCustomers = ({
  loading,
  createContactFulfillment,
  places,
  open,
  handleClose,
  createCustomer,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [cities, setCities] = useState([]);
  const [value, setValue] = useState(cities[0]);
  const formRef = useRef(null);

  /*   useEffect(() => {
      if (createCustomer?.code === 201 || createCustomer?.code === 200) {
                const form = formRef.current;
                Array.from(form?.elements).forEach((element) => {
                  if (element?.type !== 'submit') {
                    handleClose();
                  }
                });
              } 
        handleClose();
      }
  
    }, [createCustomer, handleClose]);
   */
  return (
    <ModalLayout
      open={open}
      handleClose={() => {
        setCities([]);
        handleClose();
      }}
      maxWidth="md"
    >
      <Grid item xs={12} className="pb-3 pt-3">
        <Typography variant="h5" className="sf-title text-center">
          Registro Nuevo
        </Typography>
      </Grid>
      <Formik
        {...formikConf}
        onSubmit={(values) => {
          createContactFulfillment([
            {
              name: values?.name,
              name_contact: values?.name_contact,
              email: values?.email,
              company_name: values?.name,
              is_company: false,
              phone: values?.phone,
              document_type: values?.document_type || 'CC',
              document: values?.document,
              verification_digit: 0,
              country_id: values?.country_id || 49,
              state_id: values?.state_id,
              city_id: values?.city_id,
              address: values?.address,
            },
          ]);
        }}
      >
        {({ handleChange, errors, touched, setFieldValue }) => {
          return (
            <Form ref={formRef}>
              <Grid item container sx={{ mt: 0, mb: 2 }} justifyContent="center" spacing={2}>
                <Grid item container direction="row" sx={{ mt: 2, mb: 3 }} spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Nombres y Apellidos"
                      placeholder="Ingresa nombres y apellidos"
                      fullWidth
                      variant="outlined"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item container justifyContent="center" spacing={2}>
                      <Grid item xs={6}>
                        <Field key="document_type" name="document_type" variant="outlined">
                          {({ field }) => (
                            <FormControl fullWidth>
                              <InputLabel>Tipo Documento</InputLabel>
                              <Select
                                native
                                onChange={handleChange}
                                onBlur={handleChange}
                                name="document_type"
                                label="Tipo Documento"
                                style={{
                                  fontSize: '.8rem',
                                  border: 1,
                                }}
                                error={!!(touched.document_type && errors.document_type)}
                              >
                                {Types.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.label}
                                  </option>
                                ))}
                              </Select>
                              {touched.document_type && errors.document_type && (
                                <FormHelperText className="Mui-error">
                                  {errors.document_type}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Documento"
                          placeholder="Ingresa Documento"
                          fullWidth
                          variant="outlined"
                          name="document"
                          onChange={handleChange}
                          onBlur={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={!!(touched.document && errors.document)}
                          helperText={touched.document && errors.document}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sx={{ mt: 0, mb: 2.1 }}
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item xs={4}>
                    <TextField
                      label="Nombre Contacto"
                      placeholder="Ingresa nombre de contacto"
                      fullWidth
                      name="name_contact"
                      onChange={handleChange}
                      onBlur={handleChange}
                      error={!!(touched.name_contact && errors.name_contact)}
                      helperText={touched.name_contact && errors.name_contact}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Correo Electrónico"
                      placeholder="Ingresa Email"
                      fullWidth
                      name="email"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleChange}
                      error={!!(touched.email && errors.email)}
                      helperText={
                        touched.email && errors.email ? errors.email : 'example@gmail.com'
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-textarea"
                      label="Teléfono"
                      placeholder="Ingresa Teléfono"
                      fullWidth
                      name="phone"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleChange}
                      error={!!(touched.phone && errors.phone)}
                      helperText={
                        touched.phone && errors.phone
                          ? errors.phone
                          : 'Teléfono debe estar en formato internacional: +570000000000'
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sx={{ mt: 0, mb: 2.1 }}
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <Autocomplete
                      id="AutocompleteDepartament"
                      autoHighlight
                      options={places}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setCities(newValue?.cities);
                          setValue([]);
                          setInputValue('');
                        } else {
                          setCities([]);
                          setValue([]);
                          setInputValue('');
                        }
                        setFieldValue('state_id', newValue?.id);
                      }}
                      getOptionLabel={(option) => option.label ?? ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Departamentos"
                          error={!!(touched.state_id && errors.state_id)}
                        />
                      )}
                    />
                    {touched.state_id && errors.state_id && (
                      <FormHelperText className="Mui-error">{errors.state_id}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="AutocompleteCities"
                      autoHighlight
                      options={cities}
                      getOptionLabel={(option) => option.label || ''}
                      value={value || ''}
                      isOptionEqualToValue={(option, value) => option.value === value}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.label}
                          </li>
                        );
                      }}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                        setFieldValue('city_id', newValue?.city_id);
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue, reason) => {
                        if (cities.length === 0) {
                          setInputValue('');
                        } else {
                          setInputValue(newInputValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Ciudades"
                          name="city_id"
                          error={!!(touched.city_id && errors.city_id)}
                        />
                      )}
                    />
                    {touched.city_id && errors.city_id && (
                      <FormHelperText className="Mui-error">{errors.city_id}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                      id="outlined-textarea"
                      label="Dirección"
                      onChange={handleChange}
                      onBlur={handleChange}
                      error={!!(touched.address && errors.address)}
                      helperText={
                        touched.address && errors.address
                          ? errors.address
                          : 'Ejemplo:(Calle 00 # 0-0)'
                      }
                      name="address"
                      placeholder="Ingresa dirección"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container sx={{ mt: 1 }}>
                  <Grid item xs={6} className="text-left">
                    <Button
                      style={{
                        textTransform: 'initial',
                        background: 'transparent',
                        borderColor: '#25c16a',
                        color: '#25c16a',
                      }}
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        setCities([]);
                        handleClose();
                      }}
                      endIcon={<CloseIcon />}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6} className="text-right">
                    <Button
                      variant="contained"
                      className="btn btn-primary"
                      type="submit"
                      style={{
                        textTransform: 'initial',
                      }}
                      endIcon={<SendIcon />}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </ModalLayout>
  );
};

AddCustomers.propTypes = {
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  getContactsFulfillment: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  createContactFulfillment: PropTypes.func.isRequired,
  places: PropTypes.shape({}).isRequired,
  createCustomer: PropTypes.shape({}).isRequired,
};
