import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { Dropdown } from '@superfuds/saori';
import { Chart } from '@superfuds/sf-charts';

import { CustomTable, Spinner } from '@components/index';

const DialogDetailsUsers = ({ onClose, open, data }) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle className="text-center">{data?.title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <List sx={{ pt: 0, fontSize: '.8rem' }}>
        {data?.list &&
          data?.list?.map((item) => (
            <ListItem key={item?.name}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'primary' }}>
                  <PersonIcon sx={{ pr: 0 }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item?.name} />
            </ListItem>
          ))}
      </List>
    </Dialog>
  );
};

export const CustomerSummary = ({
  monthByMonthDropdown,
  setFilterCustomer,
  channel,
  filterCustomer,
  getCustomerActivity,
  loadingCustomer,
  summaryCustomer,
  optionsChartCustomer,
  customerCurrent,
  isNegativeNumber,
}) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  return (
    <Grid
      container
      direction="row"
      sx={{ mt: 7 }}
      justifyContent="center"
      alignItems="center"
      className="containerCustomer"
    >
      <DialogDetailsUsers open={open} onClose={() => setOpen(!open)} data={data ?? []} />
      <Grid item xs={12}>
        <h2 className="sf-title-secondary">Resumen de clientes</h2>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="ContainerClient"
      >
        <Grid className="card text-center" container>
          <Grid item xs={12} sx={{ mt: 2, mb: 4, zIndex: 3 }} className="filter text-right">
            <Dropdown
              classNamePrefix="dropdown"
              className="container-filter"
              options={channel}
              onChange={(item) => {
                const filter = {
                  ...filterCustomer,
                  chanel: item.value,
                };
                setFilterCustomer(filter);
                getCustomerActivity(filter);
              }}
              placeholder="Canal de Ventas"
              value={channel.filter((option) => option.value === filterCustomer?.chanel)}
            />
            <Dropdown
              classNamePrefix="dropdown"
              className="container-filter"
              options={monthByMonthDropdown}
              onChange={(item) => {
                const filter = {
                  ...filterCustomer,
                  months: item.value,
                };
                setFilterCustomer(filter);
                getCustomerActivity(filter);
              }}
              defaultValue={monthByMonthDropdown[0]}
              value={monthByMonthDropdown.filter(
                (option) => option.value === filterCustomer?.months,
              )}
            />
          </Grid>
          {loadingCustomer ? (
            <Grid
              item
              container
              xs={12}
              sx={{ mt: 2, mb: 4, zIndex: 3 }}
              justifyContent="center"
              className="filter text-center"
            >
              <Spinner />
            </Grid>
          ) : (
            <>
              <Grid item xs={7}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: 2,
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Crecimiento de clientes
                  </Typography>
                </Grid>

                {summaryCustomer && summaryCustomer?.length > 0 ? (
                  <Chart data={summaryCustomer} options={optionsChartCustomer(summaryCustomer)} />
                ) : (
                  <Grid item xs={12} className="card_height container-message">
                    <h3 className="sf-title-secondary">No hay Resultados</h3>
                  </Grid>
                )}
              </Grid>
              <Grid container direction="row" item xs={5}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: 2,
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Tus clientes durante este mes...
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    borderBottom: ' 0.25px solid rgba(46, 46, 46, 0.7)',
                  }}
                >
                  <Typography variant="caption" display="block" gutterBottom>
                    Clientes nuevos
                  </Typography>
                  <Typography
                    variant="h2"
                    style={{
                      textAlign: 'center',
                    }}
                    display="block"
                    gutterBottom
                    className="title-Active"
                  >
                    <span>+</span> {customerCurrent?.newCustomers}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    borderRight: '0.12px solid rgba(46, 46, 46, 0.7)',
                  }}
                  sx={{
                    padding: 2,
                  }}
                >
                  <Typography variant="caption" display="block" gutterBottom>
                    Clientes Activos
                  </Typography>
                  <Typography variant="h5" display="block" gutterBottom className="customerActive">
                    {customerCurrent?.activeCustomers}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    padding: 2,
                  }}
                >
                  <Typography variant="caption" display="block" gutterBottom>
                    Clientes Perdidos
                  </Typography>
                  <Typography variant="h5" display="block" gutterBottom className="customerLost">
                    {customerCurrent?.lostCustomers}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                className="card text-center"
                xs={12}
                sx={{
                  marginTop: 7,
                }}
              >
                {summaryCustomer && summaryCustomer?.length > 0 ? (
                  <CustomTable
                    titles={['Mes', 'Clientes Activos', 'Clientes Perdidos', 'Clientes Nuevos']}
                    data={summaryCustomer}
                    active={false}
                  >
                    <TableBody>
                      {summaryCustomer?.map((item) => (
                        <TableRow key={item.monthDate}>
                          <TableCell align="center">
                            {item.monthName}
                            <Typography variant="caption" display="block" gutterBottom>
                              {item.yearName}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {item.activeCustomers}
                            {isNegativeNumber(item.growthActiveCustomers)}
                          </TableCell>
                          <TableCell align="center">
                            <div
                              style={{
                                display: 'inline-block',
                                marginRight: '15px',
                              }}
                            >
                              {item.lostCustomers}
                              {isNegativeNumber(item.growthNewCustomers)}
                            </div>
                            {item?.lostCustomersList?.length > 0 && (
                              <IconButton
                                title="Detalles de Usuarios Perdidos"
                                className="button-icon-table action-click-payment"
                                aria-label="expand row"
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpen(true);
                                  setData({
                                    title: 'Listado de Usuarios Perdidos',
                                    list: item?.lostCustomersList,
                                  });
                                }}
                              >
                                <RemoveRedEyeIcon />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <div
                              style={{
                                display: 'inline-block',
                                marginRight: '15px',
                              }}
                            >
                              {item.newCustomers}
                            </div>
                            {item?.newCustomersList?.length > 0 && (
                              <IconButton
                                title="Detalles de Usuarios Nuevos"
                                className="button-icon-table action-click-payment"
                                aria-label="expand row"
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpen(true);
                                  setData({
                                    title: 'Listado de Usuarios Nuevos',
                                    list: item?.newCustomersList,
                                  });
                                }}
                              >
                                <RemoveRedEyeIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </CustomTable>
                ) : (
                  <Grid item xs={12} className="card_height container-message">
                    <h3 className="sf-title-secondary">No hay Resultados</h3>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
CustomerSummary.propTypes = {
  monthByMonthDropdown: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterProduct: PropTypes.shape({}).isRequired,
  setFilterCustomer: PropTypes.shape({}).isRequired,
  channel: PropTypes.shape({}).isRequired,
  filterCustomer: PropTypes.shape({}).isRequired,
  getCustomerActivity: PropTypes.shape({}).isRequired,
  loadingCustomer: PropTypes.bool.isRequired,
  summaryCustomer: PropTypes.shape({}).isRequired,
  optionsChartCustomer: PropTypes.shape({}).isRequired,
  customerCurrent: PropTypes.shape({}).isRequired,
  isNegativeNumber: PropTypes.func.isRequired,
};
