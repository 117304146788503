import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like getUserRequest -> USER_REQUEST
 */
export const { Types, Creators } = createActions({
  getUserRequest: null,
  getUserSuccess: ['user'],
  logoutUser: null,
  reset: null,
});
