const { version } = require('../../package.json');

export const DEMO = 'isActive';
// export const ODOO_HOST = 'https://superfuds-staging.odoo.com';
export const ODOO_HOST = 'https://www.gaia.superfuds.com';
export const ODOO_HOST_DEMO = 'https://superfuds-odoo-demo-8248311.dev.odoo.com';
export const DEMO_USER = 'demo@demo.com';
/*   NODE_ENVIRONMENT === 'production'
    ? 'https://www.gaia.superfuds.com'
    : 'https://superfuds-staging.odoo.com'; */

export const REDUCERVERSION = version;
export const HOST =
  localStorage.getItem('ODOO_HOST') === ODOO_HOST_DEMO ? ODOO_HOST_DEMO : ODOO_HOST;
