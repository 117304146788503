export const transformServiceLevel = (response) => ({
  serviceLevel: response.service_level,
  serviceLevelByUnits: response.service_level_by_units,
  orders: [
    response.orders.map((item) => {
      return {
        ...item,
        name: `demo ${item.id}`,
      };
    }),
  ],
});
