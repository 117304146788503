import { useMemo } from 'react';
import { history } from '@utils';

export function useQueryParam(queryParam, defaultValue) {
  const { location } = history;

  const value = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params[queryParam];
  }, [location.search, queryParam]);

  if (value === undefined && defaultValue !== undefined) {
    return defaultValue;
  }

  return value;
}
