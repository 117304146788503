import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
// Create a theme instance.
const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#fff',
        light: '#6BA973',
        dark: '#369D65',
        contrastText: '#fff',
      },
      secondary: {
        main: '#FFCB47',
      },
      error: {
        main: '#F65858',
      },
      background: {
        default: '#fff',
      },
    },
    typography: {
      fontFamily: "'Silka'",
    },
  },
  esES,
);

export { theme };
