/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import { createStore } from '@redux/store';
import { App } from './App';
// import * as serviceWorker from './serviceWorker';

const { version } = require('../package.json');

export const store = createStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

const clearCache = () => {
  localStorage.clear();
};

window.addEventListener('load', () => {
  caches.keys().then((cacheNames) => {
    cacheNames.forEach((cacheName) => {
      caches.delete(cacheName);
    });
  });

  if (localStorage.getItem('reducerVersion') !== version) {
    window.location.reload(true);
    clearCache();
  }
});

localStorage.setItem('reducerVersion', version);
