import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name_contact: Yup.string().required('Nombre de contacto requerido'),
  state_id: Yup.string().required('Departamento es requerido'),
  city_id: Yup.string().required('Ciudad es requerido'),
  address: Yup.string().required('Dirección es requerido'),
});

export const formikConf = {
  validateOnChange: true,
  validateOnBlur: true,

  validationSchema,
};
