import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TableCell,
  TableBody,
  TableRow,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Alert,
  IconButton,
  Typography,
  DialogTitle,
  Switch,
  FormControl,
  TextField,
  Stack,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';

import { connect } from 'react-redux';
import ReactExport from 'react-export-excel';
import { Formik, Form } from 'formik';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import * as Yup from 'yup';
import { Creators as Stocks } from '@reducers/Stocks';
import { CustomTable, Spinner } from '@components/index';
import { TextField as Field } from '@constants/index';

import './style.scss';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const titles = [
  'SKU',
  'Código de Barra',
  'Nombre',
  'Imágen',
  'Descripción',
  'Marca',
  'Contenido Neto',
  'Estado',
  'Acciones',
];

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ProductCatalog = ({
  changePage,
  changeRowsPerPage,
  getStocks,
  products,
  pagination,
  updateStatusProduct,
  statusProduct,
  loading,
}) => {
  const [open, setIsOpen] = useState(false);
  const [product, setIsProduct] = useState({});
  const [checked, setIschecked] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { page, rowsPerPage } = pagination;

  useEffect(() => {
    getStocks();
  }, [getStocks, statusProduct]);

  const handleChangeStatus = (event) => {
    setIschecked(event.target.checked);
  };

  const validationSchema = Yup.object().shape({
    reason: Yup.string().required('La razón es requerido'),
  });

  const formikConf = {
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      reason: '',
    },
    validationSchema,
  };

  const filteredData = products?.filter((row) => {
    const rowValues = Object.values(row).join(' ').toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    return rowValues.includes(searchTermLower);
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value?.trim());
  };

  return (
    <Grid
      container
      item
      justifyContent="center"
      id="sf-productCatalogo"
      className="sf-productCatalogo"
    >
      <Dialog
        open={open}
        keepMounted
        onClose={() => {
          setIsOpen(!open);
        }}
        maxWidth="xs"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
        id="sf-productCatalogo"
        className="sf-productCatalogo"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6">
            Editar
            <Typography component="div" color="succes">
              {product?.name}
            </Typography>
          </Typography>
          {product?.imagen && (
            <img
              width={100}
              height={100}
              aria-hidden
              className="cursor-pointer img-modal"
              src={product?.imagen}
              alt={`Imagen N°${product?.id} del producto`}
            />
          )}
        </DialogTitle>

        <Formik
          {...formikConf}
          onSubmit={(values) => {
            updateStatusProduct({
              ...values,
              product_id: product.id,
            });
            setIsOpen(!open);
          }}
        >
          {({ values, errors, handleChange }) => {
            return (
              <Form className="sf-login__loginForm">
                <DialogContent>
                  <Box component="form" noValidate autoComplete="off">
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Grid item xs={12}>
                        <FormControl>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Apagado</Typography>
                            <IOSSwitch
                              sx={{ m: 3 }}
                              checked={checked}
                              onChange={handleChangeStatus}
                              name="status"
                            />
                            <Typography>Encendido</Typography>
                          </Stack>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className="multiline">
                          <TextField
                            label="Razón del estado"
                            name="reason"
                            multiline
                            rows={2}
                            defaultValue=""
                            value={values.reason}
                            onChange={handleChange}
                            error={errors.reason !== undefined}
                            helperText={errors.reason}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>

                <DialogActions>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      setIsOpen(false);
                      setIsProduct({});
                      setIschecked(null);
                    }}
                  >
                    Cerrar
                  </Button>
                  <Button
                    variant="contained"
                    className="btn btn-primary"
                    type="submit"
                    disabled={!values.reason}
                  >
                    Confirmar
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>

      <Grid item xs={12} className="pb-1 pt-3">
        <h2 className="sf-title text-left">Gestiona Productos</h2>
      </Grid>
      {filteredData && filteredData.length > 0 && (
        <Grid item xs={12} sx={{ mt: 0, mb: 2 }} className="text-right">
          <ExcelFile
            filename="Tu_productos_en_superfuds"
            element={
              <Button
                variant="contained"
                className="btn btn-primary"
                endIcon={<FileDownloadIcon />}
              >
                Descargar
              </Button>
            }
          >
            <ExcelSheet data={filteredData} name="Productos">
              <ExcelColumn label="SKU" value="product_sku" />
              <ExcelColumn label="Código de Barra" value="product_barcode" />
              <ExcelColumn label="Nombre" value="name" />
              <ExcelColumn label="Imágen" value="imagen" />
              <ExcelColumn label="Descripción" value="sf_ecom_description" />
              <ExcelColumn label="Marca" value="product_brand" />
              <ExcelColumn label="Contenido Neto" value="net_content" />
              <ExcelColumn
                label="Estado"
                value={(col) => (col.state_purchase ? 'Activo' : 'Inactivo')}
              />
            </ExcelSheet>
          </ExcelFile>
        </Grid>
      )}
      {loading ? (
        <Grid
          container
          className="card"
          justifyContent="center"
          alignItems="center"
          style={{
            padding: '20px 10px',
          }}
        >
          <Spinner />
        </Grid>
      ) : (
        <Grid container className="card">
          <Grid
            item
            xs={4}
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 1, mb: 0, zIndex: 3, paddingLeft: 2, paddingRight: 2 }}
            className="text-left"
          >
            <Grid item xs={searchTerm !== '' ? 10 : 12}>
              <Field
                size="medium"
                fullWidth
                label="Buscar"
                value={searchTerm}
                onChange={handleSearchChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            {searchTerm !== '' && (
              <Grid item xs={2}>
                <IconButton
                  title="Detalles de la Factura"
                  className="button-icon-table action-click-payment"
                  aria-label="expand row"
                  size="small"
                  style={{
                    marginLeft: '20px',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSearchTerm('');
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <CustomTable
            titles={titles}
            data={filteredData}
            pagination={pagination}
            active
            onPageChange={(event, page) => changePage(page)}
            onRowsPerPageChange={(event) => changeRowsPerPage(event.target.value)}
          >
            <TableBody>
              {filteredData &&
                filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell align="center">{item.product_sku}</TableCell>
                      <TableCell align="center">{item.product_barcode}</TableCell>
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center">
                        {item.imagen && (
                          <img
                            width={100}
                            height={100}
                            aria-hidden
                            className="cursor-pointer"
                            src={item.imagen}
                            alt={`Imagen N°${item.id} del producto`}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="caption">{item.sf_ecom_description}</Typography>
                      </TableCell>
                      <TableCell align="center">{item.product_brand}</TableCell>
                      <TableCell align="center">{item.net_content}</TableCell>
                      <TableCell align="center">
                        {item.state_purchase ? (
                          <Alert severity="success">Activo</Alert>
                        ) : (
                          <Alert severity="error">Inactivo</Alert>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          title="Pago adelantado de Factura"
                          className="button-icon-table action-click-payment"
                          aria-label="expand row"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsProduct(item);
                            setIschecked(item.state_purchase);
                            setIsOpen(true);
                          }}
                        >
                          <BorderColorOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </CustomTable>
        </Grid>
      )}
    </Grid>
  );
};

ProductCatalog.propTypes = {
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  }).isRequired,
  changeRowsPerPage: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  getStocks: PropTypes.func.isRequired,
  updateStatusProduct: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  statusProduct: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ stock: { products, pagination, statusProduct, loading } }) => ({
  products,
  pagination,
  statusProduct,
  loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getStocks: () => dispatch(Stocks.getStocks()),
    updateStatusProduct: (data) => dispatch(Stocks.putUpdateStatusProduct(data)),
    changeRowsPerPage: (item) => dispatch(Stocks.changeRowsPerPage(item)),
    changePage: (item) => dispatch(Stocks.changePage(item)),
  };
};
export const ContainerProductCatalog = connect(mapStateToProps, mapDispatchToProps)(ProductCatalog);
