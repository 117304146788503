import moment from 'moment';
import 'moment/locale/es';

import { capitalize } from '@utils';

export const transformServiceTopCustomer = (response) => ({
  countCustomers: response?.count_customers,
  topCustomers: response?.top_customers?.map((rows) => ({
    id: rows.customer_id,
    location: 'Demo location',
    name: `Demo `,
    totalAmount: rows.total_amount,
  })),
});

export const transformServiceCustomerActivity = (response) =>
  response?.map((item) => ({
    activeCustomers: item?.active_customers,
    growthActiveCustomers: item?.growth_active_customers,
    growthNewCustomers: item?.growth_new_customers,
    lostCustomers: item?.lost_customers,
    monthDate: item?.month_date,
    lostCustomersList: item?.lost_customers_list,
    newCustomersList: item?.new_customers_list,
    monthName: capitalize(moment(item?.month_date, 'YYYY-MM').locale('es').format('MMMM')),
    yearName: capitalize(moment(item?.month_date, 'YYYY-MM').format('YYYY')),
    newCustomers: item?.new_customers,
    totalCustomers: item?.total_customers,
  }));
