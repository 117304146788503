// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TableBody, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';

import { Creators as Stocks } from '@reducers/Stocks';
import { CustomTable, Download, Spinner, renderRow } from '@components/index';
import { TextField } from '@constants/index';

import './style.scss';

const titles = [
  'Sku',
  'Código de Barra',
  'Producto',
  'Imagen',
  'Lote',
  'Disponibles',
  'Fecha de Vencimiento',
  '% Vida Útil',
  'Días de Inventario',
  'Días hasta Vencimiento',
  'Total Disponibles',
  'Total Reservado',
];

export const SectionStocks = ({ products, pagination, loading }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = products?.filter((row) => {
    const rowValues = Object.values(row).join(' ').toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();

    const lotsIncludeSearchTerm = row.lots.some((lot) => {
      const lotValues = Object.values(lot).join(' ').toLowerCase();
      return lotValues.includes(searchTermLower);
    });

    return rowValues.includes(searchTermLower) || lotsIncludeSearchTerm;
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value?.trim());
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12} sx={{ mt: 5, mb: 5 }}>
        <h2 className="sf-title-secondary">Tu inventario en Superfüds</h2>
      </Grid>
      {loading ? (
        <Grid
          container
          className="card"
          justifyContent="center"
          alignItems="center"
          style={{
            padding: '20px 10px',
          }}
        >
          <Spinner />
        </Grid>
      ) : (
        <>
          <Grid item container fullWidth className="card">
            <Grid
              item
              xs={4}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 1, mb: 0, zIndex: 3, paddingLeft: 2, paddingRight: 2 }}
              className="text-left"
            >
              <Grid item xs={searchTerm !== '' ? 10 : 12}>
                <TextField
                  size="medium"
                  fullWidth
                  label="Buscar"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              {searchTerm !== '' && (
                <Grid item xs={2}>
                  <IconButton
                    title="Detalles de la Factura"
                    className="button-icon-table action-click-payment"
                    aria-label="expand row"
                    size="small"
                    style={{
                      marginLeft: '20px',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSearchTerm('');
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid item xs={8} sx={{ mt: 5 }} className="text-right">
              {filteredData && filteredData.length > 0 && <Download data={filteredData} />}
            </Grid>
            {filteredData && filteredData.length > 0 ? (
              <Grid container className="card">
                <CustomTable
                  titles={titles}
                  data={filteredData}
                  pagination={pagination}
                  active
                  onPageChange={(event, page) => dispatch(Stocks.changePage(page))}
                  onRowsPerPageChange={(event) => {
                    dispatch(Stocks.changeRowsPerPage(event.target.value));
                  }}
                >
                  <TableBody>{renderRow(filteredData, pagination, titles.length)}</TableBody>
                </CustomTable>
              </Grid>
            ) : (
              <Grid item xs={12} className="card_height container-message">
                <h3 className="sf-title-secondary">No hay Resultados</h3>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

SectionStocks.defaultProps = {
  pagination: {},
  loading: false,
};

SectionStocks.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({}),
  loading: PropTypes.bool,
};
