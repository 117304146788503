import { toast } from 'react-toastify';

export function showSuccessToast() {
  return toast.loading('Cargando... por favor espere', {
    className: 'Toastify__toast-theme--colored',
  });
}

export function updateSuccessToast({ toastId, messange = 'Registro exitoso', type = 'success' }) {
  toast.update(toastId, {
    render: messange,
    theme: 'colored',
    type: type === 'success' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
    className: 'Toastify__toast--success',
    isLoading: false,
    draggable: true,
    autoClose: 3000,
  });
}

export function successToast() {
  toast.success('Registro exitoso');
}
