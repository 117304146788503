/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { TOKEN_KEY } from '@constants/index';
import { Types } from './actionsTypes';
/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  loading: false,
  status: 0,
  accessToken: '' || localStorage.getItem(TOKEN_KEY),
  error: {
    menssage: {},
  },
});
/* ------------- Reducers ------------- */
export const loginRequest = (state = INITIAL_STATE) => {
  return state.merge({ loading: true });
};
export const loginSuccess = (state = INITIAL_STATE, { data }) => {
  return state.merge({
    loading: false,
    status: data.status,
    accessToken: data.AccessToken,
  });
};
export const loginFailure = (state = INITIAL_STATE, { error }) => {
  return state.merge({
    loading: false,
    error,
    status: error.status,
  });
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGIN_SUCCESS]: loginSuccess,
});
