import { formatCurrency, capitalize } from '@utils';

export const transformServiceAov = (response) => ({
  summaryAov: response.summary?.slice(0, 3).map((rows) => ({
    date: capitalize(rows.date),
    aov: formatCurrency(rows.aov),
    growthInPercentage: `${parseInt(rows?.growth_in_percentage * 100, 10)}%` || 0,
    percentage: parseInt(rows?.growth_in_percentage * 100, 10),
    growthInValue: rows.growth_in_value || 0,
    total: rows.aov,
  })),
  aov: response.aov,
});
