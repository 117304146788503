import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  /*  CircularProgress, */
  Button,
  Alert,
} from '@mui/material';

import { connect } from 'react-redux';
import ReactExport from 'react-export-excel';
import { useHistory } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { TextField } from '@constants/index';

// import PaidIcon from '@mui/icons-material/Paid';
import { CustomTable, Spinner, DetallisInvoces } from '@components/index';
import { Creators as Account } from '@reducers/Account';
import { formatCurrency } from '@utils';

import './style.scss';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const titles = [
  'Número Factura',
  'Referencia de Pago',
  'Fecha de Vencimiento',
  'Motivo ',
  'Total',
  'Estado',
  'Acciones',
];

const Correctiveinvoices = ({
  corrective,
  changePage,
  changeRowsPerPage,
  getBillsCorrective,
  pagination,
  loadingCorrective,
  billsLines,
  getAccountLines,
  loadingLines,
  user,
  account,
}) => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const { page, rowsPerPage } = pagination;

  // const [isLoading, setIsLoading] = useState(0);
  const [layout, setLayout] = useState({
    open: false,
  });
  useEffect(() => {
    getBillsCorrective();
  }, [getBillsCorrective]);

  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const handleClose = () =>
    setLayout({
      open: false,
    });
  const filteredData = corrective?.filter((row) => {
    const rowValues = Object.values(row).join(' ').toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    return rowValues.includes(searchTermLower);
  });

  const dataFilter =
    params.get('id') !== null
      ? corrective.filter((row) => row.name === params.get('id'))
      : filteredData;
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value?.trim());
  };

  return (
    <Grid
      container
      item
      justifyContent="center"
      id="sf-Correctiveinvoices"
      className="sf-Correctiveinvoices"
    >
      <DetallisInvoces handleClose={handleClose} layout={layout} account={account} />
      <Grid item xs={12} className="pb-1 pt-4">
        <h2 className="sf-title"> Notas Créditos</h2>
      </Grid>
      {corrective && corrective.length > 0 && (
        <Grid item xs={12} sx={{ mt: 1, mb: 2 }} className="text-right">
          <ExcelFile
            filename="Tu_facturas_Correctivas_superfuds"
            element={
              <Button
                variant="contained"
                className="btn btn-primary"
                endIcon={<FileDownloadIcon />}
              >
                Descargar
              </Button>
            }
          >
            <ExcelSheet data={corrective} name="Facturas Correctivas">
              <ExcelColumn label="Número Factura" value="name" />
              <ExcelColumn label="Referencia de Pago" value="payment_ref" />
              <ExcelColumn label="Fecha" value="invoice_date" />
              <ExcelColumn label="Fecha de Maxima de pago" value="invoice_payment" />
              <ExcelColumn label="Referencia" value="ref" />
              <ExcelColumn label="Total" value="amount_total" />
              <ExcelColumn label="Estado" value="payment_status" />
            </ExcelSheet>
          </ExcelFile>
        </Grid>
      )}

      {loadingCorrective ? (
        <Grid
          container
          className="card"
          justifyContent="center"
          alignItems="center"
          style={{
            padding: '20px 10px',
          }}
        >
          <Spinner />
        </Grid>
      ) : (
        <>
          <Grid container className="card">
            {params.get('id') !== null && (
              <Grid
                item
                xs={12}
                style={{
                  padding: '20px 10px',
                }}
              >
                <Button
                  variant="contained"
                  className="btn btn-primary"
                  style={{
                    textTransform: 'initial',
                  }}
                  onClick={() => history.push(`/facturas-rectificativas`)}
                >
                  Borrar Filtro
                </Button>
              </Grid>
            )}
            {corrective && corrective.length > 0 && (
              <Grid
                item
                xs={4}
                container
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 1, mb: 0, zIndex: 3, paddingLeft: 2, paddingRight: 2 }}
                className="text-left"
              >
                <Grid item xs={searchTerm !== '' ? 10 : 12}>
                  <TextField
                    size="medium"
                    fullWidth
                    label="Buscar Número de factura"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                {searchTerm !== '' && (
                  <Grid item xs={2}>
                    <IconButton
                      title="Detalles de la Factura"
                      className="button-icon-table action-click-payment"
                      aria-label="expand row"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSearchTerm('');
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            )}
            {dataFilter && dataFilter.length > 0 && (
              <CustomTable
                titles={titles}
                data={dataFilter}
                pagination={pagination}
                active
                onPageChange={(event, page) => changePage(page)}
                onRowsPerPageChange={(event) => changeRowsPerPage(event.target.value)}
              >
                <TableBody>
                  {dataFilter &&
                    dataFilter
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                      .map((item) => (
                        <TableRow key={item.id}>
                          <TableCell align="center">{item.name}</TableCell>
                          <TableCell align="center">{item.payment_ref}</TableCell>
                          <TableCell align="center">
                            {item.expiration_date > 0
                              ? `En ${item.expiration_date + 1} días`
                              : (item.payment_status === 'not_paid' ||
                                item.payment_status === 'in_payment' ||
                                item.payment_status === 'partial') && (
                                <span
                                  style={{
                                    color: 'red',
                                  }}
                                >{`Hace ${item.expiration_date} días`}</span>
                              )}
                          </TableCell>
                          <TableCell align="center">{item.ref}</TableCell>
                          <TableCell align="center">{formatCurrency(item.amount_total)}</TableCell>
                          <TableCell align="center">
                            {item.payment_status === 'paid' && (
                              <Alert severity="success">Pagado</Alert>
                            )}
                            {item.payment_status === 'in_payment' && (
                              <Alert severity="info">En proceso de pago</Alert>
                            )}
                            {item.payment_status === 'not_paid' && (
                              <Alert severity="warning">No pagadas</Alert>
                            )}
                            {item.payment_status === 'partial' && (
                              <Alert severity="info">Pagado Parcialmente</Alert>
                            )}
                            {item.payment_status === 'reversed' && (
                              <Alert severity="error">Revertido</Alert>
                            )}
                            {item.payment_status === 'invoicing_legacy' && (
                              <Alert severity="error">Factura Sistema Anterior</Alert>
                            )}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              title="Detalles de la Factura"
                              className="button-icon-table action-click-payment"
                              aria-label="expand row"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (!loadingLines) {
                                  setLayout({
                                    open: true,
                                    user,
                                    ...item,
                                  });
                                }
                                getAccountLines(item.id);
                              }}
                            >
                              <RemoveRedEyeIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </CustomTable>
            )}
            {corrective && corrective.length === 0 && (
              <Grid item xs={12} className="pb-1">
                <h5 className="text-center">En este momento no cuentas facturas rectificativas </h5>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

Correctiveinvoices.propTypes = {
  user: PropTypes.shape({}).isRequired,
  account: PropTypes.shape({}).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  }).isRequired,
  changeRowsPerPage: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  getBillsCorrective: PropTypes.func.isRequired,
  corrective: PropTypes.shape({}).isRequired,
  loadingCorrective: PropTypes.bool.isRequired,
  getAccountLines: PropTypes.func.isRequired,
  loadingLines: PropTypes.bool.isRequired,
  billsLines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({
  account: { corrective, pagination, loadingCorrective, billsLines, loadingLines },
  account,
  user,
}) => ({
  corrective,
  pagination,
  loadingCorrective,
  user,
  billsLines,
  loadingLines,
  account,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBillsCorrective: () => dispatch(Account.getAccountBillsCorrective()),
    changeRowsPerPage: (item) => dispatch(Account.changeRowsPerPage(item)),
    changePage: (item) => dispatch(Account.changePage(item)),
    getAccountLines: (id) => dispatch(Account.getAccountLines(id)),
  };
};
export const CorrectiveinvoicesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Correctiveinvoices);
