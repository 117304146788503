import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './style.scss';

const dataWidth = ['xs', 'sm', 'md', 'lg', 'xl'];
export const ModalLayout = ({ open, handleClose, children, maxWidth, fullWidth }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    className="modal"
  >
    <DialogTitle sx={{ m: 0, p: 1 }}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          zIndex: 4,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
  </Dialog>
);

ModalLayout.defaultProps = {
  open: false,
  handleClose: () => { },
  fullWidth: true,
  maxWidth: dataWidth.includes('lg'),
};
ModalLayout.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
