import moment from 'moment';

const formatDate = (date) => {
  if (!date) return '';
  let month = '';

  const year = moment(date).format('YYYY');
  if (date.search('enero') === 0) {
    month = `january ${year}`;
  } else if (date.search('febrero') === 0) {
    month = `february ${year}`;
  } else if (date.search('marzo') === 0) {
    month = `march ${year}`;
  } else if (date.search('abril') === 0) {
    month = `april ${year}`;
  } else if (date.search('mayo') === 0) {
    month = `may ${year}`;
  } else if (date.search('junio') === 0) {
    month = `june ${year}`;
  } else if (date.search('julio') === 0) {
    month = `july ${year}`;
  } else if (date.search('agosto') === 0) {
    month = `august ${year}`;
  } else if (date.search('septiembre') === 0) {
    month = `september ${year}`;
  } else if (date.search('octubre') === 0) {
    month = `october ${year}`;
  } else if (date.search('noviembre') === 0) {
    month = `november ${year}`;
  } else if (date.search('diciembre') === 0) {
    month = `december ${year}`;
  }

  return moment(month).format('YYYY-MM');
};

export { formatDate };
