import { capitalize } from '@utils';

export const transformServiceFrequency = (response) => ({
  summaryFqy: response?.frequency?.slice(0, 3).map((rows) => ({
    date: capitalize(rows.date),
    aov: response.aov,
    growthInPercentage: rows?.growth_in_percentage,
    growthInValue: rows?.growth_in_value,
    totalUnitsRequested: rows?.total_units_requested,
  })),
  frequency: response.aov,
});
