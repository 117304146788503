import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import * as XLSX from 'exceljs';
import {
  Button,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import { TextField } from '@constants/index';
import { Spinner, CustomTable } from '@components/index';
import { usePlaces } from '@hooks/use-places';

import { Creators as Stocks } from '@reducers/Stocks';
import { AddCustomers } from './AddCustomer';
import { AddCustomerDelivery } from './AddCustomerDelivery';

import './style.scss';
import { AddCustomerImport } from './AddCustomerImport';

const titles = [
  'ID Cliente',
  'Nombres y Apellidos',
  'Tipo Documento',
  'Documento',
  'Móvil',
  'Correo',
  'Dirección',
  'Acciones',
];
const generateExcel = (places) => {
  const workbook = new XLSX.Workbook();
  const worksheetClientes = workbook.addWorksheet('Clientes');
  worksheetClientes.addRow([
    'Nombres y Apellidos',
    'Tipo Documento',
    'Documento',
    'Nombre de Contacto',
    'Correo',
    'Teléfono',
    'stateId',
    'cityId',
    'Dirección',
  ]);
  worksheetClientes.addRow(['', '', '', '', '', '', '', '', '', '']);
  const worksheetDepartamentosCiudades = workbook.addWorksheet('Departamentos y Ciudades');
  worksheetDepartamentosCiudades.addRow(['stateId', 'Departamento', 'cityId', 'Ciudad']);
  const flatDepartamentosYCiudades = places.flatMap((place) =>
    place.cities.map((ciudad) => [place.id, place.label, ciudad.city_id, ciudad.city_name]),
  );

  worksheetDepartamentosCiudades.addRows(flatDepartamentosYCiudades);
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Clientes.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  });
};

const ContainerCustomers = ({
  loading,
  clients,
  getContactsFulfillment,
  createDeliveryContact,
  createContactFulfillment,
  delivery,
  createCustomer,
  pagination,
  changePage,
  changeRowsPerPage,
  updateContact,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [editCustomer, setEditCustomer] = useState({});

  const { places } = usePlaces();
  const history = useHistory();
  const { page, rowsPerPage } = pagination;

  useEffect(() => {
    getContactsFulfillment();
  }, [getContactsFulfillment]);

  const filteredData = clients?.filter((row) => {
    const rowValues = Object.values(row).join(' ').toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    return rowValues.includes(searchTermLower);
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value?.trim());
  };

  return (
    <Grid
      container
      item
      justifyContent="center"
      id="sf-fulfillment-customer"
      className="sf-fulfillment-customer"
    >
      <AddCustomers
        loading={createCustomer?.loading}
        createContactFulfillment={(values) => {
          createContactFulfillment(values);
          setOpen(!open);
        }}
        places={places}
        open={open}
        createCustomer={createCustomer}
        handleClose={() => setOpen(!open)}
      />
      <AddCustomerImport
        open={openImport}
        handleClose={() => {
          setOpenImport(false);
        }}
        send={(values) => {
          createContactFulfillment(values);
          setOpenImport(false);
        }}
      />
      <AddCustomerDelivery
        delivery={delivery}
        updateContact={(values) => updateContact(values)}
        loading={delivery?.loading}
        addDelivery={(values) => {
          createDeliveryContact(values);
        }}
        customer={editCustomer}
        places={places}
        open={editCustomer?.open}
        handleClose={() =>
          setEditCustomer({
            open: false,
          })
        }
      />

      <Grid item xs={12} className="pb-3 pt-3">
        <Typography variant="h2" className="sf-title">
          Clientes{' '}
        </Typography>
      </Grid>
      <Grid item container sx={{ mt: 0, mb: 2 }}>
        <Grid item xs={3}>
          <IconButton
            title="Volver atras"
            className="button-icon-table action-click-payment"
            aria-label="expand row"
            size="small"
            onClick={() => history.push('/fulfillment')}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        </Grid>
        <Grid item className="text-right" xs={12}>
          {filteredData && filteredData.length > 0 && (
            <ExcelFile
              filename="Clientes Fulfillment"
              element={
                <Button
                  variant="contained"
                  className="btn btn-primary"
                  endIcon={<FileDownloadIcon />}
                >
                  Descargar
                </Button>
              }
            >
              <ExcelSheet data={filteredData} name="Clientes Fulfillment">
                <ExcelColumn label="ID Cliente" value="id" />
                <ExcelColumn label="Nombre" value="name" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Movil" value="phone" />
                <ExcelColumn label="Tipo Documento" value="identification_type" />
                <ExcelColumn label="Documento" value="identification" />
                <ExcelColumn label="Dirección" value="street" />
              </ExcelSheet>
            </ExcelFile>
          )}
          <Button
            sx={{
              marginLeft: '15px',
            }}
            variant="contained"
            className="btn btn-primary"
            endIcon={<FileDownloadIcon />}
            onClick={() => {
              generateExcel(places);
            }}
          >
            Plantilla base
          </Button>
        </Grid>
      </Grid>
      <Grid container className="card">
        <>
          {loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                padding: '20px 10px',
              }}
            >
              <Spinner />
            </Grid>
          ) : (
            <Grid item xs={12} sx={{ mt: 0, mb: 2, zIndex: 3 }} className="filter text-right">
              <Grid
                item
                xs={4}
                container
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 1, mb: 0, zIndex: 3, paddingLeft: 2, paddingRight: 2 }}
                className="text-left"
              >
                <Grid item xs={searchTerm !== '' ? 10 : 12}>
                  <TextField
                    size="medium"
                    fullWidth
                    label="Buscar"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                {searchTerm !== '' && (
                  <Grid item xs={2}>
                    <IconButton
                      title="Limpiar Busqueda"
                      className="button-icon-table action-click-payment"
                      aria-label="expand row"
                      size="small"
                      style={{
                        marginLeft: '20px',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSearchTerm('');
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  margin: '0px 10px',
                }}
              >
                <Button
                  variant="contained"
                  className="btn btn-primary"
                  endIcon={<AddCircleOutlineIcon />}
                  onClick={() => setOpenImport(true)}
                  sx={{
                    margin: '0px 10px',
                  }}
                >
                  Subir plantilla
                </Button>

                <Button
                  variant="contained"
                  className="btn btn-primary"
                  endIcon={<AddCircleOutlineIcon />}
                  onClick={() => setOpen(!open)}
                  sx={{
                    margin: '10px 10px',
                  }}
                >
                  Agregar
                </Button>
              </Grid>
              {filteredData && filteredData?.length > 0 ? (
                <>
                  <CustomTable
                    titles={titles}
                    data={filteredData}
                    pagination={pagination}
                    active
                    onPageChange={(event, page) => changePage(page)}
                    onRowsPerPageChange={(event) => changeRowsPerPage(event.target.value)}
                  >
                    <TableBody>
                      {filteredData
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item) => (
                          <TableRow key={item.id}>
                            <TableCell align="center">{item.id}</TableCell>
                            <TableCell align="center">{item.name}</TableCell>
                            <TableCell align="center">{item.identificationType}</TableCell>
                            <TableCell align="center">{item.identification}</TableCell>
                            <TableCell align="center">{item.movil}</TableCell>
                            <TableCell align="center">{item.email}</TableCell>
                            <TableCell align="center">{item.street}</TableCell>
                            <TableCell align="center">
                              <IconButton
                                title="Editar Contacto"
                                className="button-icon-table action-click-payment"
                                aria-label="expand row"
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditCustomer({
                                    open: !editCustomer?.open,
                                    ...item,
                                  });
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </CustomTable>
                </>
              ) : (
                <Grid item xs={12} className="pb-1">
                  <h5 className="text-center">Sin registros </h5>
                </Grid>
              )}
            </Grid>
          )}
        </>
      </Grid>
    </Grid>
  );
};

ContainerCustomers.propTypes = {
  loading: PropTypes.bool.isRequired,
  getContactsFulfillment: PropTypes.func.isRequired,
  createContactFulfillment: PropTypes.func.isRequired,
  createDeliveryContact: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
  clients: PropTypes.shape({}).isRequired,
  delivery: PropTypes.shape({}).isRequired,
  createCustomer: PropTypes.shape({}).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  }).isRequired,
  changeRowsPerPage: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  stock: {
    fulfillmentClients: { clients, loading },
    createDelivery: delivery,
    createCustomer,
    pagination,
  },
}) => ({ clients, loading, delivery, createCustomer, pagination });

const mapDispatchToProps = (dispatch) => ({
  getContactsFulfillment: () => dispatch(Stocks.getContactsFulfillment()),
  createContactFulfillment: (payload) => dispatch(Stocks.createContactFulfillment(payload)),
  createDeliveryContact: (payload) => dispatch(Stocks.createDeliveryContact(payload)),
  updateContact: (payload) => dispatch(Stocks.updateContactAddrees(payload)),
  changeRowsPerPage: (item) => dispatch(Stocks.changeRowsPerPage(item)),
  changePage: (item) => dispatch(Stocks.changePage(item)),
});

export const CustomerContainer = connect(mapStateToProps, mapDispatchToProps)(ContainerCustomers);
