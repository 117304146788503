import { call } from 'redux-saga/effects';
import { ODOO_HOST, DEMO_USER, ODOO_HOST_DEMO } from '@constants/index';
import { odooApi } from './api';

export const AuthService = {
  *login(payload) {
    odooApi.basePath =
      localStorage.getItem('DEMO_USER') === DEMO_USER
        ? `${ODOO_HOST_DEMO}/api`
        : `${ODOO_HOST}/api`;
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/login',
      {
        platform: 'SASCHA',
      },
      {
        ...payload,
      },
    );
  },
};
