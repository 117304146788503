import { call } from 'redux-saga/effects';
import { odooApi } from './api';

export const UserService = {
  *getUserData() {
    const {
      data: {
        email,
        document: documentNumber,
        documentType,
        document: nit,
        avatar,
        phone,
        id,
        is_Fulfillment: isFulfillment,
      },
    } = yield call({ context: odooApi, fn: odooApi.get }, '/contacts/user');
    return {
      eid: id.toString(),
      email: email || '',
      nit,
      documentNumber,
      documentType,
      name: `demo ${id.toString()}`,
      avatar,
      phone,
      isFulfillment,
    };
  },
};
