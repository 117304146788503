/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { PAGE, ROWS_PER_PAGE } from '@constants/index';
import { Types as PurchasesSaga } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  loading: false,
  itemSold: {
    totalAmount: 0,
    totalUnits: 0,
  },
  loadingLines: false,
  summary: [],
  orders: {
    list: [],
    loading: false,
  },
  product: {},
  datalevel: {},
  averageTicket: {},
  dataFrequency: {},
  delivered: [],
  productsRanking: [],
  purchaseLines: [],
  paginationProduct: {
    page: PAGE,
    rowsPerPage: ROWS_PER_PAGE,
  },
});
/* ------------- Reducers ------------- */
export const totalSoldRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});
export const totalSoldSuccess = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: false,
  itemSold: {
    totalAmount: payload.totalAmount,
    totalUnits: payload.totalUnits,
  },
});
export const summarySuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  summary: [...data.summary],
});

export const getTopProductRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});
export const topProductSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  product: data,
});

export const getServiceLevelRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});
export const serviceLevelSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  datalevel: { ...data },
});

export const averageTicketSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  averageTicket: data,
});
export const frequencySuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  dataFrequency: data,
});
export const deliveredSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  delivered: [...data.orders],
});

export const getorderLinesRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingLines: true,
});
export const orderLinesSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loadingLines: false,
  purchaseLines: data,
});

export const resetOrderLines = (state = INITIAL_STATE) => ({
  ...state,
  loadingLines: false,
  purchaseLines: [],
});
export const filterTopPurchasesRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

export const filterTopPurchases = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  ...data,
});

export const rankingProductSucces = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  productsRanking: data?.products?.sort((a, b) => b.total_amount - a.total_amount) || [],
  paginationProduct: {
    page: PAGE,
    rowsPerPage: ROWS_PER_PAGE,
  },
});
export const changeRowsPerPage = (state = INITIAL_STATE, { PerPage }) => ({
  ...state,
  paginationProduct: {
    rowsPerPage: PerPage,
    page: PAGE,
  },
});

export const changePage = (state = INITIAL_STATE, { page }) => ({
  ...state,
  paginationProduct: {
    ...state.paginationProduct,
    page,
  },
});

export const getpurchaseOrdensRequest = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  orders: {
    loading: true,
  },
});

export const getpurchaseOrdensSucces = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loading: false,
  orders: {
    list: data,
    loading: false,
  },
});
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [PurchasesSaga.TOTAL_SOLD_SUCCESS]: totalSoldSuccess,
  [PurchasesSaga.SUMMARY_SUCCESS]: summarySuccess,
  [PurchasesSaga.TOP_PRODUCT_SUCCESS]: topProductSuccess,
  [PurchasesSaga.SERVICE_LEVEL_SUCCESS]: serviceLevelSuccess,
  [PurchasesSaga.AVERAGE_TICKET_SUCCESS]: averageTicketSuccess,
  [PurchasesSaga.FREQUENCY_SUCCESS]: frequencySuccess,
  [PurchasesSaga.DELIVERED_SUCCESS]: deliveredSuccess,
  [PurchasesSaga.FILTER_TOP_PURCHASES_SUCCESS]: filterTopPurchases,
  [PurchasesSaga.FILTER_TOP_PURCHASES]: filterTopPurchasesRequest,
  [PurchasesSaga.RANKING_PRODUCT_SUCCESS]: rankingProductSucces,
  [PurchasesSaga.CHANGE_ROWS_PER_PAGE]: changeRowsPerPage,
  [PurchasesSaga.CHANGE_PAGE]: changePage,
  // [PurchasesSaga.GET_SERVICE_LEVEL]: getpurchaseOrdens,
  [PurchasesSaga.GET_PURCHASE_ORDERS]: getpurchaseOrdensRequest,
  [PurchasesSaga.PURCHASE_ORDERS_SUCCESS]: getpurchaseOrdensSucces,
  [PurchasesSaga.GET_SERVICE_LEVEL]: getServiceLevelRequest,
  [PurchasesSaga.TOTAL_SOLD]: totalSoldRequest,
  [PurchasesSaga.GET_TOP_PRODUCT]: getTopProductRequest,
  [PurchasesSaga.GET_ORDER_LINES]: getorderLinesRequest,
  [PurchasesSaga.GET_ORDER_LINES_SUCCESS]: orderLinesSuccess,
  [PurchasesSaga.RESET_ORDER_LINES]: resetOrderLines,
});
