import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like Purchases_Request -> PURCHASES_REQUEST
 */
export const { Types, Creators } = createActions({
  totalSold: ['months'],
  totalSoldSuccess: ['payload'],
  getSummary: ['months'],
  summarySuccess: ['data'],
  getTopProduct: ['months'],
  topProductSuccess: ['data'],
  getServiceLevel: ['months'],
  serviceLevelSuccess: ['data'],
  getAverageTicket: null,
  averageTicketSuccess: ['data'],
  getFrequencyPurchase: null,
  frequencySuccess: ['data'],
  deliveredSuccess: ['data'],
  filterTopPurchases: ['item', 'date'],
  filterTopPurchasesSuccess: ['data'],
  getRankingProduct: ['item', 'date'],
  rankingProductSuccess: ['data'],
  changeRowsPerPage: ['PerPage'],
  changePage: ['page'],
  getPurchaseOrders: null,
  purchaseOrdersSuccess: ['data'],
  getPurchaseOrdersDownload: ['id'],
  getOrderLines: ['orderId'],
  getOrderLinesSuccess: ['data'],
  resetOrderLines: null,
});
