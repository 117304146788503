import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';

import { Chart } from '@superfuds/sf-charts';
import { connect } from 'react-redux';
import { Dropdown } from '@superfuds/saori';

import { Creators as Sales } from '@reducers/SellIn';
import {
  PurcharsesTop,
  SummaryOrders,
  TemplateRanking,
  BusinessPotential,
  CustomerSummary,
  TemplateSalesProductDetails,
} from '@components/index';

import {
  optionsChart,
  monthByMonthDropdown,
  CHANNEL,
  optionsChartCustomer,
} from '@constants/index';

import Product from '@assets/product.png';
import Prod from '@assets/pro.png';

import './style.scss';

const initialState = {
  chanel: '',
  months: 1,
};

const SellInReport = ({
  totalAmount,
  summary,
  delivered,
  pagination,
  filter,
  productsRankingSale,
  changePage,
  changeRowsPerPage,
  paginationProduct,
  productTop,
  customers,
  loading,
  filterRankingProduct,
  filterChanelMonth,
  loadingTop,
  getCustomerActivity,
  summaryCustomer,
  customerCurrent,
  loadingCustomer,
  filterProductSales,
  productSales,
  getListProductSales,
  listProduct,
  getProductDetails,
  detailsProduct,
  getCustomersList,
  listCustomers,
  getFrequencyCustomer,
  frequency,
  getFrequencyCustomerReset,
  getProductsClients,
  productClients,
}) => {
  useEffect(() => {
    filter(1);
    filterRankingProduct(
      {
        chanel: '',
        months: 1,
      },
      null,
    );
    filterChanelMonth({
      chanel: '',
      months: 1,
    });
    getCustomerActivity({
      chanel: '',
      months: 1,
    });
    filterProductSales({
      chanel: '',
      months: 1,
    });
    getListProductSales();
    getCustomersList();
  }, [
    getCustomersList,
    filterChanelMonth,
    filter,
    filterRankingProduct,
    getCustomerActivity,
    filterProductSales,
    getListProductSales,
  ]);

  const [filterOption, setFilterOption] = useState(initialState);
  const [filterProduct, setFilterProduct] = useState(initialState);
  const [filterCustomer, setFilterCustomer] = useState(initialState);

  const isNegativeNumber = (number) => {
    const color = number < 0 ? '#EF4044' : '#25c16a';
    const content = number < 0 ? `Decrecimiento ${number}%` : `Crecimiento ${number}%`;

    return (
      <Typography style={{ color }} variant="caption" display="block" gutterBottom>
        {content}
      </Typography>
    );
  };

  return (
    <Grid
      container
      item
      justifyContent="center"
      id="sf-purchaseReport"
      className="sf-purchaseReport"
    >
      <Grid item xs={12} className="pb-3 pt-3">
        <h2 className="sf-title">Informe de Sell In de Superfüds</h2>
      </Grid>
      <PurcharsesTop
        product={productTop}
        totalAmount={totalAmount}
        serviceLevel={0}
        filter={filter}
        title="Total de Ventas"
        customers={customers}
        loading={loadingTop}
        titleView="ventas"
      />
      <TemplateRanking
        monthByMonthDropdown={monthByMonthDropdown}
        productsRankingSale={productsRankingSale}
        filterProduct={filterProduct}
        setFilterProduct={setFilterProduct}
        filterRankingProduct={filterRankingProduct}
      />
      <TemplateSalesProductDetails
        monthByMonthDropdown={monthByMonthDropdown}
        productSales={productSales?.products}
        loading={productSales?.loading}
        productClients={productClients}
        listCustomers={listCustomers}
        getProductDetails={getProductDetails}
        detailsProduct={detailsProduct}
        listProduct={listProduct}
        filterProduct={filterProduct}
        setFilterProduct={setFilterProduct}
        filterProductSales={filterProductSales}
        channel={CHANNEL}
        paginationProduct={paginationProduct}
        changePage={changePage}
        changeRowsPerPage={changeRowsPerPage}
        getFrequencyCustomer={getFrequencyCustomer}
        frequency={frequency}
        getProductsClients={getProductsClients}
        getFrequencyCustomerReset={getFrequencyCustomerReset}
      />

      <Grid
        container
        direction="row"
        className="total_sales"
        sx={{ mt: 7 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <h2 className="sf-title-secondary">Total de ventas Mensuales</h2>
        </Grid>
        <Grid
          className="card"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sx={{ mt: 2, mb: 2, zIndz: 3 }} className="filter text-right">
            <Dropdown
              classNamePrefix="dropdown"
              className="container-filter"
              options={CHANNEL}
              onChange={(item) => {
                const filter = {
                  ...filterOption,
                  chanel: item.value,
                };
                setFilterOption(filter);
                filterChanelMonth(filter);
              }}
              placeholder="Canal de Ventas"
              value={CHANNEL.filter((option) => option.value === filterOption?.chanel)}
            />
            {/*     <Dropdown
              classNamePrefix="dropdown"
              className="container-filter"
              options={monthByMonthDropdown}
              onChange={(item) => {
                const filter = {
                  ...filterOption,
                  months: item.value,
                };
                setFilterOption(filter);
                filterChanelMonth(filter);
              }}
              defaultValue={monthByMonthDropdown[0]}
              value={monthByMonthDropdown.filter((option) => option.value === filterOption?.months)}
            /> */}
          </Grid>
          {summary && summary?.length > 0 ? (
            <SummaryOrders
              activeSale
              summary={summary}
              pagination={pagination}
              delivered={delivered}
              titles={['Mes', 'Unidades Vendidas', 'Ventas', 'Total', '']}
              titleDetails={[
                '',
                'Orden',
                'Fecha de Solicitud',
                'Fecha de Entrega',
                'Unidades Pedidas',
                'Unidades Entregadas',
              ]}
            />
          ) : (
            <Grid item xs={12} className="card_height container-message">
              <h3 className="sf-title-secondary">No hay Resultados</h3>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        className="containerfequency"
        sx={{ mt: 7 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <h2 className="sf-title-secondary">Tendencia de Ventas Mensuales </h2>
        </Grid>
        <Grid
          className="card"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sx={{ mt: 2, mb: 2, zIndex: 3 }} className="filter text-right">
            <Dropdown
              classNamePrefix="dropdown"
              className="container-filter"
              options={CHANNEL}
              onChange={(item) => {
                const filter = {
                  ...filterOption,
                  chanel: item.value,
                };
                setFilterOption(filter);
                filterChanelMonth(filter);
              }}
              placeholder="Canal de Ventas"
              value={CHANNEL.filter((option) => option.value === filterOption?.chanel)}
            />
            {/*       <Dropdown
              classNamePrefix="dropdown"
              className="container-filter"
              options={monthByMonthDropdown}
              onChange={(item) => {
                const filter = {
                  ...filterOption,
                  months: item.value,
                };
                setFilterOption(filter);
                filterChanelMonth(filter);
              }}
              defaultValue={monthByMonthDropdown[0]}
              value={monthByMonthDropdown.filter((option) => option.value === filterOption?.months)}
            /> */}
          </Grid>
          {summary && summary?.length > 0 ? (
            <Chart data={summary} options={optionsChart(summary)} />
          ) : (
            <Grid item xs={12} className="card_height container-message">
              <h3 className="sf-title-secondary">No hay Resultados</h3>
            </Grid>
          )}
        </Grid>
      </Grid>
      <CustomerSummary
        monthByMonthDropdown={monthByMonthDropdown}
        setFilterCustomer={setFilterCustomer}
        channel={CHANNEL}
        filterCustomer={filterCustomer}
        getCustomerActivity={getCustomerActivity}
        loadingCustomer={loadingCustomer}
        summaryCustomer={summaryCustomer}
        optionsChartCustomer={optionsChartCustomer}
        customerCurrent={customerCurrent}
        isNegativeNumber={isNegativeNumber}
      />

      <BusinessPotential Product={Product} Prod={Prod} />
    </Grid>
  );
};

SellInReport.propTypes = {
  loadingTop: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingCustomer: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    name: PropTypes?.string,
  }).isRequired,
  totalAmount: PropTypes.number.isRequired,
  filterChanelMonth: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  getCustomerActivity: PropTypes.func.isRequired,
  filterRankingProduct: PropTypes.func.isRequired,
  changeRowsPerPage: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  productTop: PropTypes.shape({}).isRequired,
  productSales: PropTypes.shape({}).isRequired,
  summary: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  summaryCustomer: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  delivered: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataFrequency: PropTypes.shape({
    frequency: PropTypes.number,
    summaryFqy: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  pagination: PropTypes.shape({}).isRequired,
  paginationProduct: PropTypes.shape({}).isRequired,
  productsRankingSale: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customerCurrent: PropTypes.shape({}).isRequired,
  filterProductSales: PropTypes.func.isRequired,
  getListProductSales: PropTypes.func.isRequired,
  listProduct: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getProductDetails: PropTypes.func.isRequired,
  detailsProduct: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getCustomersList: PropTypes.func.isRequired,
  listCustomers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  frequency: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productClients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getFrequencyCustomer: PropTypes.func.isRequired,
  getFrequencyCustomerReset: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  user,
  sales: {
    deliveredSales: delivered,
    productsRankingSale,
    paginationProduct,
    totalAmount,
    productTop,
    customers,
    loading,
    summarySales: summary,
    loadingTop,
    summaryCustomer,
    loadingCustomer,
    customerCurrent,
    productSales,
    listProduct,
    detailsProduct,
    listCustomers,
    frequency,
    productClients,
  },
}) => ({
  user,
  totalAmount,
  delivered,
  summary,
  productsRankingSale,
  paginationProduct,
  productTop,
  loading,
  loadingTop,
  customers,
  summaryCustomer,
  loadingCustomer,
  customerCurrent,
  productSales,
  listProduct,
  detailsProduct,
  listCustomers,
  frequency,
  productClients,
});

const mapDispatchToProps = (dispatch) => {
  return {
    filter: (item, date) => dispatch(Sales.filterTopSales(item, date)),
    filterRankingProduct: (item, date) => dispatch(Sales.filterRankingProductSales(item, date)),
    filterChanelMonth: (filter) => dispatch(Sales.filterChanelMonth(filter)),
    filterProductSales: (item, date) => dispatch(Sales.filterProductSales(item, date)),
    getCustomerActivity: (filter) => dispatch(Sales.filterCustomerActivity(filter)),
    changeRowsPerPage: (item) => dispatch(Sales.changeRowsPerPage(item)),
    getListProductSales: () => dispatch(Sales.getListProductSales()),
    getProductDetails: (id) => dispatch(Sales.getProductDetails(id)),
    changePage: (item) => dispatch(Sales.changePage(item)),
    getCustomersList: () => dispatch(Sales.getCustomersList()),
    getFrequencyCustomer: (payload) => dispatch(Sales.getFrequencyCustomer(payload)),
    getProductsClients: (id) => dispatch(Sales.getProductsClients(id)),
    getFrequencyCustomerReset: () => dispatch(Sales.getFrequencyCustomerReset()),
  };
};
export const SellInReportContainer = connect(mapStateToProps, mapDispatchToProps)(SellInReport);
