import React from 'react';
import PropTypes from 'prop-types';

import { Grid, TableCell, TableBody, TableRow, Typography, Alert } from '@mui/material';

import { CustomTable, ModalLayout, Spinner } from '@components/index';
import { formatCurrency } from '@utils';

import './style.scss';

export const DetallisInvoces = ({ layout, handleClose, account }) => {
  return (
    <ModalLayout open={layout.open} handleClose={handleClose} fullWidth={false} maxWidth="lg">
      <Grid item container>
        <Grid item xs={9}>
          <Typography variant="h6">
            <strong>Factura de proveedor: </strong>
            {layout?.name}
          </Typography>
          <Typography variant="h6">
            <strong>Proveedor: </strong>
            {layout?.user?.name}
          </Typography>
          <Typography variant="h6">
            <strong>Fecha de Vencimiento: </strong>
            {layout.expiration_date > 0
              ? `En ${layout.expiration_date + 1} días`
              : (layout.payment_status === 'not_paid' ||
                layout.payment_status === 'in_payment' ||
                layout.payment_status === 'partial') && (
                <span
                  style={{
                    color: 'red',
                  }}
                >{`Hace ${Math.abs(layout.expiration_date)} días`}</span>
              )}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          {layout.payment_status === 'paid' && <Alert severity="success">Pagado</Alert>}
          {layout.payment_status === 'in_payment' && (
            <Alert severity="info">En proceso de pago</Alert>
          )}
          {layout.payment_status === 'not_paid' && <Alert severity="warning">No pagadas</Alert>}
          {layout.payment_status === 'partial' && (
            <Alert severity="info">Pagado Parcialmente</Alert>
          )}
          {layout.payment_status === 'reversed' && <Alert severity="error">Revertido</Alert>}
          {layout.payment_status === 'invoicing_legacy' && (
            <Alert severity="error">Factura Sistema Anterior</Alert>
          )}

          <Typography gutterBottom component="div" style={{ margin: '10px 0' }}>
            Referencia de pago: {layout.ref}
          </Typography>
        </Grid>
      </Grid>
      {account?.loadingLines ? (
        <Grid
          container
          className="card"
          justifyContent="center"
          alignItems="center"
          style={{
            padding: '20px 10px',
          }}
        >
          <Spinner />
        </Grid>
      ) : (
        <Grid item container sx={{ mt: 5 }}>
          <Typography gutterBottom component="div">
            <strong> Detalle de Factura</strong>
          </Typography>
          <Grid item xs={12}>
            <CustomTable
              titles={[
                'Nombre de Producto',
                'Cantidad',
                'Embalaje (UdM)',
                'Precios Unitario',
                'SubTotal',
                'Descuento %',
                'Impuesto',
                'Total',
              ]}
              data={account?.billsLines}
              active={false}
            >
              <TableBody>
                {account?.billsLines &&
                  account?.billsLines?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.product_name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.quantity}
                      </TableCell>
                      <TableCell align="center">{row.product_uom}</TableCell>
                      <TableCell align="center">{formatCurrency(row.price_unit)}</TableCell>
                      <TableCell align="center">{formatCurrency(row.price_subtotal)}</TableCell>
                      <TableCell align="center">{row.discount}</TableCell>
                      <TableCell align="left">{row.taxes?.join('\n\n')}</TableCell>
                      <TableCell align="center"> {formatCurrency(row.price_total)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </CustomTable>
          </Grid>
          <Grid
            item
            container
            xs={12}
            flexDirection="column"
            alignItems="end"
            style={{
              paddingTop: 20,
            }}
          >
            <Grid>
              <Typography variant="body1">
                <strong> Subtotal</strong>:{' '}
                {account?.billsLines &&
                  formatCurrency(
                    account?.billsLines.reduce(
                      (total, item) => total + item.price_unit * item.quantity,
                      0,
                    ),
                  )}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body1">
                <strong>Total:</strong>{' '}
                {account?.billsLines &&
                  formatCurrency(
                    account?.billsLines.reduce((total, item) => total + item.price_total, 0),
                  )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ModalLayout>
  );
};
DetallisInvoces.propTypes = {
  account: PropTypes.shape({
    billsLines: PropTypes.arrayOf(PropTypes.shape({})),
    loadingLines: PropTypes.bool.isRequired,
  }).isRequired,
  layout: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    user: PropTypes.shape({}).isRequired,
    payment_status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    expiration_date: PropTypes.string.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
};
