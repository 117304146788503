import React from 'react';
import PropTypes from 'prop-types';

import { Alert, AlertTitle, Grid } from '@mui/material';

export const BusinessPotential = ({ Product, Prod }) => {
  return (
    <>
      <Grid
        container
        direction="row"
        sx={{ mt: 7 }}
        justifyContent="center"
        alignItems="center"
        className="containerRendimiento"
      >
        <Grid item xs={12}>
          <Alert severity="info" sx={{ width: '100%' }}>
            <AlertTitle>¡Potencia tu éxito empresarial!</AlertTitle>
            <strong>
              Nuestro servicio se activa cuando tus ventas superan los 300 millones de pesos
              mensuales.
            </strong>
          </Alert>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            opacity: '0.5',
          }}
        >
          <h2 className="sf-title-secondary">Detalle del rendimiento de tus productos </h2>
        </Grid>
        <Grid
          style={{
            opacity: '0.5',
          }}
          className="card"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <img
              data-sizes="auto"
              alt={Product}
              className="logo lazyload"
              src={Product}
              style={{
                width: '100%',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        sx={{ mt: 7 }}
        justifyContent="center"
        alignItems="center"
        className="containerCompara"
      >
        <Grid item xs={12}>
          <Alert severity="info" sx={{ width: '100%' }}>
            <AlertTitle>¡Potencia tu éxito empresarial!</AlertTitle>
            <strong>
              Nuestro servicio se activa cuando tus ventas superan los 300 millones de pesos
              mensuales.
            </strong>
          </Alert>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            opacity: '0.5',
          }}
        >
          <h2 className="sf-title-secondary">Compara tus productos con otros similares </h2>
        </Grid>
        <Grid
          style={{
            opacity: '0.5',
          }}
          className="card"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <img
              data-sizes="auto"
              alt={Prod}
              className="logo lazyload"
              src={Prod}
              style={{
                width: '100%',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
BusinessPotential.propTypes = {
  Product: PropTypes.string.isRequired,
  Prod: PropTypes.string.isRequired,
};
