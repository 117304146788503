import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';

import { PAGE, ROWS_PER_PAGE } from '@constants/index';
import './style.scss';

export const LayoutTable = ({
  children,
  titles,
  data,
  pagination,
  onPageChange,
  onRowsPerPageChange,
  active,
  textAlign,
}) => {
  return (
    <Paper sx={{ width: '100%' }} className="table-custom">
      <TableContainer component={Paper} sx={{ maxHeight: 510 }} className="table_container">
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="Table_head">
            <TableRow>
              {titles.map((row) => (
                <TableCell key={row} align={textAlign}>
                  {row}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {children}
        </Table>
      </TableContainer>
      {active && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </Paper>
  );
};

LayoutTable.defaultProps = {
  pagination: {
    page: PAGE,
    rowsPerPage: ROWS_PER_PAGE,
  },
  textAlign: 'center',
  onPageChange: () => { },
  onRowsPerPageChange: () => { },
  active: false,
};
LayoutTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  textAlign: PropTypes.string,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
  }),
  onPageChange: PropTypes.func,
  active: PropTypes.bool,
  onRowsPerPageChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const CustomTable = LayoutTable;
