import storage from 'redux-persist/lib/storage';
import { REDUCERVERSION } from '@constants/index';
import immutablePersistenceTransform from './ImmutablePersistenceTransform';

// More info here:  https://shift.infinite.red/shipping-persistant-reducers-7341691232b1
export const reduxPersistConfig = {
  active: true,
  reducerVersion: REDUCERVERSION || 1,
  gitCommit: process.env.REDUX_HASH,
  storeConfig: {
    key: 'root',
    storage,
    // Reducer keys that you do NOT want stored to persistence here.
    blacklist: ['auth', 'router'],
    // Optionally, just specify the keys you DO want stored to persistence.
    // An empty array means 'don't store any reducers' -> infinitered/ignite#409
    // whitelist: [],
    transforms: [immutablePersistenceTransform],
  },
};
