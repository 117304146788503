import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';

import { formatCurrency } from '@utils';

import './style.scss';

export const BestSellingProduct = ({ product, title = 'vendido' }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" className="title">
          Total de {title} por producto
        </Typography>
      </Grid>
      <Grid item>
        {product.image !== '' && (
          <Grid className="image">
            <img className="img" alt={product.name} src={product.image} />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm container>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Typography gutterBottom variant="caption">
              {product?.name || ''}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <span
                style={{
                  color: '#25c16a',
                }}
              >
                sku
              </span>{' '}
              {product?.sku || ''}
            </Typography>
            <Typography variant="body2" className="text_price" gutterBottom>
              {(product?.totalAmount && formatCurrency(product?.totalAmount)) || ''}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {product?.totalUnits || ''} Unidades
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

BestSellingProduct.propTypes = {
  title: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    product_code: PropTypes.string,
    total_amount: PropTypes.number,
    total_qty_delibered: PropTypes.number,
    product_name: PropTypes.string,
    name: PropTypes.string,
    sku: PropTypes.string,
    totalAmount: PropTypes.number,
    totalUnits: PropTypes.number,
  }).isRequired,
};
