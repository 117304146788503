import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Grid,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Collapse,
  Typography,
  TableHead,
  Box,
  Table,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { CustomTable, ModalLayout } from '@components/index';
import { TotalUnits, Total, TotalOrders, formatCurrency } from '@utils';

import './style.scss';

const isNegativeNumber = (number) => {
  const color = number < 0 ? '#EF4044' : '#25c16a';
  const content = number < 0 ? `Decrecimiento ${number}%` : `Crecimiento ${number}%`;

  return (
    <Typography style={{ color }} variant="caption" display="block" gutterBottom>
      {content}
    </Typography>
  );
};

const groupObjects = (array) => {
  const agrupado = array.reduce((resultado, objeto) => {
    const clave = objeto.order_name;
    if (!resultado[clave]) {
      resultado[clave] = {
        order_name: clave,
        requested_units_total: 0,
        delivered_units_total: 0,
        expected_date: 0,
        effective_date: 0,
        detail: [],
      };
    }
    resultado[clave].requested_units_total += objeto.requested_units;
    resultado[clave].delivered_units_total += objeto.delivered_units;
    resultado[clave].expected_date = moment(objeto.expected_date).format('YYYY-MM-DD');
    resultado[clave].effective_date = moment(objeto.expected_date).format('YYYY-MM-DD');
    resultado[clave].detail.push(objeto);
    return resultado;
  }, {});

  const detail = Object.keys(agrupado).map((clave) => {
    return {
      ...agrupado[clave],
      detail: agrupado[clave].detail.map((objeto) => {
        return {
          id: objeto.id,
          product_name: objeto.product_name,
          order_name: objeto.order_name,
          expected_date: objeto.expected_date,
          effective_date: objeto.effective_date,
          requested_units: objeto.requested_units,
          delivered_units: objeto.delivered_units,
        };
      }),
    };
  });

  return detail;
};

const renderRow = (rows, handleOpen, delivered, setDataDelivered, activeSale) => {
  return rows.map((item) => (
    <TableRow key={item.date}>
      <TableCell align="center">{item.name}</TableCell>
      <TableCell align="center">
        {item.units}
        {item?.percentage && (
          <Typography variant="caption"> {isNegativeNumber(item.percentage)}</Typography>
        )}
      </TableCell>
      <TableCell align="center">
        {activeSale ? groupObjects(delivered)?.length : item.ordersQty}
      </TableCell>
      <TableCell align="center">{item.amount}</TableCell>
      <TableCell align="center">
        <IconButton
          className="button-icon-table"
          aria-label="expand row"
          size="small"
          onClick={() => {
            const data = delivered?.filter((n) =>
              moment(item.newDate)?.isSame(
                moment(n.requested_date || n.effective_date).format('YYYY-MM'),
              ),
            );
            setDataDelivered(data);
            handleOpen();
          }}
        >
          <RemoveRedEyeIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ));
};

export const SummaryOrders = ({ summary, delivered, titles, activeSale, titleDetails }) => {
  const [open, setOpen] = useState(false);
  const [dataDelivered, setDataDelivered] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openDetails, setOpenDetails] = useState({});

  const handleToggle = (id) => {
    setOpenDetails((prevState) => {
      const newState = { ...prevState };
      newState[id] = !prevState[id];
      return newState;
    });
  };
  return (
    <Grid
      container
      direction="row"
      sx={{ mb: 7 }}
      justifyContent="center"
      alignItems="center"
      className="card"
    >
      <ModalLayout open={open} handleClose={handleClose}>
        <CustomTable titles={titleDetails} data={delivered}>
          <TableBody>
            {(activeSale ? groupObjects(dataDelivered) : dataDelivered)?.map((item, index) => (
              <Fragment key={item.id || item?.order_name}>
                <TableRow>
                  {activeSale && (
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleToggle(item?.order_name)}
                      >
                        {openDetails[item.order_name] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  )}
                  {item?.order_name ? (
                    <TableCell align="center">{item?.order_name}</TableCell>
                  ) : (
                    <TableCell align="center">#{item?.name}</TableCell>
                  )}
                  <TableCell align="center">
                    {moment(item?.requested_date).format('YYYY-MM-DD') || item?.expected_date || ''}
                  </TableCell>
                  <TableCell align="center">
                    {item?.delivered_date !== false
                      ? moment(item?.delivered_date).format('YYYY-MM-DD') || item?.effective_date
                      : 'Sin Fecha de Entrega'}
                  </TableCell>
                  <TableCell align="center">
                    {item?.requested_units || item?.requested_units_total}
                  </TableCell>
                  <TableCell align="center">
                    {item?.delivered_units || item?.delivered_units_total}
                  </TableCell>
                </TableRow>
                {item?.detail && item?.detail?.length > 0 && openDetails[item.order_name] && (
                  <TableRow>
                    <TableCell style={{ paddingBottom: 20, paddingTop: 20 }} colSpan={6}>
                      <Collapse in={openDetails[item.order_name]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography gutterBottom component="div">
                            <strong> Detalle de Pedido</strong>
                          </Typography>
                          <Table size="small" aria-label="summary">
                            <TableHead className="Table_head">
                              <TableRow>
                                <TableCell>Nombre de Producto</TableCell>
                                <TableCell align="center">Unidades Pedidas</TableCell>
                                <TableCell align="center">Unidades Entregadas</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item?.detail?.map((product) => (
                                <TableRow key={product.id}>
                                  <TableCell component="th" scope="row">
                                    {product.product_name}
                                  </TableCell>
                                  <TableCell align="center">{product.requested_units}</TableCell>
                                  <TableCell align="center">{product.delivered_units}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            ))}
          </TableBody>
        </CustomTable>
      </ModalLayout>
      {summary && summary.length > 0 && (
        <CustomTable titles={titles} data={summary}>
          <TableBody>
            {renderRow(summary, handleOpen, delivered, setDataDelivered, activeSale)}
            <TableRow className="footer-table-summary">
              <TableCell align="center">Total</TableCell>
              <TableCell align="center">{TotalUnits(summary)}</TableCell>
              <TableCell align="center">
                {activeSale ? groupObjects(delivered)?.length : TotalOrders(summary)}
              </TableCell>
              <TableCell align="center">{formatCurrency(Total(summary))}</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableBody>
        </CustomTable>
      )}
    </Grid>
  );
};
SummaryOrders.defaultProps = {
  pagination: {},
  activeSale: false,
  titles: ['Mes', 'Total Unidades', 'Total Órdenes', 'Total Compras', ''],
  titleDetails: [
    'Orden',
    'Fecha de Solicitud',
    'Fecha de Entrega',
    'Unidades Pedidas',
    'Unidades Entregadas',
  ],
};

SummaryOrders.propTypes = {
  summary: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  delivered: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({}),
  titles: PropTypes.arrayOf(PropTypes.string),
  titleDetails: PropTypes.arrayOf(PropTypes.string),
  activeSale: PropTypes.bool,
};
