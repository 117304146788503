import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';

import {
  Button,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';

import { ToastContainer, Slide } from 'react-toastify';
import { Creators as Stocks } from '@reducers/Stocks';
import { CustomTable, Spinner } from '@components/index';
import { TextField } from '@constants/index';
import { formikConf } from './orderReception.formik';

const formattedProducts = (products) =>
  Object.entries(products).map(([key, qty]) => {
    const productId = parseInt(key?.match(/'product_id': (\d+)/)[1], 10);
    const json = { product_id: productId, qty, price: 0 };
    return json;
  });

const ContainerReception = ({
  getProducts,
  clients,
  products,
  createOrderPicking,
  getContactsFulfillment,
  createPicking,
  loading,
}) => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const formRef = useRef(null);
  useEffect(() => {
    getProducts();
    getContactsFulfillment();
  }, [getProducts, getContactsFulfillment]);

  useEffect(() => {
    if (createPicking?.code === 201) {
      if (formRef.current) {
        const form = formRef.current;
        Array.from(form.elements).forEach((element) => {
          if (element.type !== 'submit') {
            element.value = '';
          }
        });
        form.reset();
      }
    }
  }, [createPicking, getContactsFulfillment, getProducts]);

  const filteredData = products?.filter((row) => {
    const rowValues = Object.values(row).join(' ').toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    return rowValues.includes(searchTermLower);
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value?.trim());
  };

  const handleFileChange = (event, setFieldValue, values) => {
    const file = event?.target?.files[0];

    if (file) {
      const renamedFile = new File([file], `${values?.document_origin}.pdf`, {
        type: file.type,
        lastModified: file.lastModified,
      });

      setFieldValue('fileData', renamedFile);
    }
  };
  return (
    <Grid
      container
      item
      justifyContent="flex-center"
      id="sf-fulfillment-inventory"
      className="sf-fulfillment-inventory"
    >
      <ToastContainer transition={Slide} draggablePercent={60} />
      <Grid item xs={12} className="pb-3 pt-3">
        <h2 className="sf-title">Crear Orden de Recepción</h2>
      </Grid>
      <Grid item container sx={{ mt: 0, mb: 2 }}>
        <Grid item xs={3}>
          <IconButton
            title="Volver atras"
            className="button-icon-table action-click-payment"
            aria-label="expand row"
            size="small"
            onClick={() => history.push('/fulfillment')}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        container
        className="card"
        sx={{
          padding: 2,
        }}
      >
        <Grid item xs={12} className="pb-1 pt-1">
          <Formik
            {...formikConf}
            onSubmit={(values) => {
              createOrderPicking({
                file_data: values?.fileData,
                json_data: {
                  date_delivered: values?.date_delivered,
                  amount_origin: 0,
                  document_origin: values?.document_origin,
                  products: [...formattedProducts(values?.products)] ?? [],
                },
              });
              /*  createOrderPicking({
                 orders: [
                   {
                     date_delivered: values?.date_delivered,
                     document_origin: values?.document_origin,
                     products: [...formattedProducts(values?.products)] ?? [],
                   },
                 ],
               }); */
            }}
          >
            {({ handleChange, errors, touched, values, setFieldValue }) => {
              const productIds = formattedProducts(values?.products) ?? [];
              return (
                <Form ref={formRef}>
                  <Grid item container justifyContent="center" className="pb-3 pt-6" spacing={2}>
                    <Grid item xs={2}>
                      <TextField
                        label="Documento Origen"
                        placeholder="Ingresa Documento Origen"
                        fullWidth
                        name="document_origin"
                        onChange={handleChange}
                        onBlur={handleChange}
                        error={!!(touched.document_origin && errors.document_origin)}
                        helperText={touched.document_origin && errors.document_origin}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        label="Agregar Documento PDF"
                        placeholder="Agregar Documento PDF"
                        fullWidth
                        name="fileData"
                        disabled={!values.document_origin}
                        type="file"
                        inputProps={{
                          accept: '.pdf',
                        }}
                        onChange={(e) => {
                          handleFileChange(e, setFieldValue, values);
                        }}
                        onBlur={(e) => {
                          handleFileChange(e, setFieldValue, values);
                        }}
                        error={!!(touched.fileData && errors.fileData)}
                        helperText={
                          (touched.fileData && errors.fileData) ||
                          'Asegúrate de que el archivo sea inferior a 1 mega.'
                        }
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Fecha de Recibo"
                        placeholder="Ingresa Fecha de Recibo"
                        fullWidth
                        name="date_delivered"
                        type="date"
                        inputProps={{
                          min: '2023-06-01',
                          max: '2028-06-30',
                        }}
                        onChange={handleChange}
                        onBlur={handleChange}
                        error={!!(touched.date_delivered && errors.date_delivered)}
                        helperText={touched.date_delivered && errors.date_delivered}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
                      <Grid
                        item
                        xs={12}
                        sx={{ mt: 0, mb: 2, zIndex: 3 }}
                        className="filter text-right"
                      >
                        <Grid
                          item
                          xs={4}
                          container
                          justifyContent="center"
                          alignItems="center"
                          sx={{ mt: 1, mb: 0, zIndex: 3, paddingLeft: 2, paddingRight: 2 }}
                          className="text-left"
                        >
                          <Grid item xs={searchTerm !== '' ? 10 : 12}>
                            <TextField
                              size="medium"
                              fullWidth
                              label="Buscar"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              margin="normal"
                              variant="outlined"
                            />
                          </Grid>
                          {searchTerm !== '' && (
                            <Grid item xs={2}>
                              <IconButton
                                title="Detalles de la Factura"
                                className="button-icon-table action-click-payment"
                                aria-label="expand row"
                                style={{
                                  marginLeft: '20px',
                                }}
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSearchTerm('');
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      {loading ? (
                        <Grid
                          container
                          className="card"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            padding: '20px 10px',
                          }}
                        >
                          <Spinner />
                        </Grid>
                      ) : (
                        <>
                          {filteredData && filteredData?.length > 0 ? (
                            <CustomTable
                              titles={['Código de Barra', 'Producto', 'Disponible', 'Cantidad']}
                              data={filteredData}
                              pagination={[]}
                              active={false}
                              textAlign="left"
                            >
                              <TableBody>
                                {filteredData?.map((items) => {
                                  const idActive = productIds?.find(
                                    (row) => row.product_id === items.id,
                                  )?.product_id;
                                  return (
                                    <TableRow
                                      key={items.id}
                                      style={{
                                        backgroundColor: idActive === items.id && '#8c8c8c24',
                                      }}
                                    >
                                      <TableCell align="left">{items.product_barcode}</TableCell>
                                      <TableCell align="left">
                                        {items.name} <br />
                                        <Typography variant="caption" color="gren">
                                          Sku {items.product_sku}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="left">{items.total_available}</TableCell>
                                      <TableCell align="center" width="100">
                                        <Field
                                          name={`products[{'product_id': ${items.id}}]`}
                                          type="number"
                                          fullWidth
                                        >
                                          {({ field }) => (
                                            <TextField
                                              {...field}
                                              type="number"
                                              variant="outlined"
                                              fullWidth
                                              inputProps={{
                                                min: 1,
                                                inputMode: 'numeric',
                                                style: {
                                                  textAlign: 'center',
                                                },
                                              }}
                                              error={!!(touched.products && errors.products)}
                                              helperText={touched.products && errors.products}
                                            />
                                          )}
                                        </Field>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </CustomTable>
                          ) : (
                            <Grid item xs={12} className="pb-1">
                              <h5 className="text-center">Sin registros </h5>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container sx={{ mt: 3, mb: 3 }}>
                    <Grid item xs={6} className="text-left">
                      <Button
                        style={{
                          textTransform: 'initial',
                          background: 'transparent',
                          borderColor: '#25c16a',
                          color: '#25c16a',
                        }}
                        color="primary"
                        variant="outlined"
                        onClick={() => history.push('/fulfillment')}
                        endIcon={<CloseIcon />}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                      <Button
                        variant="contained"
                        className="btn btn-primary"
                        type="submit"
                        /*  disabled={createOrder?.loading} */
                        style={{
                          textTransform: 'initial',
                        }}
                        endIcon={<SendIcon />}
                      >
                        {createPicking?.loading ? 'Guardando...' : 'Guardar'}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
};

ContainerReception.propTypes = {
  getProducts: PropTypes.func.isRequired,
  clients: PropTypes.shape({}).isRequired,
  products: PropTypes.shape({}).isRequired,
  createPicking: PropTypes.shape({}).isRequired,
  createOrderPicking: PropTypes.func.isRequired,
  getContactsFulfillment: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  stock: {
    fulfillmentProducs: { products, loading },
    fulfillmentClients: { clients },
    createPicking,
  },
}) => ({ products, loading, clients, createPicking });

const mapDispatchToProps = (dispatch) => ({
  getProducts: () => dispatch(Stocks.getProductsFulfillment()),
  getContactsFulfillment: () => dispatch(Stocks.getContactsFulfillment()),
  createOrderPicking: (payload) => dispatch(Stocks.createOrderPicking(payload)),
});

export const FulfillmentReception = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerReception);
