export const monthByMonthDropdown = [
  { label: 'Mes actual', value: 1 },
  { label: 'Tres meses atrás', value: 3 },
  { label: 'Seis meses atrás', value: 6 },
];

/* const generateMonthDropdown = () => {
  const currentMonth = new Date().getMonth();
  const monthDropdown = [];

  for (let i = 0; i <= currentMonth; i++) {
    const monthLabel =
      i === currentMonth
        ? 'Mes Actual'
        : new Date(0, i)
          .toLocaleString('es', { month: 'long' })
          .replace(/^\w/, (c) => c.toUpperCase());

    monthDropdown.push({
      label: monthLabel,
      value: i + 1,
      selected: i === currentMonth,
    });
  }

  return monthDropdown.reverse();
};
console.log(generateMonthDropdown())
export const monthByMonthDropdown = generateMonthDropdown();
 */