import { styled } from '@mui/material/styles';
import { TextField as field } from '@mui/material';

export const TOKEN_KEY = 'sf-token';
export const VAPID_KEY =
  'BMejg6GAK0fLp6NlVOW_ZqnTCt6aklodtOLrrArzYHHMTlXQOkg-zKcMPY1aGW7PZxxWw-eJZR94FU_LGjmpSiw';

export const CHANNEL = [
  {
    value: '',
    label: 'Todos los Canales',
  },
  {
    value: 'TraditionalChannel',
    label: 'Canal Tradicional',
  },
  {
    value: 'FoodService',
    label: 'Food Service',
  },
  {
    value: 'ModernChannel',
    label: 'Canal Moderno',
  },
  {
    value: 'DrugstoresAndPharmacies',
    label: 'Drogueria & Farmacia',
  },
  {
    value: 'Exportations',
    label: 'Exportanción',
  },
];

export const PAYMENT_STATUS = [
  {
    value: 'in_payment',
    label: 'En proceso de pago',
  },
  {
    value: 'paid',
    label: 'Pagado',
    color: 'success',
  },
  {
    value: 'not_paid',
    label: 'No pagado',
    color: 'warning',
  },
  {
    value: 'partial',
    label: 'Pago parcial',
    color: 'info',
  },
  {
    value: 'reversed',
    label: 'Reversado',
    color: 'error',
  },
  {
    value: 'invoicing_legacy',
    label: 'Facturación heredada',
    color: 'error',
  },
];

export const allowedPaymentStatus = ['in_payment', 'partial', 'not_paid'];

export const TypesNotifications = {
  accounting: 'Facturación',
  corrective: 'Notas Créditos',
  inventory: 'Inventario',
};
export const Errors = {
  errorRequets: 'Ocurrió un error. Intente de nuevo.',
  error: 'No se pudo establecer conexión con el servidor. Intente de nuevo.',
};

export const Types = [
  {
    id: '',
    label: '',
  },
  {
    id: 'CC',
    label: 'Cédula de ciudadanía',
  },
  { id: 'CC', label: 'NIT' },
  { id: 'CE', label: 'Cédula de extranjería' },
  { id: 'TI', label: 'Tarjeta de identidad' },
  { id: 'PP', label: 'Pasaporte' },
  { id: 'DE', label: 'Documento de identificación extranjero' },
];

export const TextField = styled(field)({
  '& label.Mui-focused': {
    color: '#25c16a',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#25c16a',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#25c16a',
    },
    '&:hover fieldset': {
      borderColor: '#25c16a',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#25c16a',
    },
  },
});

export const Select = styled(field)({
  '& label.Mui-focused': {
    color: '#25c16a',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#25c16a',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#25c16a',
    },
    '&:hover fieldset': {
      borderColor: '#25c16a',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#25c16a',
    },
  },
});
