import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like Purchases_Request -> PURCHASES_REQUEST
 */
export const { Types, Creators } = createActions({
  getStocks: null,
  stocksSuccess: ['data'],
  changeRowsPerPage: ['PerPage'],
  changePage: ['page'],
  putUpdateStatusProduct: ['payload'],
  putUpdateStatusProductSuccess: ['data'],
  getPickingList: null,
  pickingListSuccess: ['data'],
  getProductsFulfillment: null,
  productsFulfillmentSuccess: ['data'],
  getContactsFulfillment: null,
  constactsFulfillmentSuccess: ['data'],
  createContactFulfillment: ['payload'],
  createContactFulfillmentSuccess: ['data'],
  createDeliveryContact: ['payload'],
  createDeliveryContactSuccess: ['data'],
  createOrderDelivery: ['payload'],
  createOrderDeliverySuccess: ['data'],
  createOrderPicking: ['payload'],
  createOrderPickingSuccess: ['data'],
  resetCreateOrderDelivery: null,
  updateContactAddrees: ['payload'],
});
