import * as Yup from 'yup';

const phoneRegExp = /^\+57\d{10}$/;

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Correo electrónico invalido').required('Correo electrónico requerido'),
  name: Yup.string().required('Nombres y Apellidos requerido'),
  name_contact: Yup.string().required('Nombre de contacto requerido'),
  state_id: Yup.string().required('Departamento es requerido'),
  city_id: Yup.string().required('Ciudad es requerido'),
  document: Yup.string().required('Documento es requerido'),
  document_type: Yup.string().required('Tipo de documento es requerido'),
  phone: Yup.string()
    .required('Teléfono es requerido')
    .matches(phoneRegExp, 'Teléfono debe estar en formato internacional: +570000000000'),
  address: Yup.string().required('Dirección es requerido'),
});

export const formikConf = {
  validateOnChange: true,
  validateOnBlur: true,
  initialValues: {
    phone: '',
    email: '',
    name_contact: '',
    address: '',
    name: '',
    state_id: '',
    city_id: '',
    document: '',
    document_type: '',
  },
  validationSchema,
};
