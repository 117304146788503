import React from 'react';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

export const Download = ({ data }) => {
  const setData = [];
  data.map((items) =>
    items.lots.map((row) => {
      return setData.push({
        name: items.name,
        units: items.product_qty || row?.availableQty,
        lote: row.name,
        days: row.lifeTime,
        date: row.expirationDate,
        vuPercent: row.vuPercent,
      });
    }),
  );
  return (
    <ExcelFile
      filename="Tu_inventario_superfuds"
      element={
        <Button variant="contained" className="btn btn-primary" endIcon={<FileDownloadIcon />}>
          Descargar
        </Button>
      }
    >
      <ExcelSheet data={setData} name="inventario">
        <ExcelColumn label="Nombre del producto" value="name" />
        <ExcelColumn label="Unidades disponibles" value="units" />
        <ExcelColumn label="Lote" value="lote" />
        <ExcelColumn label="Días de inventario" value="days" />
        <ExcelColumn label="% Vida Útil" value="vuPercent" />
        <ExcelColumn label="Fecha Vencimiento" value="date" />
      </ExcelSheet>
    </ExcelFile>
  );
};

Download.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
