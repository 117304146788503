import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, Box, Button, Divider, Grid, Typography } from '@mui/material';
import { Chart } from '@superfuds/sf-charts';

import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ModalLayout } from '@components/index';

import { formatCurrency } from '@utils';
import { TextField, optionsChartSallIn } from '@constants/index';
import './style.scss';

export const ModalGraphic = ({
  open,
  handleClose,
  listCustomers,
  getFrequencyCustomer,
  listProduct,
  frequency,
  getFrequencyCustomerReset,
  getProductsClients,
}) => {
  const [client, setClient] = useState(null);
  const [product, setProduct] = useState({});
  return (
    <>
      <ModalLayout
        open={open}
        handleClose={() => {
          handleClose();
          setClient({});
          setProduct({});
        }}
        fullWidth
        maxWidth="lg"
      >
        <Grid item container>
          <Grid item xs={12} className="pt-1 pb-1 text-center">
            <Typography variant="h5">
              <strong>Resumen de los Últimos Seis Meses </strong>
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            sx={{ mt: 7 }}
            justifyContent="center"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={4} className="text-center" alignItems="center">
              <Autocomplete
                id="AutocompleteClient"
                autoHighlight
                options={listCustomers?.list ?? []}
                value={client}
                openOnFocus
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, state) => {
                  const isSelected = state?.inputValue === option?.label;
                  return (
                    <Box
                      component="li"
                      sx={{
                        color: isSelected ? '#25c16a' : '',
                      }}
                      {...props}
                    >
                      {option?.label}
                    </Box>
                  );
                }}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setClient(newValue);
                    getFrequencyCustomerReset();
                    getProductsClients(newValue?.id);
                    setProduct({});
                  } else {
                    setProduct({});
                    getFrequencyCustomerReset();
                  }
                }}
                getOptionLabel={(option) => option.label ?? ''}
                renderInput={(params) => <TextField {...params} label="Selecciona Cliente" />}
              />
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Autocomplete
                id="AutocompletProduct"
                autoHighlight
                className="mt-2 mb-2"
                options={listProduct ?? []}
                value={product}
                disabled={client === null}
                openOnFocus
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, state) => {
                  const isSelected = state?.inputValue === option?.name;
                  return (
                    <Box
                      component="li"
                      sx={{
                        color: isSelected ? '#25c16a' : '',
                      }}
                      {...props}
                    >
                      {option?.name}
                    </Box>
                  );
                }}
                onChange={(event, newValue) => {
                  setProduct(newValue);
                  getFrequencyCustomer({
                    clientId: client?.id,
                    productId: newValue?.id,
                  });
                }}
                getOptionLabel={(option) => option?.name ?? ''}
                renderInput={(params) => <TextField {...params} label="Selecciona Producto" />}
              />
              <br />
              {frequency?.list?.total_units > 0 && (
                <>
                  <Typography variant="body2" className="pb-2">
                    <strong>Resumen de los Últimos Seis Meses </strong>
                  </Typography>
                  <Typography variant="body2" color="success" className="text-left">
                    Total Unidades Vendidas{' '}
                    <span style={{ color: '#25c16a' }}>{frequency?.list?.total_units || 0}</span>
                  </Typography>
                </>
              )}
              {frequency?.list?.total_amount > 0 && (
                <Typography variant="body2" className="text-left">
                  Total Ventas{' '}
                  <span style={{ color: '#25c16a' }}>
                    {formatCurrency(frequency?.list?.total_amount || 0)}
                  </span>
                </Typography>
              )}
            </Grid>
            <Grid item xs={8}>
              <Grid container xs={12} justifyContent="end">
                <Grid item xs={4} className="text-right">
                  <ExcelFile
                    filename="Frecuencia_por_Producto"
                    element={
                      <Button
                        variant="contained"
                        className="btn btn-primary"
                        endIcon={<FileDownloadIcon />}
                      >
                        Descargar
                      </Button>
                    }
                  >
                    <ExcelSheet
                      data={frequency?.list?.customer_summary?.map((item) => {
                        return {
                          ...item,
                          name: product?.name,
                        };
                      })}
                      name="Productos Frecuencia por Cliente"
                    >
                      <ExcelColumn label="Producto" value="name" />
                      <ExcelColumn label="Mes" value="date" />
                      <ExcelColumn label="Ordenes de Ventas" value="orders" />
                      <ExcelColumn label="Unidades Vendidas" value="units" />
                      <ExcelColumn label="Total Ventas $" value="amount" />
                    </ExcelSheet>
                  </ExcelFile>
                </Grid>
              </Grid>
              {frequency?.list?.customer_summary &&
                frequency?.list?.customer_summary?.length > 0 ? (
                <Chart
                  data={frequency?.list?.customer_summary}
                  options={optionsChartSallIn(frequency?.list?.customer_summary)}
                />
              ) : (
                <Grid item xs={12} className="card_height container-message">
                  <h3 className="sf-title-secondary">No hay Resultados</h3>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </ModalLayout>
    </>
  );
};
ModalGraphic.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  getFrequencyCustomer: PropTypes.func.isRequired,
  listCustomers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  listProduct: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  frequency: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getFrequencyCustomerReset: PropTypes.func.isRequired,
  getProductsClients: PropTypes.func.isRequired,
};
