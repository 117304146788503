/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as XLSX from 'exceljs';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Button, Divider, Grid, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { TextField } from '@constants/index';
import { CustomTable, ModalLayout } from '@components/index';

import './style.scss';

const transformedData = (data) =>
  data?.slice(1)?.map((item) => {
    const name = item[0]?.trim();
    const document_type = item[1]?.trim() || 'CC';
    const document = `${item[2]}`;
    const verification_digit = 0;
    const country_id = 49;
    const name_contact = item[3]?.trim();
    const email = item[4]?.text?.trim() || item[4]?.trim();
    const phone = `+57${item[5]}`;
    const state_id = item[6];
    const city_id = item[7];
    const address = item[8]?.trim();
    const is_company = false;

    if (name && document && name_contact && email && phone && state_id && city_id && address) {
      return {
        name,
        document_type,
        document,
        verification_digit,
        country_id,
        name_contact,
        email,
        phone,
        state_id,
        city_id,
        address,
        is_company,
      };
    }

    return false;
  });

export const AddCustomerImport = ({ open, handleClose, send }) => {
  const [excelData, setExcelData] = useState([]);
  const [upload, setUpload] = useState(false);

  const handleFileChange = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      const data = [];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const workbook = new XLSX.Workbook();
        await workbook?.xlsx.load(arrayBuffer);
        const worksheet = workbook.getWorksheet(1);
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          const rowData = [];
          row.eachCell({ includeEmpty: true }, (cell) => {
            rowData.push(cell.value || '');
          });
          data.push(rowData);
        });
        const filteredData = transformedData(data)?.filter(Boolean);
        setExcelData(filteredData ?? []);
        setUpload(true);
      };
      reader.readAsArrayBuffer(file);
    }
  };
  return (
    <ModalLayout
      open={open}
      handleClose={() => {
        setExcelData([]);
        handleClose();
      }}
      maxWidth="md"
    >
      <Grid item xs={12} className="pb-3 pt-3 text-center">
        <Typography variant="caption" className="sf-title pt-0 pb- ">
          Registros
        </Typography>
      </Grid>
      <Divider />
      <Grid item container>
        <TextField
          onChange={handleFileChange}
          inputProps={{
            accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
          }}
          label="Subir Excel"
          placeholder="Subir Excel"
          fullWidth
          type="file"
          name="name_contact"
          variant="outlined"
        />
      </Grid>
      {upload && (
        <Grid item container className="mt-4">
          {excelData?.length > 0 ? (
            <CustomTable
              titles={
                [
                  'Nombres y Apellidos',
                  'Tipo Documento',
                  'Documento',
                  'Nombre de Contacto',
                  'Correo',
                  'Teléfono',
                  'stateId',
                  'cityId',
                  'Dirección',
                ] ?? []
              }
              data={excelData ?? []}
            >
              <TableBody>
                {excelData?.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell align="left">{row?.name}</TableCell>
                    <TableCell align="left">{row?.document_type}</TableCell>
                    <TableCell align="left">{row?.document}</TableCell>
                    <TableCell align="left">{row?.name_contact}</TableCell>
                    <TableCell align="left">{row?.email}</TableCell>
                    <TableCell align="left">{row?.phone}</TableCell>
                    <TableCell align="left">{row?.state_id}</TableCell>
                    <TableCell align="left">{row?.city_id}</TableCell>
                    <TableCell align="left">{row?.address}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </CustomTable>
          ) : (
            <Grid item xs={12} className="pb-1">
              <h5 className="text-center">Sin registros </h5>
            </Grid>
          )}
        </Grid>
      )}
      <Grid item container sx={{ mt: 3, mb: 3 }}>
        <Grid item xs={6} className="text-left">
          <Button
            style={{
              textTransform: 'initial',
              background: 'transparent',
              borderColor: '#25c16a',
              color: '#25c16a',
            }}
            color="primary"
            variant="outlined"
            onClick={handleClose}
            endIcon={<CloseIcon />}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6} className="text-right">
          <Button
            variant="contained"
            className="btn btn-primary"
            type="button"
            onClick={() => {
              send(excelData);
              handleClose();
              setUpload(false);
              setExcelData([]);
            }}
            disabled={!excelData || excelData.length <= 0}
            style={{
              textTransform: 'initial',
            }}
            endIcon={<SendIcon />}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </ModalLayout>
  );
};

AddCustomerImport.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
};
