import { put, call } from 'redux-saga/effects';

import { PurchaseService } from '@services/purchase.service';
import { Creators as PurchaseCreators } from '@reducers/Purchases';
import { transformServiceBesSellingProduct } from '@transforms/ApiToServiceBestSellingProduct.transform';
import { transformServiceLevel } from '@transforms/ApiToServiceLevel.transform';
import { transformServiceSummary } from '@transforms/ApiToServiceSummarytransform';
import { transformServiceFrequency } from '@transforms/ApiToServiceFrequencytransform';
import { transformServiceAov } from '@transforms/ApiToServiceAovtransform';
import { Errors } from '@constants/index';

export function* getDelivered() {
  try {
    const response = yield PurchaseService.getDelivered();
    const data = response.orders.map((item) => {
      return {
        ...item,
        name: `demo ${item.id}`,
      };
    });
    yield put(
      PurchaseCreators.deliveredSuccess({
        orders: data,
      }),
    );
  } catch (error) { }
}
export function* getTotalSold({ months, date }) {
  try {
    const response = yield PurchaseService.totalSold(months, date);
    yield getDelivered();
    const items = transformServiceSummary(response);
    yield put(PurchaseCreators.totalSoldSuccess(items));
  } catch (error) { }
}

export function* getSummary({ months }) {
  try {
    const response = yield PurchaseService.getSummary(months);
    const items = transformServiceSummary(response);
    yield put(PurchaseCreators.summarySuccess(items));
  } catch (error) { }
}

export function* getTopProduct({ months, date }) {
  try {
    const { product: response } = yield PurchaseService.topProductSummary(months, date);
    const product = response === 'None' ? '' : transformServiceBesSellingProduct(response);
    yield put(PurchaseCreators.topProductSuccess(product));
  } catch (error) { }
}

export function* serviceLevel({ months, date }) {
  try {
    const data = yield PurchaseService.getServiceLevel(months, date);
    const items = transformServiceLevel(data);
    yield put(PurchaseCreators.serviceLevelSuccess(items));
  } catch (error) { }
}

export function* averageTicket() {
  try {
    const data = yield PurchaseService.getAverageTicket();
    const items = transformServiceAov(data);
    yield put(PurchaseCreators.averageTicketSuccess(items));
  } catch (error) { }
}

export function* getfrequency() {
  try {
    const data = yield PurchaseService.getFrequency();
    if (!data) {
      throw new Error(Errors?.error);
    }
    const items = transformServiceFrequency(data);
    yield put(PurchaseCreators.frequencySuccess(items));
  } catch (error) {
    console.log(error?.getStatus());
    // yield put(PurchaseCreators.frequencySuccess({}));
  }
}

export function* filterTopPurchases({ item, date }) {
  try {
    yield call(getTotalSold, { months: item, date });
    yield call(serviceLevel, { months: item, date });
    yield call(getTopProduct, { months: item, date });
  } catch (error) { }
}

export function* getRankingProductPurchase({ item, date }) {
  try {
    const response = yield PurchaseService.getRankingProductPurchase(item, date);
    const data = response?.products.map((item) => {
      return {
        ...item,
        product_name: `demo ${item.product_id}`,
      };
    });
    yield put(
      PurchaseCreators.rankingProductSuccess({
        count_products: response.count_products,
        products: data.slice(0, 3),
      }),
    );
  } catch (error) { }
}

export function* getOrderList() {
  try {
    const { purchase_ordes } = yield PurchaseService.getOrderList();
    const data = purchase_ordes.map((item) => {
      return {
        ...item,
        name: `demo ${item.id}`,
      };
    });
    yield put(PurchaseCreators.purchaseOrdersSuccess(data.slice(0, 3)));
  } catch (error) { }
}

export function* getOrderLines({ orderId }) {
  try {
    const { purchase_lines: purchaseLines } = yield PurchaseService.getOrderLines({ orderId });
    const data = purchaseLines.map((item) => {
      return {
        ...item,
        product_name: `demo ${item.id}`,
      };
    });
    yield put(PurchaseCreators.getOrderLinesSuccess(data));
  } catch (error) { }
}

export function* getOrderDownload({ id }) {
  try {
    const response = yield PurchaseService.getOrderDownload(id);
    console.log(response);
    /*     yield put(PurchaseCreators.purchaseOrdersSuccess(purchase_ordes)); */
  } catch (error) { }
}
