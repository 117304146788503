import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from '@superfuds/saori';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ClearIcon from '@mui/icons-material/Clear';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import GroupIcon from '@mui/icons-material/Group';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import { CustomTable, Spinner } from '@components/index';
import { Creators as Stocks } from '@reducers/Stocks';
import { TextField } from '@constants/index';

import './style.scss';

const titles = [
  'Orden',
  'Cliente',
  'Dirección',
  'Documento de Origen',
  'Fecha de Entrega Pedido',
  'Estado de Entrega',
  'Estado Picking',
  'Guia de Envío',
  'Rastrear',
  'Tipo de Operación',
];
const options = [
  { label: 'Selecciona Tipo de operación', value: '' },
  { label: 'Ventas', value: 'Existencias' },
  {
    label: 'Recibo',
    value: 'Vendors',
  },
];
const ContainerFulfillment = ({
  getPickingList,
  data,
  loading,
  changeRowsPerPage,
  changePage,
  pagination,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [itemFilter, setItemfilter] = useState('');

  const { page, rowsPerPage } = pagination;
  useEffect(() => {
    getPickingList();
  }, [getPickingList]);

  const history = useHistory();

  const filteredData = data?.filter((row) => {
    const matchesSelectedClient = !itemFilter || row.locationOrigin === itemFilter;
    const rowValues = Object.values(row).join(' ').toLowerCase();
    const searchTermLower = searchTerm?.toLowerCase();
    const filter = rowValues.includes(searchTermLower);
    return filter && matchesSelectedClient;
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value?.trim());
  };

  return (
    <Grid container item justifyContent="flex-start" id="sf-fulfillment" className="sf-fulfillment">
      <Grid item xs={12} className="pb-3 pt-3">
        <h2 className="sf-title">Fulfillment Superfuds</h2>
      </Grid>
      <Stack direction="row" spacing={2} className={loading ? 'pb-3' : ''}>
        <Button
          variant="outlined"
          onClick={() => history.push('/fulfillment/clientes')}
          startIcon={<GroupIcon />}
          className="btn btn-primary btn-option"
        >
          Listado de Clientes
        </Button>
        <Button
          onClick={() => history.push('/fulfillment/inventario')}
          variant="outlined"
          startIcon={<ViewComfyAltIcon />}
          className="btn btn-primary btn-option"
        >
          Inventario
        </Button>
        <Button
          onClick={() => history.push('/fulfillment/nueva-orden')}
          variant="outlined"
          startIcon={<MonetizationOnIcon />}
          className="btn btn-primary btn-option"
        >
          Nueva Orden Venta
        </Button>
        <Button
          onClick={() => history.push('/fulfillment/recepcion')}
          variant="outlined"
          startIcon={<StorefrontIcon />}
          className="btn btn-primary btn-option"
        >
          Nueva Orden de Recepción
        </Button>
      </Stack>
      {data && data?.length > 0 && (
        <Grid item xs={12} sx={{ mt: 0, mb: 2 }} className="text-right">
          <ExcelFile
            filename="Ordenes de Entregas Fulfillment"
            element={
              <Button
                variant="contained"
                className="btn btn-primary"
                endIcon={<FileDownloadIcon />}
              >
                Descargar
              </Button>
            }
          >
            <ExcelSheet data={data} name="Ordenes de Entregas Fulfillment">
              <ExcelColumn label="Name" value="name" />
              <ExcelColumn label="Cliente" value="contact" />
              <ExcelColumn label="Dirección" value="direction" />
              <ExcelColumn label="Documento de Origen" value="documentOrigin" />
              <ExcelColumn label="Fecha de Entrega Pedido" value="scheduledDate" />
              <ExcelColumn label="Estado de Entrega" value="deliveryState" />
              <ExcelColumn label="Estado Picking" value="statePick" />
              <ExcelColumn label="Guía" value="trackingGuide" />
              <ExcelColumn label="Operador logístico" value="logisticOperator" />
            </ExcelSheet>
          </ExcelFile>
        </Grid>
      )}
      <Grid container className="card">
        <>
          {loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                padding: '20px 10px',
              }}
            >
              <Spinner />
            </Grid>
          ) : (
            <Grid item xs={12} sx={{ mt: 0, mb: 2, zIndex: 3 }} className="filter text-right">
              <Typography variant="h6" className="text-center pt-2 pb-2">
                Listado de Operaciones
              </Typography>
              <Grid
                item
                xs={6}
                container
                justifyContent="center"
                alignItems="center"
                gap={2}
                sx={{ mt: 1, mb: 0, zIndex: 3, paddingLeft: 2, paddingRight: 2 }}
                className="text-left"
              >
                <Grid item container justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item xs={7}>
                    <TextField
                      size="medium"
                      fullWidth
                      label="Buscar"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Dropdown
                      reanloy
                      className="zIndex"
                      classNamePrefix="dropdown"
                      options={options}
                      onChange={(item) => {
                        setItemfilter(item.value);
                      }}
                      placeholder="Selecciona Tipo de operación"
                    />
                  </Grid>
                </Grid>
                {searchTerm !== '' && (
                  <Grid item xs={2}>
                    <IconButton
                      title="Detalles de la Factura"
                      className="button-icon-table action-click-payment"
                      aria-label="expand row"
                      size="small"
                      style={{
                        marginLeft: '20px',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSearchTerm('');
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              {filteredData && filteredData?.length > 0 ? (
                <CustomTable
                  titles={titles}
                  data={filteredData}
                  pagination={pagination}
                  active
                  onPageChange={(event, page) => changePage(page)}
                  onRowsPerPageChange={(event) => changeRowsPerPage(event.target.value)}
                >
                  <TableBody>
                    {filteredData
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      ?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{item.name}</TableCell>
                          <TableCell align="center">{item.contact}</TableCell>
                          <TableCell align="center">
                            {item?.direction !== 'False / False' ? item.direction : ''}
                          </TableCell>
                          <TableCell align="center">{item.documentOrigin}</TableCell>
                          <TableCell align="center">{item.scheduledDate}</TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                bgcolor: item.colorDeliveryState,
                                padding: '4px',
                                borderRadius: 50,
                                fontSize: '.8rem',
                                color: 'white',
                              }}
                            >
                              {item.deliveryState}
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                bgcolor: item.colorState,
                                padding: '4px',
                                borderRadius: 50,
                                fontSize: '.8rem',
                                color: 'white',
                              }}
                            >
                              {item.statePick}
                            </Box>
                          </TableCell>
                          <TableCell align="center" style={{ width: '200px' }}>
                            {item?.trackingGuide !== false && (
                              <>
                                <Typography variant="overline" display="block" gutterBottom>
                                  Guía {item?.trackingGuide}
                                </Typography>
                                <Typography variant="caption" display="block" gutterBottom>
                                  {item?.logisticOperator}
                                </Typography>
                              </>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {item?.trackingGuide !== false && item?.urlTracking !== false && (
                              <Button
                                onClick={() =>
                                  window.open(
                                    `${item?.urlTracking}/g?Numero=${item?.trackingGuide}`,
                                  )
                                }
                                variant="outlined"
                                startIcon={<OpenInNewIcon />}
                                className="btn btn-primary btn-option"
                              >
                                Ir
                              </Button>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {item.locationOrigin === 'Vendors' ? 'Recibo' : 'Ventas'}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </CustomTable>
              ) : (
                <Grid item xs={12} className="pb-1">
                  <h5 className="text-center">Sin registros </h5>
                </Grid>
              )}
            </Grid>
          )}
        </>
      </Grid>
    </Grid>
  );
};

ContainerFulfillment.propTypes = {
  user: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}).isRequired,
  getPickingList: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  changeRowsPerPage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = ({
  stock: {
    pagination,
    pickingList: { loading, list: data },
  },
}) => ({
  data,
  loading,
  pagination,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPickingList: () => dispatch(Stocks.getPickingList()),
    changeRowsPerPage: (item) => dispatch(Stocks.changeRowsPerPage(item)),
    changePage: (item) => dispatch(Stocks.changePage(item)),
  };
};
export const FulfillmentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerFulfillment);
