import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  contact: Yup.string().required('Cliente es requerido'),
  delivery_contact_id: Yup.string().required('Dirección de Entrega es requerido'),
  document_origin: Yup.string().required('Documento de Referencia es requerido'),
  date_delivered: Yup.string().required('Fecha Estimada es requerida'),
  fileData: Yup.mixed()
    .required('Documento requerido')
    .test('fileFormat', 'Sólo PDF', (value) => {
      return value && ['application/pdf'].includes(value.type);
    }),
  products: Yup.array()
    .of(
      Yup.object().shape({
        product_id: Yup.number(),
        qty: Yup.number().typeError('Cantidad es requerido').required('Cantidad es requerido'),
        price: Yup.number().when('qty', {
          is: (qty) => qty !== undefined,
          then: Yup.number().typeError('Precio es requerido').required('Precio es requerido'),
        }),
      }),
    )
    .min(1, 'Agregar al menos un producto es requerido'),

  /* .of(
   Yup.object().shape({
     product_id: Yup.number().required('ID del producto es requerido'),
     qty: Yup.number().required('Cantidad es requerida'),
     price: Yup.number().required('Precio es requerido'),
   }),
 )
   .test('at-least-one-product', 'Agregar al menos un producto es requerido', (value) => {
     return value.length > 0;
   }) */
});

export const formikConf = {
  validateOnChange: true,
  validateOnBlur: true,
  initialValues: {
    contact: '',
    delivery_contact_id: '',
    document_origin: '',
    date_delivered: '',
    products: [],
    fileData: '',
  },
  validationSchema,
};
