/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionsTypes';
/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  eid: '0',
  avatar: '',
  clientType: 1,
  document: '',
  documentType: '',
  email: '',
  name: '',
  phone: '',
  isLoggedIn: false,
  loading: false,
  status: 0,
});
/* ------------- Reducers ------------- */
export const userDataFetchRequest = (state = INITIAL_STATE, { user }) => ({
  loading: true,
});
export const userDataFetchSucceeded = (state = INITIAL_STATE, { user }) => ({
  ...state,
  ...user,
  loading: false,
  isLoggedIn: true,
});

export const reset = (state = INITIAL_STATE) => {
  return {
    ...INITIAL_STATE,
  };
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USER_REQUEST]: userDataFetchRequest,
  [Types.GET_USER_SUCCESS]: userDataFetchSucceeded,
  [Types.RESET]: reset,
});
