import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useHistory } from 'react-router-dom';

import {
  Badge,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Avatar,
  Tooltip,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';

import { SideMenu } from '@components/index';
import { ReactComponent as Leaves } from '@assets/SVGs/leaves.svg';
// import { ReactComponent as Logo } from '@assets/SVGs/logo.svg';
import { formatCurrency } from '@utils';

import './style.scss';
import { TypesNotifications } from '@constants/config.constants';

export const Header = ({ user: { name }, user, handleSignOff, notification, activeSellOut }) => {
  const location = useLocation();
  const history = useHistory();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [open, setOpen] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    window.addEventListener(
      'load',
      () => {
        const Width = window.innerWidth;
        if (Width <= 1025) {
          handleDrawerClose();
        } else {
          handleDrawerOpen();
        }
      },
      false,
    );
    window.addEventListener(
      'resize',
      () => {
        const Width = window.innerWidth;
        if (Width <= 1025) {
          handleDrawerClose();
        } else {
          handleDrawerOpen();
        }
      },
      false,
    );
    if (location.pathname !== '/') {
      // setOpenMenu(false);
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColorFromString = (string) => {
    if (!string) return '';
    let hash = 0;

    for (let i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value?.toString(16)}`.slice(-2);
    }

    return color;
  };

  const stringAvatar = (name) => {
    if (!name) return '';
    return {
      sx: {
        bgcolor: getColorFromString(name),
      },
      children: `${name?.split(' ')[0]?.[0] ?? ''}${name?.split(' ')[1]?.[0] ?? ''}`,
    };
  };

  /*   const onDownload = () => {
      const link = document.createElement('a');
      mixpanel.track('Click_OnDownload_Simulator_Pricing', { ...user });
  
      link.download =
        'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/SimuladorPricingSuperfuds.xlsx';
      link.href =
        'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/SimuladorPricingSuperfuds.xlsx';
      link.click();
    }; */

  const groupedNotifications =
    notification &&
    notification.length > 0 &&
    notification.reduce((groups, text) => {
      if (!groups[text?.type]) {
        groups[text?.type] = [];
      }
      groups[text?.type].push(text);
      return groups;
    }, {});

  return (
    <AppBar
      position="fixed"
      sx={{
        pr: 3,
        pl: 3,
      }}
    >
      <Toolbar disableGutters className="header">
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flexGrow: 0,
            display: { xs: 'flex', md: 'flex', lg: 'flex' },
            width: '100px',
          }}
        >
          <Link to="/">
            <Leaves />
          </Link>
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg: 'flex' } }}>
          <SideMenu user={user} activeSellOut={activeSellOut} />
        </Box>
        {/* <Typography
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            mr: 1,
            display: { xs: 'none', md: 'none', lg: 'flex' },
            justifyContent: 'end',
          }}
          onClick={onDownload}
        >
          <Link to={location.pathname} className="link-simulator-Pricing">
            Simulador Pricing
          </Link>
        </Typography> */}

        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg: 'none' } }}>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={handleSignOff}>
              <Typography textAlign="center">Cerrar sesión</Typography>
            </MenuItem>
          </Menu>
        </Box>
        {/*    <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg: 'none' } }}
        >
          <Link to="/">
            <Leaves />
            <Logo
              style={{
                marginTop: 7,
                marginLeft: 7,
              }}
            />
          </Link>
        </Typography> */}

        <Box sx={{ flexGrow: 0, display: { xs: 'flex' } }}>
          {notification && notification.length > 0 && (
            <IconButton
              size="large"
              aria-label={`show ${notification.length} new notifications`}
              title={`show ${notification.length} new notifications`}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <Badge badgeContent={notification.length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}
          <Tooltip title="Abrir Configuraciones" className="navbar-avatar">
            {name !== '' && (
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Typography> Hola, {name} </Typography>
                <Avatar {...stringAvatar(name)} />
              </IconButton>
            )}
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {/*       <MenuItem onClick={handleCloseUserMenu}>
              <Typography textAlign="center">Perfil</Typography>
            </MenuItem> */}
            <MenuItem onClick={handleSignOff}>
              <Typography textAlign="center">Cerrar sesión</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(!drawerOpen)}
      >
        <Box sx={{ width: 300, paddingTop: '30px' }} role="presentation">
          <Typography
            textAlign="center"
            style={{
              display: 'flex',
            }}
            justifyContent="center"
          >
            <NotificationsIcon color="success" />
            Notificaciones
          </Typography>
          <br />
          <Divider />
          <List>
            {Object.keys(groupedNotifications).map((type, index) => (
              <div key={index}>
                {index !== 0 && <Divider />}
                <Typography
                  className="text-center"
                  style={{
                    color: '#25c16a',
                    padding: '20px 20px',
                  }}
                >
                  {type}
                </Typography>
                <Divider />
                {groupedNotifications[type]?.map((text, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    style={{
                      backgroundColor:
                        text?.type === TypesNotifications?.accounting && 'text.primary',
                    }}
                  >
                    <ListItemButton
                      onClick={() => {
                        if (text?.type === TypesNotifications?.inventory) {
                          history.push('/inventario');
                        }
                        if (text?.type === TypesNotifications?.accounting) {
                          history.push('/facturas');
                        }
                        if (text?.type === TypesNotifications?.corrective) {
                          history.push('/facturas-rectificativas');
                        }
                      }}
                    >
                      <ListItemText
                        primary={
                          (
                            <>
                              <Typography variant="body1">
                                <strong>{text?.name}</strong>
                                <br />
                              </Typography>
                            </>
                          ) ?? ''
                        }
                        secondary={
                          <>
                            {text?.amount_total && (
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                Total {formatCurrency(text?.amount_total) || 0}
                                <br />
                              </Typography>
                            )}
                            {text.message ?? ''}
                          </>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </div>
            ))}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

Header.propTypes = {
  user: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  notification: PropTypes.shape({}).isRequired,
  handleSignOff: PropTypes.func.isRequired,
};
