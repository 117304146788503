import { call } from 'redux-saga/effects';
import { odooApi } from './api';

export const PurchaseService = {
  *totalSold(months, date) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/purchases/summary', {
      months: 1,
      ...date,
    });
  },
  *getSummary(months) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/purchases/summary', {
      months: 3,
    });
  },
  *getRankingProductPurchase(months, date) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/purchases/product_ranking', {
      months: 3,
      ...date,
    });
  },
  *topProductSummary(months, date) {
    return yield call(
      { context: odooApi, fn: odooApi.get },
      '/sascha/purchases/top_product_summary',
      { months: 1, ...date },
    );
  },
  *getServiceLevel(months, date) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/purchases/service_level', {
      months: 1,
      ...date,
    });
  },
  *getAverageTicket() {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/purchases/aov', {});
  },
  *getFrequency() {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/purchases/frequency', {});
  },
  *getDelivered() {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/purchases/delivered', {
      months: 1,
    });
  },
  *getOrderList() {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/purchases/order_list', {});
  },
  *getOrderLines({ orderId }) {
    return yield call({ context: odooApi, fn: odooApi.get }, '/sascha/purchase/order_lines', {
      id: orderId,
    });
  },
  *getOrderDownload(id) {
    return yield call(
      { context: odooApi, fn: odooApi.get },
      '/sascha/purchase/download_documents',
      {
        id,
      },
    );
  },
};
