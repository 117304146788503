import { persistStore } from 'redux-persist';
import { reduxPersistConfig } from '@config/reduxPersistConfig';
import { Creators as StartupActions } from '@redux/reducers/StartUp';
// import { debugConfig } from '@config/debugConfig';

export const updateReducers = (store: Object) => {
  const { reducerVersion } = reduxPersistConfig;
  const startup = () => store.dispatch(StartupActions.startup());
  // Check to ensure latest reducer version
  const localVersion = localStorage.getItem('reducerVersion');
  // eslint-disable-next-line no-console
  /*   console.debug('Reducer', localVersion, reducerVersion); */
  if (localVersion !== reducerVersion) {
    localStorage.clear();
    persistStore(store, null, startup).purge();
    localStorage.setItem('reducerVersion', reducerVersion);
  } else {
    persistStore(store, null, startup);
  }
};
