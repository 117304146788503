import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { connect } from 'react-redux';
import { Creators as Stocks } from '@reducers/Stocks';
import { Creators as Account } from '@reducers/Account';
import { Creators as Sales } from '@reducers/SellIn';

const ContainerAI = ({
  getStocks,
  getAccountBills,
  getBillsCorrective,
  getSellOut,
  filter,
  filterRankingProduct,
  filterChanelMonth,
  getCustomerActivity,
  filterProductSales,
  getListProductSales,
  getCustomersList,
}) => {
  useEffect(() => {
    getStocks();
    getAccountBills();
    getSellOut();

    filter(1);
    filterRankingProduct(
      {
        chanel: '',
        months: 1,
      },
      null,
    );
    filterChanelMonth({
      chanel: '',
      months: 1,
    });
    getCustomerActivity({
      chanel: '',
      months: 1,
    });
    filterProductSales({
      chanel: '',
      months: 1,
    });
    getListProductSales();
    getCustomersList();
  }, [
    getAccountBills,
    getStocks,
    getBillsCorrective,
    getSellOut,
    filter,
    filterRankingProduct,
    filterChanelMonth,
    getCustomerActivity,
    filterProductSales,
    getListProductSales,
    getCustomersList,
  ]);

  return (
    <Container sx={{ mt: 4, mb: 1, padding: 0 }} maxWidth="xl">
      <iframe
        id="microfrontend"
        src="https://chatbot-ai-sascha.vercel.app" // Reemplaza con la ruta relativa de tu aplicación
        title="Chat" // Atributo title para accesibilidad
        allowFullScreen // Permitir pantalla completa
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms" // Atributos de seguridad
        style={{ width: '100%', height: '90vh', border: 'none' }} // Estilos CSS
      />
    </Container>
  );
};

const mapStateToProps = () => { };

const mapDispatchToProps = (dispatch) => {
  return {
    getBillsCorrective: () => dispatch(Account.getAccountBillsCorrective()),
    getStocks: () => dispatch(Stocks.getStocks()),
    getAccountBills: () => dispatch(Account.getAccountBills()),
    getSellOut: () => dispatch(Sales.getSellOut()),
    filter: (item, date) => dispatch(Sales.filterTopSales(item, date)),
    filterRankingProduct: (item, date) => dispatch(Sales.filterRankingProductSales(item, date)),
    filterChanelMonth: (filter) => dispatch(Sales.filterChanelMonth(filter)),
    filterProductSales: (item, date) => dispatch(Sales.filterProductSales(item, date)),
    getCustomerActivity: (filter) => dispatch(Sales.filterCustomerActivity(filter)),
    changeRowsPerPage: (item) => dispatch(Sales.changeRowsPerPage(item)),
    getListProductSales: () => dispatch(Sales.getListProductSales()),
    getProductDetails: (id) => dispatch(Sales.getProductDetails(id)),
    changePage: (item) => dispatch(Sales.changePage(item)),
    getCustomersList: () => dispatch(Sales.getCustomersList()),
    getFrequencyCustomer: (payload) => dispatch(Sales.getFrequencyCustomer(payload)),
    getProductsClients: (id) => dispatch(Sales.getProductsClients(id)),
    getFrequencyCustomerReset: () => dispatch(Sales.getFrequencyCustomerReset()),
  };
};
export const ChatContainer = connect(mapStateToProps, mapDispatchToProps)(ContainerAI);
