import moment from 'moment';
import 'moment/locale/es';

import { formatCurrency, capitalize, formatDate } from '@utils';

const regex = /^\d{4}-(0[1-9]|1[0-2])$/;
// const currentYear = new Date().getFullYear();

export const transformServiceSummary = (response) => ({
  summary: response.summary.slice(0, 3)?.map((rows) => ({
    date: rows.date,
    name: regex.test(rows?.date)
      ? capitalize(moment(rows?.date, 'YYYY-MM').locale('es').format('MMMM YYYY'))
      : capitalize(rows?.date),
    units: rows.units.total,
    ordersQty: rows.orders.quantity,
    amount: formatCurrency(rows.amount.total),
    total: rows.amount.total,
    value: rows.amount.total,
    newDate: regex.test(rows?.date) ? rows?.date : formatDate(rows?.date),
    growthInPercentage: `${parseInt(rows?.units?.growth_in_percentage * 100, 10)}%` || 0,
    percentage: parseInt(rows?.units?.growth_in_percentage * 100, 10) || 0,
  })),
  /*  .sort((a, b) => {
     const yearA = new Date(a.date).getFullYear();
     const monthA = new Date(a.date).getMonth();

     const yearB = new Date(b.date).getFullYear();
     const monthB = new Date(b.date).getMonth();
     if (yearA === currentYear && yearB === currentYear) {
       return monthB - monthA;
     }

     return yearB - yearA;
   }), */
  totalAmount: response.total_amount,
  totalUnits: response.total_units,
});
