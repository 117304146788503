import { put } from 'redux-saga/effects';

import { AccountService } from '@services/accounting.service';
import { Creators as AccountCreators } from '@reducers/Account';
import { Creators as NotificationCreators } from '@reducers/Notifications';
import { allowedPaymentStatus, TypesNotifications } from '@constants/index';

export function* getAccountBills() {
  try {
    const data = yield AccountService.getAccount();

    const bills = data.bills?.slice(0, 3).map((item) => {
      return {
        ...item,
        name: `demo ${item.id}`,
      };
    });

    if (!data) {
      throw new Error('No se pudo establecer conexión con el servidor. Intente de nuevo.');
    }
    const notifictionsData = bills.filter(
      (item) => allowedPaymentStatus.includes(item.payment_status) && item.expiration_date < 0,
    );
    const filteredItems = bills.filter((item) => item.expiration_date !== 0);
    filteredItems.sort((a, b) => {
      const expirationDateA = Math.abs(b.expiration_date);
      const expirationDateB = Math.abs(a.expiration_date);

      if (expirationDateA < expirationDateB) {
        return -1;
      }
      if (expirationDateA > expirationDateB) {
        return 1;
      }
      return 0;
    });
    const arrayNotifications = notifictionsData?.map((row) => ({
      ...row,
      message: `Factura Pendiente de pago ${row?.name}`,
      type: TypesNotifications?.accounting ?? '',
    }));

    yield put(NotificationCreators.pushNotifications(arrayNotifications));

    yield put(
      AccountCreators.accountBillsSuccess({
        ...data,
        bills: filteredItems,
      }),
    );
  } catch (error) {
    yield put(AccountCreators.accountBillsSuccess([]));
  }
}

export function* paymentBill({ id }) {
  try {
    const {
      data: { message },
    } = yield AccountService.paymentBill(id);
    if (!message) {
      throw new Error('No se pudo establecer conexión con el servidor. Intente de nuevo.');
    }
    if (message) {
      const newString = message?.replace('Bill', '')?.replace('generate Email successfully', '');
      const newMenssage = `Nuestro equipo de finanzas revisará tu solicitud de adelanto de pago para la factura ${newString
        ?.split(' ')
        ?.join('')}. Si la solicitud es aprobada, nos pondremos en contacto contigo.`;
      yield put(AccountCreators.prepaymentSuccess(newMenssage));
    }
  } catch (error) {
    yield put(AccountCreators.prepaymentSuccess(''));
  }
}

export function* getAccountBillsCorrective() {
  try {
    const data = yield AccountService.getAccountCorrective();
    const credit_notes = data.credit_notes.slice(0, 3).map((item) => {
      return {
        ...item,
        name: `demo ${item.id}`,
        ref: `demo ${item.id}`,
        payment_ref: `demo ${item.id}`,
      };
    });

    if (!data) {
      throw new Error('No se pudo establecer conexión con el servidor. Intente de nuevo.');
    }
    const notifictionsData = credit_notes.filter(
      (item) => allowedPaymentStatus.includes(item.payment_status) && item.expiration_date < 0,
    );

    const arrayNotifications = notifictionsData?.map((row) => ({
      ...row,
      message: `Factura Pendiente de pago ${row?.name}`,
      type: TypesNotifications?.accounting ?? '',
    }));
    yield put(NotificationCreators.pushNotifications(arrayNotifications));

    if (data && credit_notes && credit_notes.length > 0) {
      yield put(AccountCreators.accountBillsCorrectiveSuccess(credit_notes));
    } else {
      yield put(AccountCreators.accountBillsCorrectiveSuccess([]));
    }
  } catch (error) { }
}

export function* getAccountLines({ id }) {
  try {
    const { invoice_lines: response } = yield AccountService.getAccountLines({ id });
    const data = response.slice(0, 3).map((item) => {
      return {
        ...item,
        product_name: `demo ${item.id}`,
      };
    });
    yield put(AccountCreators.getAccountLinesSuccess(data));
  } catch (error) { }
}
