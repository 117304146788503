/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as XLSX from 'exceljs';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Button, Divider, Grid, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { TextField } from '@constants/index';
import { CustomTable, ModalLayout } from '@components/index';

const transformedData = (data) =>
  data?.slice(1)?.map((item) => {
    const barcode = item[0];
    const qty = item[1];
    const price = item[2];

    if (barcode && qty && price) {
      return {
        barcode,
        qty,
        price,
      };
    }

    return false;
  });

export const AddOrderImport = ({ open, handleClose, send, products }) => {
  const [excelData, setExcelData] = useState([]);
  const [upload, setUpload] = useState(false);
  const handleFileChange = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      const data = [];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const workbook = new XLSX.Workbook();
        await workbook?.xlsx.load(arrayBuffer);
        const worksheet = workbook.getWorksheet(1);
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          const rowData = [];
          row.eachCell({ includeEmpty: true }, (cell) => {
            rowData.push(cell.value || '');
          });
          data.push(rowData);
        });
        const filteredData = transformedData(data)?.filter(Boolean);

        const res = products?.flatMap((x) => {
          const match = filteredData.find(({ barcode }) => {
            return parseInt(x.product_barcode, 10) === barcode;
          });
          return match
            ? [
              {
                product_id: x.id,
                product: x.name,
                qty: match.qty,
                barcode: match.barcode,
                price: match.price,
              },
            ]
            : [];
        });

        setExcelData(res ?? []);
        setUpload(true);
      };
      reader.readAsArrayBuffer(file);
    }
  };
  return (
    <ModalLayout
      open={open}
      handleClose={() => {
        setExcelData([]);
        handleClose();
      }}
      maxWidth="md"
    >
      <Grid item xs={12} className="pb-3 pt-3 text-center">
        <Typography variant="caption" className="sf-title pt-0 pb- ">
          Registros
        </Typography>
      </Grid>
      <Divider />
      <Grid item container>
        <TextField
          onChange={handleFileChange}
          inputProps={{
            accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
          }}
          label="Subir Excel"
          placeholder="Subir Excel"
          fullWidth
          type="file"
          name="name_contact"
          variant="outlined"
        />
      </Grid>
      {upload && (
        <Grid item container className="mt-4">
          {excelData?.length > 0 ? (
            <CustomTable
              titles={['Producto', 'EAN', 'Cantidad', 'Precio Unitario'] ?? []}
              data={excelData ?? []}
            >
              <TableBody>
                {excelData?.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell align="center">{row?.product}</TableCell>
                    <TableCell align="center">{row?.barcode}</TableCell>
                    <TableCell align="center">{row?.qty}</TableCell>
                    <TableCell align="center">{row?.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </CustomTable>
          ) : (
            <Grid item xs={12} className="pb-1">
              <h5 className="text-center">Sin registros </h5>
            </Grid>
          )}
        </Grid>
      )}
      <Grid item container sx={{ mt: 3, mb: 3 }}>
        <Grid item xs={6} className="text-left">
          <Button
            style={{
              textTransform: 'initial',
              background: 'transparent',
              borderColor: '#25c16a',
              color: '#25c16a',
            }}
            color="primary"
            variant="outlined"
            onClick={handleClose}
            endIcon={<CloseIcon />}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6} className="text-right">
          <Button
            variant="contained"
            className="btn btn-primary"
            type="button"
            onClick={() => {
              const data = excelData?.map((row) => ({
                product_id: row.product_id,
                qty: row.qty,
                price: row.price,
              }));
              send(data);
              handleClose();
              setUpload(false);
              setExcelData([]);
            }}
            disabled={!excelData || excelData.length <= 0}
            style={{
              textTransform: 'initial',
            }}
            endIcon={<SendIcon />}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </ModalLayout>
  );
};

AddOrderImport.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
  products: PropTypes.shape({}).isRequired,
};
