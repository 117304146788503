import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like Account_Request -> ACCOUNT_REQUEST
 */
export const { Types, Creators } = createActions({
  getAccountBills: null,
  accountBillsSuccess: ['data'],
  changeRowsPerPage: ['PerPage'],
  changePage: ['page'],
  paymentBill: ['id'],
  getAccountBillsCorrective: null,
  accountBillsCorrectiveSuccess: ['data'],
  prepaymentSuccess: ['data'],
  prepaymentReset: null,
  getAccountLines: ['id'],
  getAccountLinesSuccess: ['data'],
});
