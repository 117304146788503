import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import {
  Autocomplete,
  Button,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import MapIcon from '@mui/icons-material/Map';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { TextField } from '@constants/index';
import { ModalLayout, Spinner } from '@components/index';
import { formikConf } from './delivery.formik';

import './style.scss';

export const AddCustomerDelivery = ({
  loading,
  addDelivery,
  places,
  open,
  handleClose,
  customer,
  delivery,
  updateContact,
}) => {
  const formRef = useRef(null);

  const [cities, setCities] = useState([]);
  const [value, setValue] = useState(cities[0]);
  const [inputValue, setInputValue] = useState('');
  const [addrees, setAddrees] = useState({});
  const [AddDelivery, setAddDelivery] = useState(false);

  useEffect(() => {
    if (delivery?.code === 201) {
      if (formRef.current) {
        const form = formRef.current;
        Array.from(form.elements).forEach((element) => {
          if (element.type !== 'submit') {
            handleClose();
            setAddDelivery(false);
          }
        });
      }
    }
  }, [delivery, handleClose]);
  return (
    <>
      <ModalLayout
        open={AddDelivery}
        maxWidth="md"
        handleClose={() => {
          setAddDelivery(false);
          setCities([]);
          setInputValue('');
          setAddrees({});
        }}
      >
        <Grid item xs={12} className="pb-3 pt-3 text-center">
          <Typography variant="h6">Agregar dirección de entrega</Typography>
        </Grid>
        <Formik
          initialValues={{
            name_contact: addrees.name ?? '',
            address: addrees.street ?? '',
            state_id: addrees.state_id ?? '',
            city_id: addrees.city_id ?? '',
            phone: addrees.phone ?? '',
          }}
          {...formikConf}
          onSubmit={(values) => {
            if (addrees?.id) {
              updateContact({
                state_id: values.state_id,
                city_id: values.city_id,
                name: values.name_contact,
                street: values.address,
                phone: values.phone,
                contactId: addrees?.id,
              });
              handleClose();
              setAddDelivery(false);
            } else {
              addDelivery({
                ...values,
                id: customer.id,
              });
            }
            setInputValue('');
            setAddrees({});
          }}
        >
          {({ handleChange, errors, touched, setFieldValue, values }) => {
            return (
              <Form ref={formRef}>
                <Grid
                  item
                  container
                  xs={12}
                  sx={{ mt: 0, mb: 2.1 }}
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item xs={3}>
                    <TextField
                      label="Nombre Contacto"
                      placeholder="Ingresa nombre de contacto"
                      fullWidth
                      name="name_contact"
                      value={values.name_contact}
                      onChange={handleChange}
                      onBlur={handleChange}
                      error={!!(touched.name_contact && errors.name_contact)}
                      helperText={touched.name_contact && errors.name_contact}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Teléfono Contacto"
                      placeholder="Teléfono contacto"
                      fullWidth
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleChange}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      id="AutocompleteDepartament"
                      autoHighlight
                      options={places}
                      value={values.state_id}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setCities(newValue?.cities);
                          setValue([]);
                          setInputValue('');
                        } else {
                          setCities([]);
                          setValue([]);
                          setInputValue('');
                        }
                        setFieldValue('state_id', newValue?.id);
                      }}
                      getOptionLabel={(option) => {
                        if (values.state_id) {
                          const selectedState =
                            places.find((item) => item.id === values.state_id) ?? {};
                          if (selectedState) {
                            setCities(selectedState.cities);
                            return selectedState.label ?? '';
                          }
                        }
                        return option.label ?? '';
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Departamentos"
                          error={!!(touched.state_id && errors.state_id)}
                        />
                      )}
                    />
                    {touched.state_id && errors.state_id && (
                      <FormHelperText className="Mui-error">{errors.state_id}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      id="AutocompleteCities"
                      autoHighlight
                      options={cities}
                      value={value || values.state_id}
                      getOptionLabel={(option) => {
                        if (values.state_id && values.city_id) {
                          const selectedCitie =
                            cities.find((item) => item.id === values.city_id) ?? {};
                          if (selectedCitie) {
                            return selectedCitie.label ?? '';
                          }
                        }
                        return option.label ?? '';
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.label}
                          </li>
                        );
                      }}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                        setFieldValue('city_id', newValue?.city_id);
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue, reason) => {
                        if (cities?.length === 0) {
                          setInputValue('');
                        } else {
                          setInputValue(newInputValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Ciudades"
                          name="city_id"
                          error={!!(touched.city_id && errors.city_id)}
                        />
                      )}
                    />
                    {touched.city_id && errors.city_id && (
                      <FormHelperText className="Mui-error">{errors.city_id}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                      id="outlined-textarea"
                      label="Dirección"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={values.address}
                      error={!!(touched.address && errors.address)}
                      helperText={
                        touched.address && errors.address
                          ? errors.address
                          : 'Ejemplo:(Calle 00 # 0-0)'
                      }
                      name="address"
                      placeholder="Ingresa dirección"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container sx={{ mt: 3, mb: 3 }}>
                  <Grid item xs={6} className="text-left">
                    <Button
                      style={{
                        textTransform: 'initial',
                        background: 'transparent',
                        borderColor: '#25c16a',
                        color: '#25c16a',
                      }}
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        setCities([]);
                        setAddDelivery(false);
                      }}
                      endIcon={<CloseIcon />}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6} className="text-right">
                    <Button
                      variant="contained"
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      style={{
                        textTransform: 'initial',
                      }}
                      endIcon={<SendIcon />}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </ModalLayout>

      <ModalLayout
        open={open}
        handleClose={() => {
          setCities([]);
          handleClose();
          setAddDelivery(false);
          setCities([]);
          setInputValue('');
        }}
        maxWidth="md"
      >
        <Grid item xs={12} className="pb-3 pt-3 text-center">
          <Typography variant="caption" className="sf-title ">
            Direcciones de <span color="primary">{customer?.name ?? ''}</span>
          </Typography>
        </Grid>
        <Divider />
        <Grid item container>
          <Grid item xs={12}>
            <Grid
              item
              xs={12}
              className="text-right"
              sx={{
                margin: '10px 10px',
              }}
            >
              <Button
                variant="contained"
                className="btn btn-primary"
                endIcon={<AddCircleOutlineIcon />}
                onClick={() => setAddDelivery(true)}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>
          {loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                padding: '20px 10px',
              }}
            >
              <Spinner />
            </Grid>
          ) : (
            <>
              {customer && customer?.contactsDelivery?.length > 0 ? (
                <List sx={{ width: '100%', marginTop: '2rem' }}>
                  {customer?.contactsDelivery?.map((row) => {
                    return (
                      <>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <MapIcon />
                          </ListItemAvatar>
                          <ListItemText
                            primary={row?.name}
                            secondary={
                              <>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  Dirección{' '}
                                </Typography>
                                {row?.stret}
                              </>
                            }
                          />
                          <IconButton
                            title="Editar Contacto"
                            className="button-icon-table action-click-payment"
                            aria-label="expand row"
                            size="small"
                            onClick={(e) => {
                              console.log(row);
                              e.preventDefault();
                              setAddrees({
                                ...row,
                                phone: row.phone !== false ? row.phone : '',
                              });
                              setAddDelivery(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </>
                    );
                  })}
                </List>
              ) : (
                <Grid item xs={12} className="pb-1">
                  <h5 className="text-center">Sin registros </h5>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </ModalLayout>
    </>
  );
};

AddCustomerDelivery.propTypes = {
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addDelivery: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
  places: PropTypes.shape({}).isRequired,
  customer: PropTypes.shape({}).isRequired,
  delivery: PropTypes.shape({}).isRequired,
};
