import { put, call } from 'redux-saga/effects';

import { ProductsService } from '@services/products.service';
import { Creators as StockCreators } from '@reducers/Stocks';
import { showSuccessToast, updateSuccessToast, successToast } from '@utils';
import { Creators as NotificationCreators } from '@reducers/Notifications';

import {
  transformServiceStock,
  stateTranslations,
  deliveryStateTranslations,
  stateStyle,
  colorDeliveryState,
} from '@transforms/ApiToServiceStocktransform';
import { TypesNotifications, Errors } from '@constants/index';

export function* get() {
  try {
    const response = yield ProductsService.getStocks();
    if (!response) {
      throw new Error(Errors?.error);
    }
    const transformer = transformServiceStock(response);
    const arrayNotifications = transformer
      .flatMap((items) => (items?.lots || []).filter((lot) => lot.vu_percent * 100 <= 50))
      .map((row) => ({
        ...row,
        id: row?.name,
        name: `demo`,
        message: `¡Atención!\nLotes con Vida Útil del ${row?.vu_percent * 100}% próximo a Vencer`,
        type: TypesNotifications?.inventory ?? '',
      }))
      .filter((notification) => Object.keys(notification).length > 0);
    yield put(NotificationCreators.pushNotifications(arrayNotifications));

    yield put(StockCreators.stocksSuccess(transformer));
  } catch (error) {
    yield put(StockCreators.stocksSuccess([]));
  }
}
export function* updateStatusProduct({ payload: { product_id, reason } }) {
  const toastId = yield call(showSuccessToast);
  try {
    const response = yield ProductsService.putUpdateStatusProduct({
      product_id,
      reason,
    });
    const {
      data: { message },
      status_code,
    } = response;

    if (
      (message === 'Product successfully deactivated' && status_code === 200) ||
      (message === 'Product successfully active' && status_code === 200)
    ) {
      yield put(StockCreators.getStocks());
      yield put(StockCreators.putUpdateStatusProductSuccess(true));
      yield call(updateSuccessToast, toastId);
    }
  } catch (error) {
    yield call(updateSuccessToast, {
      toastId,
      messange: Errors?.errorRequets,
      type: 'error',
    });
  }
}
export function* getPickingList() {
  try {
    const { pickings_ordes: response } = yield ProductsService.getPickingList();
    const data = response.slice(0, 3).map((row) => ({
      contact: `Demo ${row.id}`,
      deliveryDate: row?.delivery_date,
      direction: `Demo ${row.id}`,
      documentOrigin: `Demo ${row.id}`,
      id: row?.id,
      locationOrigin: row?.location_origin,
      name: `Demo ${row.id}`,
      scheduledDate: row?.scheduled_date,
      urlTracking: row?.url_tracking,
      trackingGuide: row?.tracking_guide,
      logisticOperator: row?.logistic_operator,
      statePick: stateTranslations[row?.state_pick],
      deliveryState: deliveryStateTranslations[row?.delivery_state],
      colorState: stateStyle[stateTranslations[row?.state_pick]],
      colorDeliveryState: colorDeliveryState[deliveryStateTranslations[row?.delivery_state]],
    }));
    yield put(StockCreators.pickingListSuccess(data.length > 0 ? data : []));
  } catch (error) {
    yield put(StockCreators.pickingListSuccess([]));
  }
}

export function* getProductsFulfillment() {
  try {
    const response = yield ProductsService.getProductsFulfillment();
    const data = response.map((row) => ({
      barcode: `Demo ${row?.product_id}`,
      name: `Demo ${row?.product_id}`,
      sku: `Demo ${row?.product_id}`,
      content: row?.product_content,
      productUom: row?.product_uom,
      qty: row?.qty_reserved,
      quantity: row?.quantity,
      id: row?.product_id,
    }));
    const transformer = transformServiceStock(response);
    yield put(StockCreators.productsFulfillmentSuccess(data.length > 0 ? transformer : []));
  } catch (error) {
    yield put(StockCreators.productsFulfillmentSuccess([]));
  }
}

export function* getContactsFulfillment() {
  try {
    const { clients: response } = yield ProductsService.getContactsFulfillment();
    const data = response.slice(0, 2).map((row) => ({
      companyName: `Demo ${row?.id}`,
      email: `Demo ${row?.id}`,
      identification: 'Demo',
      identificationType: 'Demo',
      movil: '0000000000',
      name: `Demo ${row?.id}`,
      street: 'Demo location',
      id: row?.id,
      contactsDelivery: row?.contacts_delivery.slice(0, 2).map((item) => ({
        ...item,
        name: `Demo ${item?.id}`,
        street: `Demo ${item?.id}`,
      })),
    }));
    yield put(
      StockCreators.createDeliveryContactSuccess({
        code: 200,
      }),
    );
    yield put(StockCreators.constactsFulfillmentSuccess(data.length > 0 ? data : []));
  } catch (error) {
    yield put(StockCreators.constactsFulfillmentSuccess([]));
  }
}

export function* createContactFulfillment({ payload }) {
  try {
    const response = yield ProductsService.createContactsFulfillment({
      platform: 'SF',
      clients: [...payload],
    });
    if (!response) {
      throw new Error(Errors?.error);
    }
    if (response?.status_code === 201 || response?.status_code === 200) {
      yield put(StockCreators.getContactsFulfillment());
      yield put(
        StockCreators.createContactFulfillmentSuccess({
          code: 201,
        }),
      );
      successToast();
    }
  } catch (error) {
    const toastId = yield call(showSuccessToast);
    const errorMensagge = error?.getParsedBody()?.data?.message || Errors?.errorRequets;
    yield call(updateSuccessToast, {
      toastId,
      messange: errorMensagge,
      type: 'error',
    });
    yield put(StockCreators.createContactFulfillmentSuccess({ code: error?.getStatus() }));
  }
}

export function* createDeliveryContact({ payload }) {
  try {
    const response = yield ProductsService.contactAddDelivery({
      platform: 'SF',
      name_contact: payload?.name_contact,
      parent_id: payload?.id,
      phone: '', // No requerido
      country_id: 49,
      state_id: payload?.state_id,
      city_id: payload?.city_id,
      address: payload?.address,
    });

    if (!response) {
      throw new Error(Errors?.error);
    }
    if (response?.status_code === 201) {
      yield put(StockCreators.getContactsFulfillment());
      yield put(
        StockCreators.createDeliveryContactSuccess({
          code: 201,
        }),
      );
      successToast();
    }
    yield put(StockCreators.createDeliveryContactSuccess({ code: 200 }));
  } catch (error) {
    const toastId = yield call(showSuccessToast);
    yield call(updateSuccessToast, {
      toastId,
      messange: Errors?.errorRequets,
      type: 'error',
    });
    yield put(StockCreators.createDeliveryContactSuccess({ code: error?.getStatus() }));
  }
}
export function* createOrderDelivery({ payload }) {
  const toastId = yield call(showSuccessToast);
  try {
    const response = yield ProductsService.createOrderDelivery(payload);
    if (!response) {
      throw new Error(Errors?.error);
    }

    if (response?.status_code === 201) {
      yield put(StockCreators.getPickingList());
      yield put(
        StockCreators.createOrderDeliverySuccess({
          code: 201,
        }),
      );
      yield call(updateSuccessToast, { toastId });
    } else {
      throw new Error(response?.message || Errors?.defaultError);
    }
  } catch (error) {
    yield call(updateSuccessToast, {
      toastId,
      messange: Errors?.errorRequets,
      type: 'error',
    });
    yield put(StockCreators.createOrderDeliverySuccess({ code: error?.getStatus() }));
  }
}

export function* createOrderPicking({ payload }) {
  const toastId = yield call(showSuccessToast);
  try {
    const response = yield ProductsService.createOrderPicking(payload);
    if (!response) {
      throw new Error(Errors?.error);
    }
    if (response?.status_code === 201) {
      yield put(StockCreators.getPickingList());
      yield put(
        StockCreators.createOrderPickingSuccess({
          code: 201,
        }),
      );
      yield call(updateSuccessToast, { toastId });
    }
  } catch (error) {
    yield call(updateSuccessToast, {
      toastId,
      messange: Errors?.errorRequets,
      type: 'error',
    });
    yield put(StockCreators.createOrderPickingSuccess({ code: error?.getStatus() }));
  }
}

export function* updateContactAddrees({ payload }) {
  try {
    const response = yield ProductsService.updateAddressContact(payload);
    if (!response) {
      throw new Error(Errors?.error);
    }
    if (response?.status_code === 201) {
      yield put(StockCreators.getContactsFulfillment());
      successToast();
    }
  } catch (error) {
    console.log(error);
  }
}
