import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableBody } from '@mui/material';

import { CustomTable, CustomTitle } from '@components/index';

import './style.scss';

export const CardTablePromedio = ({
  summary,
  title,
  titleSecondary,
  ticket,
  titlesTable,
  active,
  percent,
  renderRow,
  activeNumber,
}) => {
  return (
    <Grid container direction="row" sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <h2 className="sf-title-secondary">{title}</h2>
      </Grid>
      <Grid
        item
        xs={12}
        className="card_height card text-center"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sx={{ mb: 4 }}>
          <CustomTitle
            title={titleSecondary}
            item={ticket}
            active={active}
            percent={percent}
            activeNumber={activeNumber}
          />
        </Grid>
        {summary && summary.length > 0 ? (
          <Grid item xs={12} sx={{ mt: 1 }} container>
            <CustomTable titles={titlesTable} data={summary}>
              <TableBody>{renderRow()}</TableBody>
            </CustomTable>
          </Grid>
        ) : (
          <Grid item xs={12} className="card_height container-message">
            <h3 className="sf-title-secondary">No hay Resultados</h3>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

CardTablePromedio.defaultProps = {
  title: '',
  titleSecondary: '',
  active: false,
  percent: false,
  activeNumber: false,
};
CardTablePromedio.propTypes = {
  summary: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string,
  titleSecondary: PropTypes.string,
  ticket: PropTypes.number.isRequired,
  active: PropTypes.bool,
  percent: PropTypes.bool,
  titlesTable: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderRow: PropTypes.func.isRequired,
  activeNumber: PropTypes.bool,
};
