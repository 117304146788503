import { formatCurrency } from '@utils';

export const optionsChart = (summary) => ({
  height: 290,
  colorSchema: ['#72CFED', '#637EDD'],
  enableLabels: false,
  isHorizontal: false,
  enableMarkers: true,
  enableLegend: true,
  numberFormat: {
    type: 'NUMERIC',
    format: 'Abbreviated Currency',
    decimals: 0,
    currency: { text: '$ (COP)', label: '$' },
  },
  yAxisLabel: 'COP venta <br /> total',
  xAxisLabel: 'Meses',
  axisFontSize: 20,
  layers: [
    {
      type: 'BAR_CHART',
      data: summary,
      settings: { fieldX: 'name', fieldY: 'value' },
      yLabel: 'Total en dinero',
      tooltipTemplate(data) {
        return `<div class="tooltip">
              <h3>${data?.originalData.name}</h3>
              <h4>Unidades</h4>
              <p class=primary-font-color>${data?.originalData.units}</p>
              <h4>Total en dinero</h4>
              <p>${formatCurrency(data?.originalData.value)} COP</p>
            </div>`;
      },
    },
    {
      type: 'LINE_CHART',
      yLabel: 'Total en unidades',
      data: summary,
      settings: { fieldX: 'name', fieldY: 'units' },
      axisPosition: 'Right',
      xTickSuffix: null,
      yTickSuffix: 'Uds',
      tooltipTemplate(data) {
        return `<div class="tooltip">
          <h3>${data?.originalData.name}</h3>
          <h4>Unidades</h4>
          <p class=primary-font-color>${data?.originalData.units}</p>
          <h4>Total en dinero</h4>
          <p>${formatCurrency(data?.originalData.value)} COP</p>
        </div>`;
      },
    },
  ],
});

const transformSummaryChat = (response, option = 'Active') =>
  response?.map((item) => ({
    value: option === 'Active' ? item?.activeCustomers : item?.lostCustomers,
    name: item?.monthName,
  }));

export const optionsChartCustomer = (summary) => ({
  height: 290,
  colorSchema: ['#72CFED', '#637EDD'],
  enableLabels: false,
  isHorizontal: false,
  enableMarkers: true,
  enableLegend: true,
  yAxisLabel: 'Clientes Totales',
  xAxisLabel: 'Meses',
  axisFontSize: 20,
  layers: [
    {
      type: 'BAR_CHART',
      data: transformSummaryChat(summary) ?? [],
      settings: { fieldX: 'name', fieldY: 'value' },
      yLabel: 'Total Clientes Activos',
      tooltipTemplate(data) {
        return `<div class="tooltip">
              <h3>Clientes Activos ${data?.originalData?.value}</h3>
            </div>`;
      },
    },
    {
      type: 'LINE_CHART',
      yLabel: 'Total Clientes Inactivos',
      data: transformSummaryChat(summary, 'Inactive') ?? [],
      settings: { fieldX: 'name', fieldY: 'value' },
      axisPosition: 'Right',
      xTickSuffix: null,
      tooltipTemplate(data) {
        return `<div class="tooltip">
          <h3>Clientes Inactivos ${data?.originalData?.value} </h3>
        </div>`;
      },
    },
  ],
});

export const optionsChartSallIn = (summary) => {
  return {
    width: null,
    height: 400,
    // colorSchema: ['#72CFED', '#637EDD'],
    chartGradientColors: ['rgb(94, 38, 169)', 'rgb(15, 85, 235)'],
    enableLabels: false,
    isHorizontal: false,
    enableMarkers: true,
    enableLegend: false,
    settings: { fieldX: 'name', fieldY: 'value' },
    numberFormat: {
      type: 'NUMERIC',
      format: 'Abbreviated',
      decimals: 2,
      currency: { text: '$ (COP)', label: '$' },
    },
    axisFontSize: 20,
    layers: [
      {
        type: 'BAR_CHART',
        data: summary?.map((item) => {
          return {
            value: item?.amount,
            name: item?.date_name,
            portion: item?.orders,
            units: item?.units,
          };
        }),
        settings: { fieldX: 'name', fieldY: 'value' },
        isMain: true,
        axisNumberFormat: {
          type: 'NUMERIC',
          format: 'Abbreviated Currency',
          decimals: 2,
          currency: { text: '$ (COP)', label: '$' },
        },
        tooltipTemplate(data) {
          return `<div class="tooltip">
              <h3>${data?.originalData.name}</h3>
              <h4>Orden</h4>
              <p>${data?.originalData.portion}</p>
              <h4>Total</h4>
              <p>${formatCurrency(data?.originalData.value)}</p>
            </div>`;
        },
      },
      {
        type: 'LINE_CHART',
        data: summary?.map((item) => {
          return {
            value: item?.amount,
            name: item?.date_name,
            portion: item?.orders,
            units: item?.units,
          };
        }),
        settings: { fieldX: 'name', fieldY: 'units' },
        axisPosition: 'Right',
        xTickSuffix: null,
        yTickSuffix: 'Uds',
        axisNumberFormat: {
          type: 'NUMERIC',
          format: 'Abbreviated',
          decimals: 2,
          currency: { text: '$ (COP)', label: '$' },
        },
        tooltipTemplate(data) {
          return `<div class="tooltip">
              <h3>${data?.originalData.name}</h3>
              <h4>Orden</h4>
              <p>${data?.originalData.portion}</p>
              <h4>Unidades vendidas</h4>
              <p>${data?.originalData.units}</p>
            </div>`;
        },
      },
    ],
  };
};

export const optionsChartSellOut = (summary) => {
  return {
    width: null,
    height: 400,
    // colorSchema: ['#72CFED', '#637EDD'],
    chartGradientColors: ['rgb(94, 38, 169)', 'rgb(15, 85, 235)'],
    enableLabels: false,
    isHorizontal: false,
    enableMarkers: true,
    enableLegend: false,
    settings: { fieldX: 'name', fieldY: 'value' },
    axisFontSize: 10,
    layers: [
      {
        type: 'BAR_CHART',
        data: summary?.map((item) => {
          return {
            value: item?.amount?.total,
            name: item?.date?.toUpperCase(),
            units: item?.units?.total,
          };
        }),
        settings: { fieldX: 'name', fieldY: 'value' },
        isMain: true,
        tooltipTemplate(data) {
          return `<div class="tooltip">
                       <h3>${data?.value?.originalData?.name?.toUpperCase()}</h3>
                       <h4>Unidades ${data?.value?.originalData?.units}</h4>
                       <h4>Total ${formatCurrency(data?.value?.originalData?.value)}</h4>
                     </div>`;
        },
      },
      {
        type: 'LINE_CHART',
        data: summary?.map((item) => {
          return {
            value: item?.amount?.total,
            name: item?.date?.toUpperCase(),
            units: item?.units?.total,
          };
        }),
        settings: { fieldX: 'name', fieldY: 'value' },
        axisPosition: 'Right',
        xTickSuffix: null,
        yTickSuffix: '',
        tooltipTemplate(data) {
          return `<div class="tooltip">
          <h3>${data?.value?.originalData?.name?.toUpperCase()}</h3>
          <h4>Unidades ${data?.value?.originalData?.units}</h4>
          <h4>Total ${formatCurrency(data?.value?.originalData?.value)}</h4>
        </div>`;
        },
      },
    ],
  };
};

export const optionsChartSellOutUnds = (summary) => {
  return {
    width: null,
    height: 400,
    // colorSchema: ['#72CFED', '#637EDD'],
    chartGradientColors: ['rgb(94, 38, 169)', 'rgb(15, 85, 235)'],
    enableLabels: true,
    isHorizontal: false,
    enableMarkers: true,
    enableLegend: false,
    settings: { fieldX: 'name', fieldY: 'value' },
    axisFontSize: 10,
    layers: [
      {
        type: 'BAR_CHART',
        data: summary?.map((item) => {
          return {
            value: item?.units?.total,
            name: item?.date?.toUpperCase(),
          };
        }),
        settings: { fieldX: 'name', fieldY: 'value' },
        isMain: true,
        yLabel: 'Total Unidades',
        tooltipTemplate(data) {
          return `<div class="tooltip">
                       <h3>${data?.value?.originalData?.name?.toUpperCase()}</h3>
                       <h4>Unidades ${data?.value?.originalData?.value}</h4>
                     </div>`;
        },
      },
      {
        type: 'LINE_CHART',
        data: summary?.map((item) => {
          return {
            value: item?.units?.total,
            name: item?.date?.toUpperCase(),
          };
        }),
        yLabel: 'Total Unidades',
        settings: { fieldX: 'name', fieldY: 'value' },
        axisPosition: 'Right',
        xTickSuffix: null,
        yTickSuffix: '',
        tooltipTemplate(data) {
          return `<div class="tooltip">
          <h3>${data?.value?.originalData?.name?.toUpperCase()}</h3>
          <h4>Unidades ${data?.value?.originalData?.value}</h4>
        </div>`;
        },
      },
    ],
  };
};
