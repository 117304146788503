import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  document_origin: Yup.string().required('Documento de Referencia es requerido'),
  date_delivered: Yup.string().required('Fecha Estimada es requerida'),
  products: Yup.object()
    .test('at-least-one-modified', 'Agregar Productos son requeridos', (value) => {
      return Object.keys(value).length > 0;
    })
    .required('Agregar Productos son requeridos'),
  fileData: Yup.mixed()
    .required('Documento requerido')
    .test('fileFormat', 'Sólo PDF', (value) => {
      return value && ['application/pdf'].includes(value.type);
    }),
});

export const formikConf = {
  validateOnChange: true,
  validateOnBlur: true,
  initialValues: {
    document_origin: '',
    date_delivered: '',
    products: {},
    fileData: '',
  },
  validationSchema,
};
