import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  CircularProgress,
  Alert,
  Tooltip,
  Table,
  Box,
  Collapse,
  Typography,
  TableHead,
} from '@mui/material';
import { connect } from 'react-redux';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import PendingIcon from '@mui/icons-material/Pending';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { CustomTable, Spinner } from '@components/index';
import { Creators as Purchase } from '@reducers/Purchases';
import { formatCurrency } from '@utils';
// import { HOST } from '../../constants/odoo.constants';

import './style.scss';

const titles = [
  '',
  'Orden de Compra',
  'Fecha de Confirmación',
  'Total de la Orden',
  'Estado de la Factura',
  'Correo',
  'Fecha de Recepción',
  'Estado de la Recepción',
  'Acciones',
];

const PurchaseOrders = ({
  changePage,
  changeRowsPerPage,
  getPurchaseOrders,
  getOrderLines,
  purchaseLines,
  resetOrderLines,
  orders,
  pagination,
  loading,
}) => {
  const [isLoading] = useState(0);
  // const urlFile = `${HOST}/api/sascha/purchase/download_documents?id=`;
  // const urlFileReception = `${HOST}/api/sascha/purchase/download_reception_support?id=`;
  const { page, rowsPerPage } = pagination;

  useEffect(() => {
    getPurchaseOrders();
  }, [getPurchaseOrders]);

  const [openDetails, setOpenDetails] = useState({});

  const handleToggle = (id) => {
    resetOrderLines();
    setOpenDetails({
      [id]: !openDetails[id],
    });
    if (openDetails[id]) return;
    getOrderLines(id);
  };
  const renderTooltip = () => <div>La orden de Compra tiene una novedad en la recepción</div>;

  return (
    <Grid
      container
      item
      justifyContent="center"
      id="sf-PurchaseOrders"
      className="sf-PurchaseOrders"
    >
      <Grid item xs={12} className="pb-2 pt-2">
        <h2 className="sf-title"> Órdenes de compra</h2>
      </Grid>
      <Grid container className="card">
        {orders?.loading ? (
          <Grid
            container
            className="card"
            justifyContent="center"
            alignItems="center"
            style={{
              marginTop: '30px',
              padding: '20px 10px',
            }}
          >
            <Spinner />
          </Grid>
        ) : (
          <CustomTable
            titles={titles}
            data={orders?.list}
            pagination={pagination}
            active
            onPageChange={(event, page) => changePage(page)}
            onRowsPerPageChange={(event) => changeRowsPerPage(event.target.value)}
          >
            <TableBody>
              {orders?.list &&
                orders?.list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <>
                      <Tooltip
                        title={item.novenlty_status === 'with_novelty' ? renderTooltip() : ''}
                        arrow
                      >
                        <TableRow
                          key={item.id}
                          style={{
                            backgroundColor:
                              item.novenlty_status === 'with_novelty' && 'rgb(250 198 130 / 24%)',
                          }}
                        >
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => {
                                handleToggle(item.id);
                              }}
                            >
                              {openDetails[item.id] ? (
                                <KeyboardArrowUpIcon
                                  style={{
                                    color: 'white',
                                    background: '#25c16a',
                                    borderRadius: '50%',
                                  }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">{item.name}</TableCell>
                          <TableCell align="center">{item.confirmation_date}</TableCell>
                          <TableCell align="center">{formatCurrency(item.amount_total)}</TableCell>
                          <TableCell align="center">
                            {item.invoice_status === 'invoiced' && (
                              <Alert severity="success">Totalmente Facturado</Alert>
                            )}
                            {item.invoice_status === 'no' && (
                              <Alert severity="info">Nada para Facturar</Alert>
                            )}
                            {item.invoice_status === 'to invoice' && (
                              <Alert severity="info">Para Facturar</Alert>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {item.status_email === 'sent' ? (
                              <Tooltip title="Correo Enviado">
                                <MarkEmailReadIcon color="success" />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Correo Pendiente">
                                <PendingIcon color="warning" />
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell align="center">{item.reception_date}</TableCell>
                          <TableCell align="center">
                            {item.reception_status === 'Entregado' ? (
                              <Alert severity="success">Entregado</Alert>
                            ) : (
                              <Alert severity="info">{item.reception_status}</Alert>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              width: 100,
                            }}
                          >
                            <IconButton
                              title="Pago adelantado de Factura"
                              className="button-icon-table action-click-payment"
                              aria-label="expand row"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                alert('Documentos descargados');
                                /*     setIsLoading(item?.id);
                                    const link = document.createElement('a');
                                    link.href = `${urlFile}${item?.id}`;
                                    link.download = `${urlFile}${item?.id}`;
                                    link.click();
                                    if (link) {
                                      setIsLoading(0);
                                    } */
                              }}
                            >
                              {isLoading !== item?.id ? (
                                <Tooltip title="Descargar Orden de Compra">
                                  <PictureAsPdfIcon />
                                </Tooltip>
                              ) : (
                                <CircularProgress color="secondary" />
                              )}
                            </IconButton>
                            {item.novenlty_status === 'with_novelty' && (
                              <IconButton
                                title="Pago adelantado de Factura"
                                className="button-icon-table action-click-payment"
                                aria-label="expand row"
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  /*  setIsLoading(item?.id);
                                   const link = document.createElement('a');
                                   link.href = `${urlFileReception}${item?.id}`;
                                   link.download = `${urlFileReception}${item?.id}`;
                                   link.click();
                                   if (link) {
                                     setIsLoading(0);
                                   } */
                                }}
                              >
                                {isLoading !== item?.id ? (
                                  <Tooltip title="Descargar Reporte novedades">
                                    <NewReleasesIcon />
                                  </Tooltip>
                                ) : (
                                  <CircularProgress color="secondary" />
                                )}
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      </Tooltip>
                      {openDetails[item.id] && (
                        <>
                          {loading ? (
                            <TableRow>
                              <TableCell colSpan={12}>
                                <Spinner />
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell style={{ paddingBottom: 20, paddingTop: 20 }} colSpan={9}>
                                <Collapse in={openDetails[item.id]} timeout="auto" unmountOnExit>
                                  <Box sx={{ margin: 1 }}>
                                    <Typography gutterBottom component="div">
                                      <strong> Detalle de Compra</strong>
                                    </Typography>
                                    <Table
                                      size="small"
                                      aria-label="summary"
                                      sx={{
                                        zIndex: 0,
                                      }}
                                    >
                                      <TableHead className="Table_head">
                                        <TableRow
                                          style={{
                                            position: 'relative',
                                            zIndex: 0,
                                          }}
                                        >
                                          <TableCell>Nombre de Producto</TableCell>
                                          <TableCell align="center">Unidades Pedidas</TableCell>
                                          <TableCell align="center">Embalaje (UdM)</TableCell>
                                          <TableCell align="center">Total Unidades</TableCell>
                                          <TableCell align="center">Precio Unitario</TableCell>
                                          <TableCell align="center">Impuestos</TableCell>
                                          <TableCell align="center">Subtotal</TableCell>
                                          <TableCell align="center">Total</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {purchaseLines?.map((e) => (
                                          <TableRow key={e.id}>
                                            <TableCell component="th" scope="row">
                                              {e.product_name}
                                            </TableCell>
                                            <TableCell align="center">{e.quantity}</TableCell>
                                            <TableCell align="center">{e.product_uom}</TableCell>
                                            <TableCell align="center">{e.total_qunatity}</TableCell>
                                            <TableCell align="center">
                                              {formatCurrency(e.price_unit)}
                                            </TableCell>
                                            <TableCell align="center">{e.taxes}</TableCell>
                                            <TableCell align="center">
                                              {formatCurrency(e.sub_total)}
                                            </TableCell>
                                            <TableCell align="center">
                                              {formatCurrency(e.total)}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                    </>
                  ))}
            </TableBody>
          </CustomTable>
        )}
      </Grid>
    </Grid>
  );
};

PurchaseOrders.propTypes = {
  loading: PropTypes.bool.isRequired,
  getPurchaseOrders: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  }).isRequired,
  getOrderLines: PropTypes.shape({}).isRequired,
  changeRowsPerPage: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  resetOrderLines: PropTypes.func.isRequired,
  purchaseLines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({
  purchases: { orders, paginationProduct: pagination, purchaseLines, loadingLines: loading },
}) => ({
  orders,
  pagination,
  purchaseLines,
  loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPurchaseOrders: () => dispatch(Purchase.getPurchaseOrders()),
    getOrderLines: (id) => dispatch(Purchase.getOrderLines(id)),
    resetOrderLines: () => dispatch(Purchase.resetOrderLines()),
    changeRowsPerPage: (item) => dispatch(Purchase.changeRowsPerPage(item)),
    changePage: (item) => dispatch(Purchase.changePage(item)),
  };
};
export const PurchaseOrdersContainer = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrders);
