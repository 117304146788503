// @ts-nocheck
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  DialogTitle,
} from '@mui/material';
import { connect } from 'react-redux';
import ReactExport from 'react-export-excel';
import { useHistory } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import ClearIcon from '@mui/icons-material/Clear';
import PaidIcon from '@mui/icons-material/Paid';
import { TextField } from '@constants/index';
import { CustomTable, Spinner, DetallisInvoces } from '@components/index';
import { Creators as Account } from '@reducers/Account';
import { formatCurrency } from '@utils';

import './style.scss';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const titles = [
  'Número de Factura',
  'Fecha de Vencimiento',
  'Referencia',
  'Total',
  'Estado',
  'Notas crédito',
  'Estado de Pago Adelantado',
  'Acciones',
];

const Accounting = ({
  account,
  getAccountBills,
  changePage,
  changeRowsPerPage,
  paymentBill,
  prepaymentReset,
  getAccountLines,
  user,
}) => {
  const [isLoading, setIsLoading] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const { page, rowsPerPage } = account?.pagination;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const history = useHistory();
  const [layout, setLayout] = useState({
    open: false,
  });

  useEffect(() => {
    getAccountBills();
    prepaymentReset();
  }, [getAccountBills, prepaymentReset]);

  const handleClose = () =>
    setLayout({
      open: false,
    });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value?.trim());
  };

  const filteredData = account?.bills?.filter((row) => {
    const rowValues = Object.values(row).join(' ').toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    return rowValues.includes(searchTermLower);
  });

  return (
    <Grid container item justifyContent="center" id="sf-accounting" className="sf-accounting">
      <DetallisInvoces handleClose={handleClose} layout={layout} account={account} />

      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>¿Deseas Confirmar el Adelanto de Factura?</DialogTitle>
        <DialogActions
          style={{
            justifyContent: 'space-around',
          }}
        >
          <Button variant="contained" className="btn btn-primary" onClick={() => setOpen(false)}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            className="btn btn-primary"
            onClick={() => {
              if (id && id !== 0) {
                setIsLoading(id);
                setOpen(false);
                paymentBill(id);
              }
            }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={account.message}
        keepMounted
        onClose={() => {
          setIsLoading(0);
          prepaymentReset();
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-center" color="#25c16a">
          ¡Importante!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {account.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="btn btn-primary"
            onClick={() => {
              setIsLoading(0);
              prepaymentReset();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item xs={12} className="pt-3">
        <h2 className="sf-title">Facturas</h2>
      </Grid>
      {filteredData && filteredData?.length > 0 && (
        <Grid item xs={12} sx={{ mt: 1, mb: 2 }} className="text-right">
          <ExcelFile
            filename="Tu_facturas_superfuds"
            element={
              <Button
                variant="contained"
                className="btn btn-primary"
                endIcon={<FileDownloadIcon />}
              >
                Descargar
              </Button>
            }
          >
            <ExcelSheet data={account?.bills} name="Facturas">
              <ExcelColumn label="Factura" value="name" />
              <ExcelColumn label="Fecha de Vencimiento" value="expiration_date" />
              <ExcelColumn label="Referencia" value="ref" />
              <ExcelColumn label="Total" value="amount_total" />
              <ExcelColumn label="Facturas rectificativas" value="invoice_refund" />
            </ExcelSheet>
          </ExcelFile>
        </Grid>
      )}

      <Grid container className="card" alignItems="center" gap={4}>
        {account?.loadingBills ? (
          <Grid
            container
            className="card"
            justifyContent="center"
            alignItems="center"
            style={{
              padding: '20px 10px',
            }}
          >
            <Spinner />
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={4}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 1, mb: 0, zIndex: 3, paddingLeft: 2, paddingRight: 2 }}
              className="text-left"
            >
              <Grid item xs={searchTerm !== '' ? 10 : 12}>
                <TextField
                  size="medium"
                  fullWidth
                  label="Buscar Número de factura"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              {searchTerm !== '' && (
                <Grid item xs={2}>
                  <IconButton
                    title="Detalles de la Factura"
                    className="button-icon-table action-click-payment"
                    aria-label="expand row"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSearchTerm('');
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                backgroundColor: 'rgb(255, 244, 229)',
                padding: 10,
              }}
            >
              <h6
                className="text-left"
                style={{
                  fontSize: '.8rem',
                  fontWeight: 300,
                  margin: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <PaidIcon
                  style={{
                    color: '#25c16a',
                  }}
                />
                Requisitos para Aplicar el Pago Adelantado de Facturas
              </h6>
              <ul className="terms">
                <li>Factura mayor a 15 días </li>
                <li>
                  Factura con un monto menor a{' '}
                  <span
                    style={{
                      color: '#25c16a',
                    }}
                  >
                    {formatCurrency(10000000)}
                  </span>
                </li>
                <li>Aprobado el adelanto de Factura se cobra el 3% sobre el monto total</li>
              </ul>
            </Grid>

            {filteredData && filteredData?.length > 0 ? (
              <CustomTable
                titles={titles}
                data={account?.bills}
                pagination={account?.pagination}
                active
                onPageChange={(event, page) => changePage(page)}
                onRowsPerPageChange={(event) => changeRowsPerPage(event.target.value)}
              >
                <TableBody>
                  {filteredData
                    .sort((a, b) => {
                      if (a.expiration_date < new Date()) {
                        return -1;
                      }
                      if (b.expiration_date < new Date()) {
                        return 1;
                      }
                      return Math.abs(a.expiration_date) - Math.abs(b.expiration_date);
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => (
                      <TableRow key={item.id}>
                        <TableCell align="center">{item.name}</TableCell>
                        <TableCell align="center">
                          {item.expiration_date > 0
                            ? `En ${item.expiration_date + 1} días`
                            : (item.payment_status === 'not_paid' ||
                              item.payment_status === 'in_payment' ||
                              item.payment_status === 'partial') && (
                              <span
                                style={{
                                  color: 'red',
                                }}
                              >{`Hace ${Math.abs(item.expiration_date)} días`}</span>
                            )}
                        </TableCell>
                        <TableCell align="center">{item.ref}</TableCell>
                        <TableCell align="center">{formatCurrency(item.amount_total)}</TableCell>
                        <TableCell align="center">
                          {item.payment_status === 'paid' && (
                            <Alert severity="success">Pagado</Alert>
                          )}
                          {item.payment_status === 'in_payment' && (
                            <Alert severity="info">En proceso de pago</Alert>
                          )}
                          {item.payment_status === 'not_paid' && (
                            <Alert severity="warning">No pagadas</Alert>
                          )}
                          {item.payment_status === 'partial' && (
                            <Alert severity="info">Pagado Parcialmente</Alert>
                          )}
                          {item.payment_status === 'reversed' && (
                            <Alert severity="error">Revertido</Alert>
                          )}
                          {item.payment_status === 'invoicing_legacy' && (
                            <Alert severity="error">Factura Sistema Anterior</Alert>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {item.invoice_refund !== 'False' ? (
                            <Alert
                              severity="success"
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                              onClick={() => {
                                history.push(`/facturas-rectificativas?id=${item.invoice_refund}`);
                              }}
                            >
                              Ir a {item.invoice_refund}
                            </Alert>
                          ) : (
                            'Sin Notas de Crédito'
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {item.prepayment_status !== false ? (
                            <>
                              {item?.prepayment_status === 'not_approved' && (
                                <Alert severity="error">Pago No Aprobado</Alert>
                              )}
                              {item?.prepayment_status === 'approved' && (
                                <Alert severity="success">Pago Aprobado</Alert>
                              )}
                              {item?.prepayment_status === 'request' && (
                                <Alert severity="warning">Revisión de Solicitud</Alert>
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </TableCell>

                        <TableCell align="center" style={{ padding: '0px' }}>
                          {item.invoice_payment &&
                            item.invoice_payment !== '15 Days' &&
                            item.payment_status !== 'paid' &&
                            item?.prepayment_status === false &&
                            10000000 >= item.amount_total && (
                              <IconButton
                                style={{ display: 'none' }}
                                title="Pago adelantado de Factura"
                                className="button-icon-table action-click-payment"
                                aria-label="expand row"
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleClickOpen();
                                  setId(item?.id);
                                }}
                              >
                                {isLoading !== item?.id ? (
                                  <PaidIcon />
                                ) : (
                                  <CircularProgress
                                    color="secondary"
                                    style={{
                                      width: 20,
                                      height: 20,
                                    }}
                                  />
                                )}
                              </IconButton>
                            )}
                          <IconButton
                            title="Detalles de la Factura"
                            className="button-icon-table action-click-payment"
                            aria-label="expand row"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!account?.loadingLines) {
                                setLayout({
                                  open: true,
                                  user,
                                  ...item,
                                });
                              }
                              getAccountLines(item.id);
                            }}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </CustomTable>
            ) : (
              <Grid item xs={12} className="card_height container-message">
                <h3 className="sf-title-secondary">No hay Resultados</h3>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

Accounting.propTypes = {
  user: PropTypes.shape({}).isRequired,
  account: PropTypes.shape({
    count_bills: PropTypes.number,
    bills: PropTypes.arrayOf(PropTypes.shape({})),
    pagination: PropTypes.arrayOf(PropTypes.shape({})),
    message: PropTypes.string,
    billsLines: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  pagination: PropTypes.shape({}).isRequired,
  getAccountBills: PropTypes.func.isRequired,
  changeRowsPerPage: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  paymentBill: PropTypes.func.isRequired,
  prepaymentReset: PropTypes.func.isRequired,
  getAccountLines: PropTypes.func.isRequired,
};

const mapStateToProps = ({ account, user }) => ({
  account,
  user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountBills: () => dispatch(Account.getAccountBills()),
    changeRowsPerPage: (item) => dispatch(Account.changeRowsPerPage(item)),
    changePage: (item) => dispatch(Account.changePage(item)),
    paymentBill: (id) => dispatch(Account.paymentBill(id)),
    prepaymentReset: () => dispatch(Account.prepaymentReset()),
    getAccountLines: (id) => dispatch(Account.getAccountLines(id)),
  };
};
export const AccountingContainer = connect(mapStateToProps, mapDispatchToProps)(Accounting);
