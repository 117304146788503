import { put, select } from 'redux-saga/effects';
import { Creators as StockCreators } from '@reducers/Stocks';
import { Creators as AccountCreators } from '@reducers/Account';
import { debugConfig } from '@config/debugConfig';
import { userIsLoggedIn } from '@reducers/User';

/* Exported to make available for tests
   process STARTUP actions */
export function* startup() {
  // eslint-disable-next-line no-console
  if (debugConfig.useReactotron && console.tron) {
    // fully customized!
    const subObject = { a: 1, b: [1, 2, 3], c: true };
    subObject.circularDependency = subObject;
    // eslint-disable-next-line no-console
    console.tron.display({
      name: '🔥 SUPERFUDS INIT 🔥',
      preview: 'You should totally expand this',
      value: {
        '💃': 'Welcome to the future!',
        subObject,
        someInlineFunction: () => true,
        someGeneratorFunction: startup,
        someNormalFunction: () => '',
      },
    });
  }
  const isLoggedIn = yield select(userIsLoggedIn);
  if (isLoggedIn) {
    yield put(StockCreators.getStocks());
    yield put(AccountCreators.getAccountBills());
    yield put(AccountCreators.getAccountBillsCorrective());
  }
}
