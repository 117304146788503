import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
// import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { connect, useDispatch } from 'react-redux';
import { TextField as field, Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { DEMO } from '@constants/index';
import { Creators as Auth } from '@reducers/Auth';
import { PASSWORD, EMAIL } from '@constants/env-vars.constants';
import { useQueryParam } from '../../../hooks/use-query-param.hook';

import { formikConf } from './login.formik';
import './style.scss';

const TextField = styled(field)({
  '& label.Mui-focused': {
    color: '#25c16a',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#25c16a',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#25c16a',
    },
    '&:hover fieldset': {
      borderColor: '#25c16a',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#25c16a',
    },
  },
});

const LoginContainer = ({ loading, status, isLoggedIn }) => {
  const [errorLogin, setErrorLogin] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const isDemo = useQueryParam(DEMO) === 'demo';
  useEffect(() => {
    if (isDemo) {
      localStorage.setItem('DEMO_USER', process.env.REACT_APP_EMAIL_DEMO);
      dispatch(
        Auth.loginRequest({
          email: process.env.REACT_APP_EMAIL_DEMO,
          password: process.env.REACT_APP_PASSWORD_DEMO,
        }),
      );
    }
  }, [dispatch, isDemo]);

  useEffect(() => {
    if (status === 404 || status === 401)
      setErrorLogin('Correo electrónico o password incorrectos');
    else if (status === 200) {
      history.push('/');
    } else if (status >= 405) {
      setErrorLogin(
        'No se pudo conectar al servidor. Por favor, verifique su conexión a Internet y reintente.',
      );
    }
  }, [history, isLoggedIn, dispatch, status]);
  return (
    <div>
      <h2>Demo</h2>
      <h5
        style={{
          fontWeight: 100,
        }}
      >
        Usuario: <b>demo@demo.com</b>
        <br />
        Password: <b>demo</b>
      </h5>

      <Formik
        {...formikConf}
        onSubmit={(values) => {
          dispatch(
            Auth.loginRequest({
              email: EMAIL,
              password: PASSWORD,
            }),
          );
        }}
      >
        {({ values, handleChange, errors }) => {
          return (
            <Form className="sf-login__loginForm">
              <TextField
                autoComplete="off"
                error={errors.email !== undefined}
                type="email"
                id="email"
                name="email"
                value={isDemo ? process.env.REACT_APP_EMAIL_DEMO : values.email}
                label="Correo electrónico"
                onChange={handleChange}
                className="text-field"
                helperText={errors.email}
                fullWidth
              />
              <TextField
                autoComplete="off"
                error={errors.password !== undefined}
                helperText={errors.password}
                type="password"
                id="password"
                name="password"
                label="Contraseña"
                color="success"
                className="text-field"
                value={isDemo ? process.env.REACT_APP_PASSWORD_DEMO : values.password}
                onChange={handleChange}
                fullWidth
              />

              <Button
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
                className="button"
              >
                {loading || isLoggedIn ? (
                  <div className="aa-LoadingIndicator" style={{ display: 'flex' }}>
                    <svg className="aa-LoadingIcon" viewBox="0 0 100 100" width="20" height="20">
                      <circle
                        cx="50"
                        cy="50"
                        fill="none"
                        r="35"
                        stroke="currentColor"
                        strokeDasharray="164.93361431346415 56.97787143782138"
                        strokeWidth="6"
                      >
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          repeatCount="indefinite"
                          dur="1s"
                          values="0 50 50;90 50 50;180 50 50;360 50 50"
                          keyTimes="0;0.40;0.65;1"
                        />
                      </circle>
                    </svg>
                  </div>
                ) : (
                  'Iniciar sesión'
                )}
              </Button>
              {errorLogin && (
                <Typography className="sf-login__text_center error">{errorLogin}</Typography>
              )}
            </Form>
          );
        }}
      </Formik>

      {/*    <div className="sf-login__forgetPassword">
        <Button
          className="button-link"
          onClick={() => {
            console.log('clicked');
          }}
        >
          ¿Has olvidado tu contraseña?
        </Button>
      </div> */}
    </div>
  );
};

LoginContainer.defaultProps = {
  status: 0,
  error: {},
  isLoggedIn: false,
  loadingUser: false,
};

LoginContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  status: PropTypes.number,
  error: PropTypes.shape({}),
  isLoggedIn: PropTypes.bool,
  loadingUser: PropTypes.bool,
};

const mapStateToProps = ({
  auth: { loading, status },
  user: { isLoggedIn, loading: loadingUser },
}) => ({
  loading,
  status,
  isLoggedIn,
  loadingUser,
});

export const Login = connect(mapStateToProps)(LoginContainer);
