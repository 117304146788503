// @ts-nocheck
import React from 'react';

import { Login } from '@components/index';
import './style.scss';

export const LoginContainer = () => {
  return (
    <div className="sf-login">
      <div className="sf-login__leftSection">
        <p>
          Somos el aliado
          <br />
          estratégico que
          <br />
          impulsa la expansión
          <br />
          de tu marca.
        </p>
      </div>
      <div className="sf-login__loginForm">
        <Login />
      </div>
    </div>
  );
};
