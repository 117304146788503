import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { rootSaga } from '@redux/sagas';
import { reduxPersistConfig } from '@config/reduxPersistConfig';
import { reducer as AuthReducer } from '@reducers/Auth';
import { reducer as UserReducer } from '@reducers/User';
import { reducer as PurchasesReducer } from '@reducers/Purchases';
import { reducer as NotificationReducer } from '@reducers/Notifications';
import { reducer as AccountReducer } from '@reducers/Account';
import { reducer as StocksReducer } from '@reducers/Stocks';
import { reducer as SellInReducer } from '@reducers/SellIn';
import { configureStore } from './configureStore';

export const history = createBrowserHistory();

/* ------------- Assemble The Reducers ------------- */
export const reducers = (router) =>
  combineReducers({
    // eslint-disable-next-line global-require
    auth: AuthReducer,
    user: UserReducer,
    purchases: PurchasesReducer,
    account: AccountReducer,
    stock: StocksReducer,
    sales: SellInReducer,
    notification: NotificationReducer,
  });

export const createStore = () => {
  let finalReducers = reducers(history);
  // If rehydration is on use persistReducer otherwise default combineReducers
  if (reduxPersistConfig.active) {
    const persistConfig = reduxPersistConfig.storeConfig;
    finalReducers = persistReducer(persistConfig, reducers(history));
  }

  const storeData = configureStore(finalReducers, rootSaga);

  const { store, sagaMiddleware } = storeData;
  let { sagasManager } = storeData;
  if (module.hot) {
    module.hot.accept(() => {
      store.replaceReducer(reducers);

      // eslint-disable-next-line global-require
      const newYieldedSagas = require('../sagas').rootSaga;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware(newYieldedSagas);
      });
    });
  }
  return store;
};
