// @ts-nocheck
import React from 'react';
import './styles.scss';
import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';

const style = (vuPercent) => ({
  background: vuPercent <= 50 && '#F65858',
  color: vuPercent <= 50 && '#fff',
});
const title = (vuPercent) =>
  vuPercent <= 50 ? `Productos Próximos a Vencer vida útil ${vuPercent}%` : '';

export const renderRow = (rows, pagination, limitRows) => {
  const { page, rowsPerPage } = pagination;
  const sortedRows = JSON.parse(JSON.stringify(rows)).sort(
    (a, b) =>
      moment(a.lots[0]?.expirationDate, 'YYYY-MM-DD') -
      moment(b.lots[0]?.expirationDate, 'YYYY-MM-DD'),
  );
  return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items) =>
    items?.lots?.map((row, index) => {
      const diffInDays = Math.abs(moment().diff(moment(row.expirationDate), 'days'));
      const percent = row.vu_percent * 100;
      const vuPercent = percent.toFixed(0);
      return (
        <TableRow key={row.name}>
          <TableCell align="center" style={style(vuPercent)} title={title(vuPercent)}>
            {items.product_sku}
          </TableCell>
          <TableCell align="center" style={style(vuPercent)} title={title(vuPercent)}>
            {items.product_barcode}
          </TableCell>
          <TableCell align="center" style={style(vuPercent)} title={title(vuPercent)}>
            {items.name}
          </TableCell>
          <TableCell align="center" style={style(vuPercent)} title={title(vuPercent)}>
            {items.imagen && (
              <img
                width={100}
                height={100}
                aria-hidden
                className="cursor-pointer img-modal"
                src={items.imagen}
                alt={`Imagen N°${items.id} del producto`}
              />
            )}
          </TableCell>
          <TableCell align="center" style={style(vuPercent)} title={title(vuPercent)}>
            {row.name}
          </TableCell>
          <TableCell align="center" style={style(vuPercent)} title={title(vuPercent)}>
            {row.availableQty}
          </TableCell>
          <TableCell align="center" style={style(vuPercent)} title={title(vuPercent)}>
            {moment(row.expirationDate).format('YYYY-MM-DD')}
          </TableCell>
          <TableCell align="center" style={style(vuPercent)} title={title(vuPercent)}>
            {!isNaN(vuPercent) ? `${vuPercent}%` : ''}
          </TableCell>
          {limitRows >= 11 && (
            <TableCell align="center" style={style(vuPercent)} title={title(vuPercent)}>
              {items.daysInventory} días
            </TableCell>
          )}
          <TableCell align="center" style={style(vuPercent)}>
            {diffInDays} días
          </TableCell>
          {index === 0 && (
            <TableCell
              align="center"
              style={{ background: 'rgb(37 193 106 / 14%)', fontWeight: 600 }}
              rowSpan={items.lots.length}
            >
              {items.totalAvailable}
            </TableCell>
          )}
          {index === 0 && (
            <TableCell
              align="center"
              style={{ background: 'rgb(37 193 106 / 14%)', fontWeight: 600 }}
              rowSpan={items.lots.length}
            >
              {items.totalReserved}
            </TableCell>
          )}
        </TableRow>
      );
    }),
  );
};
