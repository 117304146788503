/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { PAGE, ROWS_PER_PAGE } from '@constants/index';
import { Types } from './actionsTypes';
/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  loading: false,
  products: [],
  pickingList: {
    list: [],
    loading: false,
  },
  fulfillmentProducs: {
    products: [],
    loading: false,
  },
  createDelivery: {
    loading: false,
  },
  createCustomer: {
    loading: false,
  },
  createOrder: {
    loading: false,
  },
  orderPicking: {
    loading: false,
    data: [],
  },
  fulfillmentClients: {
    clients: [],
    loading: false,
  },
  statusProduct: false,
  pagination: {
    page: PAGE,
    rowsPerPage: ROWS_PER_PAGE,
  },
});
/* ------------- Reducers ------------- */

export const getStocks = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

export const stockSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    loading: false,
    products: data,
    pagination: {
      page: PAGE,
      rowsPerPage: ROWS_PER_PAGE,
    },
  };
};
export const pickingListRequest = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    loading: false,
    pickingList: {
      loading: true,
    },
  };
};
export const pickingListSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    loading: false,
    pickingList: {
      list: data,
      loading: false,
    },
  };
};

export const getproductFulfillment = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    loading: false,
    fulfillmentProducs: {
      loading: true,
    },
  };
};
export const productsFulfillmentSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    loading: false,
    pagination: {
      page: PAGE,
      rowsPerPage: ROWS_PER_PAGE,
    },
    fulfillmentProducs: {
      products: data,
      loading: false,
    },
  };
};
export const getContactsFulfillment = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    loading: false,
    fulfillmentClients: {
      loading: true,
    },
  };
};
export const constactsFulfillmentSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    loading: false,
    fulfillmentClients: {
      clients: data,
      loading: false,
    },
  };
};

export const changeRowsPerPage = (state = INITIAL_STATE, { PerPage }) => {
  return {
    ...state,
    pagination: {
      rowsPerPage: PerPage,
      page: PAGE,
    },
  };
};
export const changePage = (state = INITIAL_STATE, { page }) => {
  return {
    ...state,
    pagination: {
      ...state.pagination,
      page,
    },
  };
};
export const updateStatusProductSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    statusProduct: data,
  };
};

export const createDeliveryContactRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    createDelivery: {
      loading: true,
    },
  };
};
export const createDeliveryContactSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    loading: false,
    createDelivery: {
      loading: false,
      openModal: false,
      ...data,
    },
  };
};
export const createOrderDeliveryRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    createOrder: {
      loading: true,
    },
  };
};
export const createOrderDeliverySuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    loading: false,
    createOrder: {
      loading: false,
      ...data,
    },
  };
};
export const createOrderPickingRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    createPicking: {
      loading: true,
    },
  };
};
export const createOrderPickingSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    loading: false,
    createPicking: {
      loading: false,
      ...data,
    },
  };
};
export const createContactFulfillment = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    createCustomer: {
      loading: true,
    },
  };
};
export const createContactFulfillmentSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    loading: false,
    createCustomer: {
      loading: false,
      ...data,
    },
  };
};

export const reset = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    createOrder: {
      loading: false,
    },
  };
};
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.STOCKS_SUCCESS]: stockSuccess,
  [Types.CHANGE_ROWS_PER_PAGE]: changeRowsPerPage,
  [Types.CHANGE_PAGE]: changePage,
  [Types.GET_STOCKS]: getStocks,
  [Types.PUT_UPDATE_STATUS_PRODUCT_SUCCESS]: updateStatusProductSuccess,
  [Types.PICKING_LIST_SUCCESS]: pickingListSuccess,
  [Types.GET_PICKING_LIST]: pickingListRequest,
  [Types.PRODUCTS_FULFILLMENT_SUCCESS]: productsFulfillmentSuccess,
  [Types.GET_PRODUCTS_FULFILLMENT]: getproductFulfillment,
  [Types.CONSTACTS_FULFILLMENT_SUCCESS]: constactsFulfillmentSuccess,
  [Types.GET_CONTACTS_FULFILLMENT]: getContactsFulfillment,
  [Types.CREATE_DELIVERY_CONTACT]: createDeliveryContactRequest,
  [Types.CREATE_DELIVERY_CONTACT_SUCCESS]: createDeliveryContactSuccess,
  [Types.CREATE_ORDER_DELIVERY]: createOrderDeliveryRequest,
  [Types.CREATE_ORDER_DELIVERY_SUCCESS]: createOrderDeliverySuccess,
  [Types.CREATE_CONTACT_FULFILLMENT]: createContactFulfillment,
  [Types.CREATE_CONTACT_FULFILLMENT_SUCCESS]: createContactFulfillmentSuccess,
  [Types.CREATE_ORDER_PICKING]: createOrderPickingRequest,
  [Types.CREATE_ORDER_PICKING_SUCCESS]: createOrderPickingSuccess,
  [Types.RESET_CREATE_ORDER_DELIVERY]: reset,
});