import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Correo electrónico invalido').required('Correo electrónico requerido'),
  password: Yup.string().required('Contraseña requerida'),
});
export const formikConf = {
  validateOnChange: false,
  validateOnBlur: true,
  initialValues: {
    email: 'demo@demo.com',
    password: 'demo',
  },
  validationSchema,
};
